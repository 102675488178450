import { motion } from 'framer-motion'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

const DialogContainer = styled(motion.div)`
  position: relative;
  top: 0px
  left: 0px;
  width: 100%;
  z-index: 1000;
  /* overflow: hidden; */

  h3 {
    color: ${props => props.theme.neutral.text};
  }
`

const Dialog = styled.div`
  position: relative;
`

const DialogWrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: auto;
`

const Step = styled(motion.div)`
  position: relative;
  margin-left: ${props => props.theme.space.sm};
  width: calc(100vw - 40px);
  height: auto;
  border-radius: 8px;
  padding-bottom: 50px;
  background: ${props => props.theme.neutral.bright};
  box-shadow: ${props => props.theme.shadow.base};
  transition: height 0.6s;
`

const Content = styled.div`
  height: 100%;
  padding: ${props => props.theme.space.m};
  display: flex;
  flex-direction: column;
`

const Navigator = styled.div`
  position: absolute;
  border-top: 1px solid ${props => props.theme.neutral.light};
  padding: ${props => props.theme.space.s} ${props => props.theme.space.m};
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 50px;
  color: ${props => props.theme.tertiary.base};
  font-weight: ${props => props.theme.fontWeight.medium};
`

const SecondaryActionButton = styled(motion.div)`
  position: relative;
  display: inline-block;
`

const PrimaryActionButton = styled(motion.div)`
  position: relative;
  text-align: right;
  float: right;
  display: inline-block;
`

function SingleStepDialog(props) {
  const dialogRef = useRef(null)
  const wrapperRef = useRef(null)

  useEffect(() => {
    document.body.style.position = 'fixed'
    document.body.style.width = '100%'
    document.body.style.height = '100%'
  }, [])

  return (
    <DialogContainer initial={{ opacity: 0, y: -200 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -200 }}>
      <Dialog ref={dialogRef}>
        <DialogWrapper ref={wrapperRef}>
          <Step>
            <Content>{props.children}</Content>
            <Navigator>
              <SecondaryActionButton onClick={props.secondaryAction}>{props.secondaryLabel}</SecondaryActionButton>
              <PrimaryActionButton onClick={props.primaryAction}>{props.primaryLabel}</PrimaryActionButton>
            </Navigator>
          </Step>
        </DialogWrapper>
      </Dialog>
    </DialogContainer>
  )
}

export default SingleStepDialog
