import React, { useEffect } from 'react'
import { db } from '../config'

export function useCategories(group) {
  const [error] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [categories, setCategories] = React.useState([])

  useEffect(() => {
    let unsubscribe = () => {}

    if (group) {
      unsubscribe = db.collection('groups')
        .doc(group)
        .collection('categories')
        .onSnapshot(function(querySnapshot) {
          let categories = []
          querySnapshot.forEach(function(doc) {
            const category = doc.id
            categories.push(category)
          })
          setLoading(false)
          setCategories(categories)
        })
    }

    return () => unsubscribe()
  }, [group])

  return {
    error,
    loading,
    categories,
  }
}
