import firebase from 'firebase/app'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as OptionsIcon } from '../../../assets/icons/options.svg'
import { Context } from '../../../context/ContextProvider'
import { OptionMenu } from '../../tunnels'

const Button = styled(motion.div)`
  position: relative;
  height: 32px;
  background-color: ${props => props.theme.primary.light};
  border-radius: 16px;
  padding: ${props => props.theme.space.xs} ${props => props.theme.space.s};
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.6s;

  svg {
    width: 20px;
    height: auto;
    fill: ${props => props.theme.neutral.bright};
  }
`

function OptionButton(props) {
  const context = useContext(Context)

  const [modalVisibility, setModalVisibility] = useState(false)

  function toggleProfilModal() {
    setModalVisibility(!modalVisibility)
  }

  function signOut() {
    firebase
      .auth()
      .signOut()
      .then(function() {
        // Sign-out successful.
      })
      .catch(function(error) {
        // An error happened.
      })
  }

  return (
    <AnimatePresence>
      <OptionMenu
        key="optionMenu"
        show={modalVisibility}
        links={[
          // { key: 1, type: 'link', title: 'Datenschutz', to: 'profil' },
          { key: 1, type: 'link', title: 'Credits', to: 'credits' },
          { key: 2, type: 'function', title: 'Abmelden', function: signOut },
        ]}
        close={toggleProfilModal}
      />
      {context.userProfile && (
        <Button
          key="playerName"
          onClick={toggleProfilModal}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          whileTap={{ scale: 0.9 }}
        >
          <OptionsIcon />
        </Button>
      )}
    </AnimatePresence>
  )
}

export default OptionButton
