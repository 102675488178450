import React, { useContext, useEffect } from 'react'
import { Context } from '../../context/ContextProvider'
import { db } from '../config'

export function useQuiz(quizId, uid) {
  const context = useContext(Context)

  const [error] = React.useState(false)
  const [quizLoading, setQuizLoading] = React.useState(true)
  const [turnOwner, setTurnOwner] = React.useState()
  const [timestamp, setTimestamp] = React.useState()
  const [challenger, setChallenger] = React.useState()
  const [challengerName, setChallengerName] = React.useState()
  const [challengerRound, setChallengerRound] = React.useState()
  const [challengerScore, setChallengerScore] = React.useState()
  const [opponent, setOpponent] = React.useState()
  const [opponentName, setOpponentName] = React.useState()
  const [opponentRound, setOpponentRound] = React.useState()
  const [opponentScore, setOpponentScore] = React.useState()
  const [role, setRole] = React.useState()
  const [winner, setWinner] = React.useState()
  const [quizStatus, setQuizStatus] = React.useState()

  useEffect(() => {
    
    const unsubscribe = db
      .collection('groups')
      .doc(context.userProfile.activeGroup)
      .collection('quizzes')
      .doc(quizId)
      .onSnapshot(function(doc) {
        const quiz = doc.data()
        setTimestamp(quiz.timestamp)
        setTurnOwner(quiz.turnOwner)
        setChallenger(quiz.challenger)
        setChallengerName(quiz.challengerName)
        setChallengerRound(quiz.challengerRound)
        setChallengerScore(quiz.challengerScore)
        setOpponent(quiz.opponent)
        setOpponentName(quiz.opponentName)
        setOpponentRound(quiz.opponentRound)
        setOpponentScore(quiz.opponentScore)
        setWinner(quiz.winner)
        setQuizStatus(quiz.status)

        if (quiz.challenger === uid) {
          setRole('challenger')
        } else {
          setRole('opponent')
        }

        setQuizLoading(false)
      })

    return () => unsubscribe()
  }, [])

  return {
    error,
    quizLoading,
    timestamp,
    turnOwner,
    challenger,
    challengerName,
    challengerRound,
    challengerScore,
    opponent,
    opponentName,
    opponentRound,
    opponentScore,
    role,
    winner,
    quizStatus,
  }
}
