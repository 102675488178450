import { motion } from 'framer-motion'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useQuizMessages } from '../../../database/services/useQuizMessages'
import { MessageBubble, MessageEditor, MessageReader } from '../messaging'

const Container = styled.section`
  width: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: ${props => props.theme.space.m} ${props => props.theme.space.l} ${props => props.theme.space.m} ${props => props.theme.space.l};
  margin-bottom: ${props => props.theme.space.l};
`

const Score = styled.div`
  font-size: ${props => props.theme.fontSize.xxl};
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.neutral.bright};
  padding: 0px ${props => props.theme.space.m};
`

const Avatar = styled(motion.div)`
  position: relative;
  cursor: pointer;
`

const Mood = styled.div`
  width: 56px;
  height: 56px;
  border: 2px solid ${props => (props.winner === props.playerRole ? '#FFCE17' : '#fff')};
  border-radius: 100%;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.neutral.bright};
  box-shadow: ${props => props.theme.shadow.light};
  line-height: 1rem;
`

const AvatarLabel = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(-28px);
  width: auto;
  text-align: center;
  color: ${props => props.theme.neutral.bright};
  font-size: ${props => props.theme.fontSize.s};
  font-weight: ${props => props.theme.fontWeight.bold};
`

const challengerMood = (challengerScore, opponendScore) => {
  if (challengerScore - opponendScore === 0) {
    return '😊'
  } else if (challengerScore - opponendScore > 0 && challengerScore - opponendScore < 3) {
    return '😜'
  } else if (challengerScore - opponendScore >= 3) {
    return '🥳'
  } else if (challengerScore - opponendScore < 0 && challengerScore - opponendScore >= -1) {
    return '😐'
  } else if (challengerScore - opponendScore < -1 && challengerScore - opponendScore >= -3) {
    return '🥺'
  } else if (challengerScore - opponendScore < -3 && challengerScore - opponendScore >= -5) {
    return '😭'
  } else {
    return '🤬'
  }
}

const opponentMood = (opponendScore, challengerScore) => {
  if (challengerScore - opponendScore === 0) {
    return '😊'
  } else if (opponendScore - challengerScore > 0 && opponendScore - challengerScore < 3) {
    return '😜'
  } else if (opponendScore - challengerScore >= 3) {
    return '🥳'
  } else if (opponendScore - challengerScore < 0 && opponendScore - challengerScore >= -1) {
    return '😐'
  } else if (opponendScore - challengerScore < -1 && opponendScore - challengerScore >= -3) {
    return '🥺'
  } else if (opponendScore - challengerScore < -3 && opponendScore - challengerScore >= -5) {
    return '😭'
  } else {
    return '🤬'
  }
}

function ScoreCounter(props) {
  const [messageEditor, setMessageEditor] = useState(false)
  const [messageReader, setMessageReader] = useState(false)

  const { messages, messageNumber } = useQuizMessages(props.userProfile.activeGroup, props.quizId)

  function toggleEditor() {
    setMessageEditor(!messageEditor)
  }

  function toggleReader() {
    messageNumber > 0 && setMessageReader(!messageReader)
  }

  return (
    <>
      <MessageEditor
        userProfile={props.userProfile}
        quizId={props.quizId}
        role={props.role}
        challenger={props.challenger}
        challengerName={props.challengerName}
        opponent={props.opponent}
        opponentName={props.opponentName}
        show={messageEditor}
        close={toggleEditor}
      />
      <MessageReader
        userProfile={props.userProfile}
        user={props.user}
        challenger={props.challenger}
        opponent={props.opponent}
        role={props.role}
        messages={messages}
        show={messageReader}
        close={toggleReader}
      />
      <Container>
        <Avatar initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: -50, opacity: 0 }} onClick={toggleEditor}>
          <MessageBubble type="sender" />
          <Mood playerRole={props.role === 'challenger' ? 'challenger' : 'opponent'} winner={props.winner}>
            {props.role === 'challenger'
              ? challengerMood(props.challengerScore, props.opponentScore)
              : opponentMood(props.opponentScore, props.challengerScore)}
          </Mood>
          <AvatarLabel winner={props.winner}>{props.role === 'challenger' ? props.challengerName : props.opponentName}</AvatarLabel>
        </Avatar>
        <Score>
          {props.role === 'challenger'
            ? props.challengerScore + ' - ' + props.opponentScore
            : props.opponentScore + ' - ' + props.challengerScore}
        </Score>
        <Avatar initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: -50, opacity: 0 }} onClick={toggleReader}>
          {messageNumber > 0 && <MessageBubble type="receiver" messageNumber={messageNumber} />}
          <Mood playerRole={props.role === 'challenger' ? 'opponent' : 'challenger'} winner={props.winner}>
            {props.role === 'challenger'
              ? opponentMood(props.opponentScore, props.challengerScore)
              : challengerMood(props.challengerScore, props.opponentScore)}
          </Mood>
          <AvatarLabel winner={props.winner}>{props.role === 'challenger' ? props.opponentName : props.challengerName}</AvatarLabel>
        </Avatar>
      </Container>
    </>
  )
}

export default ScoreCounter
