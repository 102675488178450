import React, { useEffect } from 'react'
import { db } from '../config'

export function useGroup(groupID) {
  const [groupLoading, setGroupLoading] = React.useState(true)
  const [groupData, setGroupData] = React.useState()

  useEffect(() => {
    let unsubscribe = () => {}

    groupID &&
      (unsubscribe = db
        .collection('groups')
        .doc(groupID)
        .onSnapshot(function(doc) {
          if (doc.exists) {
            setGroupData({ id: doc.id, ...doc.data() })
          } else {
            console.log('group not exists')
          }
        }))

    return () => unsubscribe()
  }, [groupID])

  return {
    groupLoading,
    groupData,
  }
}
