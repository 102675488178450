import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Layer } from '../layout'
import { QuestionCard } from '../teaser'

const CardPosition = styled.div`
  position: absolute;
  width: calc(100% - 48px);
  left: ${props => props.theme.space.m};
  top: 50%;
  transform: translateY(-50%);
  z-index: 2000;
  transition: opacity 0.6s;
  padding-bottom: ${props => props.theme.space.l};
`

function QuestionModal(props) {
  useEffect(() => {
    console.log(props)
  })
  

  return (
    <Layer action={props.closeQuestionModal}>
      <CardPosition onClick={props.closeQuestionModal}>
        <AnimatePresence>
          {props.question && (
            <motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0, scale: 0.6 }}>
              <QuestionCard key={props.question.id} group={props.groupId} questionID={props.question.id} question={props.question} ownResult={props.ownResult} mateResult={props.mateResult} owner={props.owner} />
            </motion.div>
          )}
        </AnimatePresence>
      </CardPosition>
    </Layer>
  )
}

export default QuestionModal
