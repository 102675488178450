import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const ProgressBar = styled(motion.div)`
  width: 80%;
  height: 16px;
  background-color: ${props => props.theme.primary.light};
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  position: fixed;
  bottom: ${props => props.theme.space.xl};
  left: 10%;
  border: 4px solid ${props => props.theme.primary.light};
`

const ProgressIndicator = styled(motion.div)`
  position: absolute;
  top: 0px;
  right: 0%;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.secondary.base};
  border-radius: 5px;
`

function TimeGuard(props) {
  const [duration] = useState(20000)
  const [timer, setTimer] = useState(0)

  useEffect(() => {
    props.defused && clearInterval(window.interval)
  }, [props.defused])

  useEffect(() => {

    let counter = 0
    
    setTimeout(() => {
      if (props.defused === false) {
        props.timeout()
      }
    }, duration)

    window.interval = setInterval(() => {
      counter >= 100 && clearInterval(window.interval)
      counter++
      let counterValue = '-' + counter + '%'
      setTimer(counterValue)
    }, duration / 100)

    return () => clearInterval(window.interval)
  }, [])

  return (
    <AnimatePresence>
      {!props.defused && (
        <ProgressBar inital={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <ProgressIndicator timer={timer} inital={{ x: '100%' }} animate={{ x: timer }} transition={{ type: 'tween' }} />
        </ProgressBar>
      )}
    </AnimatePresence>
  )
}

export default TimeGuard
