export const defaultTheme = {
  primary: {
    base: '#374498',
    light: '#4252B7',
    dark: '#1E2B79',
    text: '#7684DE',
    transparent: '#ABB6FA'
  },

  secondary: {
    base: '#FFBF1B',
    light: '#FFEFC6',
    transparent: '#FEEEC4'
  },

  tertiary: {
    base: '#E25E76',
    transparent: '#FBACBB'
  },

  positive: {
    base: '#98CC7A',
    background: '#EAFFDD'
  },

  negative: {
    base: '#E74560',
    background: '#F8C9D1'
  },

  neutral: {
    bright: '#FFFFFF',
    light: '#F7F7F7',
    text: '#CCCCCC'
  },

  duration: {
    fast: '0.3s',
    normal: '0.6s',
    slow: '0.9s',
  },

  radius: {
    s: '4px',
    m: '8px',
    l: '16px',
  },

  space: {
    xxs: '4px',
    xs: '8px',
    s: '16px',
    sm: '20px',
    m: '24px',
    l: '32px',
    xl: '48px',
    xxl: '96px',
    max: '160px',
  },

  fontFamily: {
    primary: ['Karla', 'sans-serif']
  },

  fontSize: {
    root: '16px',
    xxs: '0.7rem',
    xs: '0.85rem',
    s: '0.9rem',
    m: '1rem',
    l: '1.1rem',
    xl: '1.3rem',
    xxl: '1.6rem'
  },

  lineHeight: {
    root: '21px',
    s: '1.2rem',
    m: '1.4rem',
    l: '1.5rem',
    xl: '2.2rem',
    xxl: '2.4rem'
  },

  fontWeight: {
    light: 300,
    regular: 400,
    medium: 600,
    bold: 700,
  },

  shadow: {
    base:
      '0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12), 0 16px 16px rgba(0, 0, 0, 0.12)',
    light: '0px 0px 2px 0px rgba(0,0,0,0.12), 0px 16px 32px 0px rgba(0,0,0,0.12)',
    dark: '',
    text: '0px 2px 24px rgba(0, 0, 0, 0.3)',
    topHeavy:
      '0 -1px 1px rgba(0, 0, 0, 0.12), 0 -2px 2px rgba(0, 0, 0, 0.12), 0 -4px 4px rgba(0, 0, 0, 0.12), 0 -8px 8px rgba(0, 0, 0, 0.12), 0 -24px 24px rgba(0, 0, 0, 0.12)',
      topHeavyLight:
      '0 -1px 1px rgba(0, 0, 0, 0.02), 0 -2px 2px rgba(0, 0, 0, 0.02), 0 -4px 4px rgba(0, 0, 0, 0.02), 0 -8px 8px rgba(0, 0, 0, 0.02), 0 -24px 24px rgba(0, 0, 0, 0.02)',
  },

  desktopWidth: '440px'
}
