import React from 'react'
import styled, { keyframes } from 'styled-components'

const spinnerAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const Wrapper = styled.div`
  position: ${props => props.position === 'center' ? 'fixed' : 'relative'};
  top: ${props => props.position === 'center' ? '50%' : '0px'};
  left: ${props => props.position === 'center' ? '50%' : '0px'};
  transform: ${props => props.position === 'center' ? 'translate(-50%, -50%)' : 'none'};
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #fff;
`

const Indicator = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 100%;
  margin-bottom: 24px;
  border: 4px solid rgba(255, 255, 255, 0.5);
  border-top: 4px solid rgba(255, 255, 255, 1);
  animation: ${spinnerAnimation} 2s linear infinite;
`

function Spinner(props) {
  return (
    <Wrapper position={props.position}>
      <Indicator />
      {props.children}
    </Wrapper>
  )
}

export default Spinner
