import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Input } from '.'
import { ReactComponent as SelectIcon } from '../../../assets/icons/select.svg'

const Container = styled.div`
  /* border-top: 1px solid ${props => props.theme.neutral.text}; */
  margin-bottom: ${props => props.theme.space.s};
  /* padding-top: ${props => props.theme.space.s}; */
`

const FormatInput = css`
  h3 {
    margin-bottom: ${props => props.theme.space.xs};
    display: inline-block;
    width: auto;

    svg {
      fill: ${props => props.theme.neutral.text};
      width: auto;
      height: 10px;
      margin-left: ${props => props.theme.space.xxs};
    }
  }
`

const HEXInput = styled(motion.div)`
  ${FormatInput}
`

const RGBInput = styled(motion.div)`
  ${FormatInput}
`

const HSLInput = styled(motion.div)`
  ${FormatInput}
`

const InputContainer = styled.div`
  display: flex;
`

const InputColumn = styled.div`
  margin-right: ${props => props.theme.space.xs};

  &:last-child {
    margin-right: 0px;
  }
`

function ColorCodePicker(props) {
  const [visible, setVisible] = useState('hex')
  const [hex, setHex] = useState(props.hex)
  const [hsl, setHsl] = useState([, ,])
  const [rgb, setRgb] = useState([, ,])

  useEffect(() => {
    if (props.rgb) {
      const rgbArray = props.rgb
        .substring(4, props.rgb.length - 1)
        .replace(/ /g, '')
        .split(',')
      console.log(rgbArray)
      setRgb(rgbArray)
    }

    if (props.hsl) {
      const hslArray = props.hsl
        .substring(4, props.hsl.length - 1)
        .replace(/ /g, '')
        .replace(/\%/g, '')
        .split(',')
      console.log(hslArray)
      setHsl(hslArray)
    }

    if (props.hex) {
      console.log(props.hex)
      setHex(props.hex)
    }
  }, [props.rgb, props.hsl, props.hex])

  const variants = {
    visible: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
  }

  const changeHEX = value => {
    if(value.startsWith('#')) {
      setHex(value)
      console.log(value)
      props.emitHEX(value)
    } else {
      let newHex = '#' + value
      setHex(newHex)
      props.emitHEX(newHex)
    }
  }

  const changeHSL = (value, part) => {
    console.log(value)
    console.log(part)
    const newHSL = [part === 0 ? value : hsl[0], part === 1 ? value : hsl[1], part === 2 ? value : hsl[2]]
    console.log(newHSL)
    setHsl(newHSL)
    props.emitHSL(newHSL)
  }

  const changeRGB = (value, part) => {
    setRgb([part === 0 ? value : rgb[0], part === 1 ? value : rgb[1], part === 2 ? value : rgb[2]])
    props.emitRGB([part === 0 ? value : rgb[0], part === 1 ? value : rgb[1], part === 2 ? value : rgb[2]])
  }

  const changeFormat = newFormat => {
    setVisible(newFormat)
  }

  return (
    <Container>
      <AnimatePresence exitBeforeEnter>
        {visible === 'hex' && (
          <HEXInput key="hex" variants={variants} initial="hidden" animate="visible" exit="hidden">
            <h3 onClick={() => changeFormat('rgb')}>
              HEX <SelectIcon />
            </h3>
            <Input placeholder="HEX-Code" value={hex} emitValue={changeHEX} maxLength="7" nature="small" hideCounter />
          </HEXInput>
        )}
        {visible === 'rgb' && (
          <RGBInput key="rgb" variants={variants} initial="hidden" animate="visible" exit="hidden">
            <h3 onClick={() => changeFormat('hsl')}>
              RGB <SelectIcon />
            </h3>
            <InputContainer>
              <InputColumn>
                <Input placeholder="R" value={rgb[0]} emitValue={value => changeRGB(value, 0)} maxLength="3" nature="small" hideCounter />
              </InputColumn>
              <InputColumn>
                <Input placeholder="R" value={rgb[1]} emitValue={value => changeRGB(value, 1)} maxLength="3" nature="small" hideCounter />
              </InputColumn>
              <InputColumn>
                <Input placeholder="R" value={rgb[2]} emitValue={value => changeRGB(value, 2)} maxLength="3" nature="small" hideCounter />
              </InputColumn>
            </InputContainer>
          </RGBInput>
        )}{' '}
        {visible === 'hsl' && (
          <HSLInput key="hsl" variants={variants} initial="hidden" animate="visible" exit="hidden">
            <h3 onClick={() => changeFormat('hex')}>
              HSL <SelectIcon />
            </h3>
            <InputContainer>
              <InputColumn>
                <Input placeholder="H" value={hsl[0]} emitValue={value => changeHSL(value, 0)} maxLength="3" nature="small" hideCounter />
              </InputColumn>
              <InputColumn>
                <Input placeholder="S" value={hsl[1]} emitValue={value => changeHSL(value, 1)} maxLength="3" nature="small" hideCounter />
              </InputColumn>
              <InputColumn>
                <Input placeholder="L" value={hsl[2]} emitValue={value => changeHSL(value, 2)} maxLength="3" nature="small" hideCounter />
              </InputColumn>
            </InputContainer>
          </HSLInput>
        )}
      </AnimatePresence>
    </Container>
  )
}

export default ColorCodePicker
