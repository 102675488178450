import firebase from 'firebase/app'
import { AnimatePresence } from 'framer-motion'
import React, { useContext, useEffect, useState } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'
import { Context, GameStoreProvider } from '../../context'
import { useTheme } from '../../hooks'
import { GlobalModal, GlobalNotification } from '../components/communication'
import { Header } from '../components/partials'
import { GlobalStyle } from '../global'
import { Archive, Login, MainView, Preloader, Quiz, Registration } from './'
import Credits from './Credits'

const Container = styled.main`
  box-sizing: border-box;
  padding: 72px ${props => props.theme.space.sm} ${props => props.theme.space.sm} ${props => props.theme.space.sm};
  overflow-x: hidden;
`

function ViewController({ user, uid, ...props }) {
  const context = useContext(Context)
  const location = useLocation()

  const { theme, themeReady } = useTheme(uid)

  const [activeGroup, setActiveGroup] = useState(null)

  useEffect(() => {
    context && context.userProfile && setActiveGroup(context.userProfile.activeGroup)
  }, [context])

  return (
    <>
      {themeReady && (
        <ThemeProvider theme={theme}>
          <GlobalStyle theme={theme} />
          <GlobalNotification />
          <GlobalModal />
          <Header user={user} />
          <GameStoreProvider uid={uid} activeGroup={activeGroup}>
            <Container>
              <AnimatePresence exitBeforeEnter initial={false}>
                {context.loading && location.pathname !== '/login' && location.pathname !== '/registration' ? (
                  <Preloader />
                ) : (
                  <Switch location={location} key={location.key}>
                    <Route path="/:groupId/quiz/:quizId" component={props => <Quiz user={user} />}></Route>
                    <Route path="/login" exact component={props => <Login firebase={firebase} {...props} />}></Route>
                    <Route path="/registration" exact component={props => <Registration user={user} {...props} />} />
                    <Route path="/:groupId/archive" exact component={props => <Archive user={user} {...props} />} />
                    <Route path="/:groupId" exact component={props => <MainView user={user} {...props} />} />
                    <Route path="/" exact component={props => <Preloader user={user} {...props} />} />
                    <Route path="/credits" component={<Credits />} />
                  </Switch>
                )}
              </AnimatePresence>
            </Container>
          </GameStoreProvider>
        </ThemeProvider>
      )}
    </>
  )
}

export default ViewController
