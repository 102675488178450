import { AnimatePresence, motion } from 'framer-motion'
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Context, GameStore } from '../../context'
import { LinkButton } from '../components/buttons'
import { ContentBox } from '../components/layout'
import { EmptyState } from '../components/mainview'
import { Teaser } from '../components/teaser'
import { viewVariants } from '../global'

const Container = styled(motion.div)`
  padding: 0px 0px ${props => props.theme.space.xxl} 0px;
`

function Dashboard({ user, ...props }) {
  const context = useContext(Context)
  const gameStore = useContext(GameStore)

  const [activeList, setActiveList] = useState()
  const [waitingList, setWaitingList] = useState()

  useEffect(() => {
    let activeItems = []
    if (gameStore.quizzes.challengerQuizList && gameStore.quizzes.opponentQuizList && gameStore.quizzes.challengeList) {
      activeItems = activeItems.concat(
        gameStore.quizzes.challengerQuizList.filter(quiz => quiz.challenger === user.uid && quiz.turnOwner === 'challenger')
      )
      activeItems = activeItems.concat(
        gameStore.quizzes.opponentQuizList.filter(quiz => quiz.opponent === user.uid && quiz.turnOwner === 'opponent')
      )
      activeItems = activeItems.concat(gameStore.quizzes.challengeList)
      setActiveList(activeItems)
    }
  }, [user, gameStore.quizzes])

  useEffect(() => {
    let waitingItems = []
    if (gameStore.quizzes.challengerQuizList && gameStore.quizzes.opponentQuizList && gameStore.quizzes.requestList) {
      waitingItems = waitingItems.concat(
        gameStore.quizzes.challengerQuizList.filter(quiz => quiz.challenger === user.uid && quiz.turnOwner === 'opponent')
      )
      waitingItems = waitingItems.concat(
        gameStore.quizzes.opponentQuizList.filter(quiz => quiz.opponent === user.uid && quiz.turnOwner === 'challenger')
      )
      waitingItems = waitingItems.concat(gameStore.quizzes.requestList)
      setWaitingList(waitingItems)
    }
  }, [user, gameStore.quizzes])

  return (
    <>
      {activeList && waitingList && (
        <AnimatePresence>
          <Container initial="initial" animate="animate" exit="exit" variants={viewVariants}>
            {activeList.length === 0 && waitingList.length === 0 ? (
              <EmptyState />
            ) : (
              <>
                {activeList.length !== 0 && (
                  <ContentBox>
                    <h3>Du bist dran</h3>
                    {activeList.map(item => {
                      return (
                        <Teaser
                          key={item.id.toString()}
                          id={item.id}
                          status="active"
                          challenger={item.challenger}
                          challengerName={item.challengerName}
                          opponent={item.opponent}
                          opponentName={item.opponentName}
                          user={user}
                          questionPool={context.questionPool}
                          turnOwner={item.turnOwner}
                          userProfile={context.userProfile}
                        />
                      )
                    })}
                  </ContentBox>
                )}

                {waitingList.length !== 0 && (
                  <ContentBox>
                    <h3>Warten auf</h3>
                    {waitingList.map(item => {
                      return (
                        <Teaser
                          key={item.id.toString()}
                          id={item.id}
                          status="waiting"
                          challenger={item.challenger}
                          challengerName={item.challengerName}
                          opponent={item.opponent}
                          opponentName={item.opponentName}
                          user={user}
                          questionPool={context.questionPool}
                          turnOwner={item.turnOwner}
                          userProfile={context.userProfile}
                        />
                      )
                    })}
                  </ContentBox>
                )}
              </>
            )}

            <motion.div positionTransition>
              <LinkButton to={context.userProfile.activeGroup + '/archive'}>Spielearchive</LinkButton>
            </motion.div>
          </Container>
        </AnimatePresence>
      )}
    </>
  )
}

export default Dashboard
