import React from 'react'
import styled from 'styled-components'
import { ReactComponent as AddIcon } from '../../../assets/icons/add.svg'

const SenderBubble = styled.div`
  position: absolute;
  bottom: -2px;
  right: -2px;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background: ${props => props.theme.neutral.bright};
  box-shadow: ${props => props.theme.shadow.base};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${props => props.theme.secondary.base};
    width: 12px;
    height: 12px;
  }
`

const ReceiverBubble = styled.div`
  position: absolute;
  bottom: -4px;
  right: -4px;
  width: 22px;
  height: 18px;
  border-radius: 4px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: ${props => props.theme.secondary.base};
  box-shadow: ${props => props.theme.shadow.base};
  color: ${props => props.theme.neutral.bright};
  font-size: 12px;
  font-weight: ${props => props.theme.fontWeight.medium};

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: -6px;
    left: 4px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 0 10px;
    border-color: transparent transparent transparent ${props => props.theme.secondary.base};
  }
`

function MessageBubble(props) {
  return (
    <div>
      {props.type === 'sender' ? (
        <SenderBubble>
          <AddIcon />
        </SenderBubble>
      ) : (
        <ReceiverBubble>{props.messageNumber}</ReceiverBubble>
      )}
    </div>
  )
}

export default MessageBubble
