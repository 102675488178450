import React, { useEffect, useState } from "react";
import styled from "styled-components";


const NotifactionWrapper = styled.div`
  text-align: center;
  padding: 24px 0px;
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
`

function ResultNotification(props) {
  const [message, setMessage] = useState();

  useEffect(() => {
    if (props.quizResult === 'victory' ) {
      setMessage('Du hast gewonnen 🥳')
    } else if (props.quizResult === 'defeat') {
      setMessage('Du hast verloren 😨')
    } else {
      setMessage('Unentschieden 😇')
    }
  }, [props.quizResult])

  return (
    <NotifactionWrapper>
      {message}
    </NotifactionWrapper>
  )
}

export default ResultNotification