import firebase from 'firebase/app'
import 'firebase/auth'
import { motion } from 'framer-motion'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { Context } from '../../context/ContextProvider'
import { UPDATE_USERIMAGE } from '../../database/actions/userActions'
import { Logo } from '../components/atoms'
import { Button } from '../components/buttons'

const ViewContainer = styled(motion.section)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`
const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LogoWrapper = styled(motion.div)`
  margin-bottom: ${props => props.theme.space.xl};
  text-align: center;
`

const Publisher = styled.div`
  color: ${props => props.theme.primary.dark};
  position: fixed;
  left: 0%;
  width: 100%;
  text-align: center;
  font-weight: ${props => props.theme.fontWeight.medium};
  bottom: ${props => props.theme.space.xl};
`

function Login(props) {
  const context = useContext(Context)

  const [themeColor, setThemeColor] = useState('#374498')
  const [redirect, setRedirect] = useState(false)

  function signIn() {
    const provider = new firebase.auth.GoogleAuthProvider()

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function(result) {
        // var token = result.credential.accessToken
        // var user = result.user
        UPDATE_USERIMAGE(result.user.uid, result.user.photoURL)
        setRedirect(true)
      })
      .catch(function(error) {
        // var errorCode = error.code
        // var errorMessage = error.message
        // var email = error.email
        // var credential = error.credential
      })
  }

  useEffect(() => {
    context.headerVisible && context.updateHeaderVisibility(false)

    if (context) {
      context.activeGroup && context.activeGroup.customThemeColors
        ? setThemeColor(context.activeGroup.customThemeColors[0])
        : setThemeColor('#374498')
    }
  }, [context])

  return (
    <ViewContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Helmet>
        <meta name="theme-color" content={themeColor} />
      </Helmet>
      <LoginWrapper>
        <LogoWrapper initial={{ y: -50, opacity: 0 }} animate={{ y: 0, opacity: 1 }}>
          <Logo size="large" />
          <h2>cuiz</h2>
        </LogoWrapper>
        <Button onClick={signIn}>Mit Google anmelden</Button>
      </LoginWrapper>
      <Publisher>Von Freunden für Freunde.</Publisher>

      {redirect && <Redirect to="/" />}
    </ViewContainer>
  )
}

export default Login
