import React from 'react'
import styled from 'styled-components'

const Stage = styled.div``

const Value = styled.div`
  font-size: ${props => props.theme.fontSize.xxl};
  font-weight: ${props => props.theme.fontWeight.medium};
  color: ${props => props.theme.primary.dark};
`

const Label = styled.div`
  font-size: ${props => props.theme.fontSize.xs};
`

const Suffix = styled.span`
  margin-left: ${props => props.theme.space.xxs};
  font-size: ${props => props.theme.fontSize.xl};
`

function FactStage(props) {
  return (
    <Stage>
      <Value>
        {props.value}
        {props.suffix && <Suffix>{props.suffix}</Suffix>}
      </Value>
      <Label>{props.label}</Label>
    </Stage>
  )
}

export default FactStage
