import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  margin-bottom: ${props => props.theme.space.xs};
`

const DotGroup = styled(motion.div)`
  width: 33.33%;
  padding: 0px ${props => props.theme.space.xs} ${props => props.theme.space.m} 0px;
  line-height: 8px;
`

const GroupTitle = styled.h6`
  font-size: ${props => props.theme.fontSize.xs};
  margin: 0px 0px ${props => props.theme.space.xs} 0px;
  font-weight: ${props => props.theme.fontWeight.regular};
`

const QuestionDot = styled.span`
  width: 4px;
  height: 4px;
  border-radius: 100%;
  display: inline-block;
  margin-right: ${props => props.theme.space.xxs};
  background: ${props => (props.highlighted ? props.theme.secondary.base : props.theme.primary.dark)};
`

function PoolVisualizer(props) {
  return (
    <Container>
      {Object.values(props.pool).map(
        (category, index) =>
          category.length !== 0 && (
            <DotGroup key={index} onClick={() => props.handleQuestionDrawer(Object.keys(props.pool)[index])} whileTap={{ scale: 0.85 }}>
              <GroupTitle>{Object.keys(props.pool)[index]}</GroupTitle>
              {category
                .sort((a, b) => a.timestamp - b.timestamp)
                .map((question, index) => (
                  <QuestionDot key={index} highlighted={question.author === props.user.uid ? true : false} />
                ))}
            </DotGroup>
          )
      )}
    </Container>
  )
}

export default PoolVisualizer
