import { AnimatePresence, motion } from 'framer-motion'
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Context } from '../../../context/ContextProvider'
import { storage } from '../../../database/config'

const QuestionWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
`

const ImageContainer = styled(motion.div)`
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  &:before {
    content: '';
    width: 100%;
    height: 80%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    /* background: linear-gradient(0deg, rgba(55, 68, 152, 1) 0%, rgba(55, 68, 152, 0) 100%); */
    background: ${props => 'linear-gradient(0deg, ' + props.theme.primary.base + ' 0%, rgba(0, 0, 0, 0) 100%);'};
    z-index: 2000;
  }
`

const TextContainer = styled.div`
  position: fixed;
  bottom: 388px;
  left: 0px;
  width: calc(100% - 32px);
  height: auto;
  padding: ${props => props.theme.space.m} ${props => props.theme.space.m} ${props => props.theme.space.m} ${props => props.theme.space.m};
  color: ${props => props.theme.neutral.bright};
  font-size: ${props => props.theme.fontSize.xl};
  line-height: ${props => props.theme.lineHeight.xl};
  font-weight: ${props => props.theme.fontWeight.bold};
  text-align: left;
  z-index: 3000;
  text-shadow: ${props => props.theme.shadow.text};
`

function ImageQuestion(props) {
  const context = useContext(Context)
  const [url, setUrl] = useState()
  const [legacy, setLagacy] = useState(false)
  const [imageLoading, setImageLoading] = useState(true)

  useEffect(() => {
    console.log(props)
    if (props.imageName) {
       defaultDownload()
    } else {
      setLagacy(true)
      legacyGsDownlod()
    }

    // context.updateHeaderVisibility(false)
  }, [])

  function defaultDownload() {
    storage
      .refFromURL('gs://friendsquiz-2020.appspot.com/groups/' + context.userProfile.activeGroup + '/question-images/' + props.imageName + '_1024x1024')
      .getDownloadURL()
      .then(function(url) {
        setUrl(url)
      })
      .catch(function(error) {
        switch (error.code) {
          case 'storage/object-not-found':
            break
          case 'storage/unauthorized':
            break
          case 'storage/canceled':
            break
          case 'storage/unknown':
            break
          default:
            break
        }
      })
  }

  function legacyGsDownlod() {
    storage
      .refFromURL(props.imageURL)
      .getDownloadURL()
      .then(function(url) {
        setUrl(url)
      })
      .catch(function(error) {
        switch (error.code) {
          case 'storage/object-not-found':
            break
          case 'storage/unauthorized':
            break
          case 'storage/canceled':
            break
          case 'storage/unknown':
            break
          default:
            break
        }
      })
  }

  function changeLoadingStatus() {
    setImageLoading(false)
  }

  const imageReveal = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  }

  return (
    <QuestionWrapper>
      <AnimatePresence>
        {url && (
          <ImageContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <motion.div key={url} variants={imageReveal} initial="hidden" animate={imageLoading ? 'hidden' : 'visible'}>
              <img onLoad={changeLoadingStatus} src={url} alt={props.text} />
            </motion.div>
          </ImageContainer>
        )}
        <TextContainer>{props.text}</TextContainer>
      </AnimatePresence>
    </QuestionWrapper>
  )
}

export default ImageQuestion
