import React, { useEffect } from 'react'
import { db } from '../config'

export function useQuizResults(group, quizId) {
  const [error] = React.useState(false)
  const [loading] = React.useState(true)
  const [round_0, setRound_0] = React.useState()
  const [round_1, setRound_1] = React.useState()
  const [round_2, setRound_2] = React.useState()
  const [round_3, setRound_3] = React.useState()
  const [quizResults, setQuizResults] = React.useState()

  const setRoundResult = (round, result) => {
    switch (round) {
      case 'round_0':
        setRound_0(result)
        break
      case 'round_1':
        setRound_1(result)
        break
      case 'round_2':
        setRound_2(result)
        break
      case 'round_3':
        setRound_3(result)
        break
      default:
        break
    }
  }

  useEffect(() => {
    const unsubscribe = db
      .collection('groups')
      .doc(group)
      .collection('quizzes')
      .doc(quizId)
      .collection('results')
      .onSnapshot(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          setRoundResult(doc.id, doc.data())
        })
      })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    setQuizResults({ round_0, round_1, round_2, round_3 })
  }, [round_0, round_1, round_2, round_3])

  return {
    error,
    loading,
    quizResults,
  }
}
