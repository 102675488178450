import firebase from 'firebase/app'
import React, { useEffect } from 'react'
import { db } from '../config'

export function useMemberships(user) {
  const [error] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [memberships, setMemberships] = React.useState([])
  const [membershipStatus, setMembershipStatus] = React.useState([])
  const [membershipObjects, setMembershipObjects] = React.useState([])

  useEffect(() => {
    let unsubscribe = () => {}

    user &&
      (unsubscribe = db
        .collection('users')
        .doc(user.uid)
        .collection('memberships')
        .onSnapshot(function(querySnapshot) {
          let list = []
          let statusList = []
          querySnapshot.forEach(function(doc) {
            const id = doc.id
            const data = doc.data()
            const listItem = id
            const statusItem = {
              id: doc.id,
              status: data.status,
            }
            list.push(listItem)
            statusList.push(statusItem)
          })
          setLoading(false)
          setMemberships(list)
          setMembershipStatus(statusList)
        }))

    return () => unsubscribe()
  }, [user])

  useEffect(() => {
    memberships.length > 0 &&
      db
        .collection('groups')
        .where(firebase.firestore.FieldPath.documentId(), 'in', memberships)
        .onSnapshot(function(querySnapshot) {
          let list = []
          querySnapshot.forEach(function(doc) {
            const id = doc.id
            const data = doc.data()
            const listItem = {
              id: id,
              name: data.name,
              customQuizName: data.customQuizName,
              customThemeColors: data.slogan,
              thumbnailName: data.thumbnailName,
              timestamp: data.timestamp,
            }
            list.push(listItem)
          })
          setLoading(false)
          setMembershipObjects(list)
        })
  }, [memberships])

  return {
    error,
    memberships,
    membershipStatus,
    membershipObjects,
  }
}
