import firebase from 'firebase/app'
import { db } from '../config'

export const CREATE_QUESTIONS = (group, quizId, questionPool) => {
  const availableCategories = Object.keys(questionPool).filter(category => questionPool[category].length > 2)

  console.log(questionPool)

  const sortCategoriesByLength = Object.keys(questionPool).sort(category => questionPool[category].length)

  console.log(sortCategoriesByLength)

  const selectedCategories = Object.values(questionPool)
    .sort((a, b) => Math.random() + b.length * 0.002 - 0.5)
    .slice(0, 4)
    .sort(() => Math.random() - 0.5)

  // const selectedCategories = availableCategories.sort(() => Math.random() - 0.5).slice(0, 4)

  console.log(selectedCategories)

  selectedCategories.map((questions, index = 0) => {
    const batch = questions.sort(() => Math.random() - 0.5).slice(0, 3)
    const docKey = 'round_' + index

    db.collection('groups')
      .doc(group)
      .collection('quizzes')
      .doc(quizId)
      .collection('questions')
      .doc(docKey)
      .set({
        index: index,
        category: questions[0].category,
        question_0: batch[0],
        question_1: batch[1],
        question_2: batch[2],
      })
      .then(function() {
        console.info('Die Quizfragen wurden ausgewählt.')
      })
      .catch(function(error) {
        console.error('Die Quizfragen konnten nicht erstellt werden: ', error)
      })

    index++

    return null
  })
}

export const UPDATE_QUIZSCORE = (group, quizId, role, score) => {
  const key = role + 'Score'
  db.collection('groups')
    .doc(group)
    .collection('quizzes')
    .doc(quizId)
    .update({
      [key]: score,
    })
    .then(function() {
      console.info('Dein Punktestand wurde erfolgreich aktualisiert.')
    })
    .catch(function(error) {
      console.error('Dein Punkte stand konnte nicht aktualisiert werden: ', error)
    })
}

export const UPDATE_QUIZRESULT = (group, quizId, round, questionIndex, role, result, answerLabel, callback) => {
  console.log('RUN UPDATE_QUIZRESULT')

  const resultKey = role + '_' + questionIndex
  const roundValue = 'round_' + round
  const indexKey = role + 'Index'
  const indexValue = questionIndex + 1

  db.collection('groups')
    .doc(group)
    .collection('quizzes')
    .doc(quizId)
    .collection('results')
    .doc(roundValue)
    .update({
      [indexKey]: indexValue,
      [resultKey]: { result: result, answer: answerLabel },
    })
    .then(function() {
      callback()
      console.info('Dein Ergebnis wurde gespeichert.')
    })
    .catch(function(error) {
      console.error('Das Ergebnis konnte leider nicht gespeichert werden: ', error)
    })
}

export const SWITCH_TURN = (group, quizId, newOwner) => {
  db.collection('groups')
    .doc(group)
    .collection('quizzes')
    .doc(quizId)
    .update({
      turnOwner: newOwner,
    })
    .then(function() {
      console.info('Der Spielerwechsel war erfolgreich.')
    })
    .catch(function(error) {
      console.error('Der Spieler konnte nicht gewechselt werden: ', error)
    })
}

export const INCREASE_ROUND = (group, quizId, nextRound, playerRole) => {
  if (playerRole === 'challenger') {
    db.collection('groups')
      .doc(group)
      .collection('quizzes')
      .doc(quizId)
      .update({
        challengerRound: nextRound,
      })
      .then(function() {
        console.info('Der Runden-Index wurde erhöht.')
      })
      .catch(function(error) {
        console.error('Der Runden-Index konnte nicht erhöht werden: ', error)
      })
  } else {
    db.collection('groups')
      .doc(group)
      .collection('quizzes')
      .doc(quizId)
      .update({
        opponentRound: nextRound,
      })
      .then(function() {
        console.info('Runden-Index wurde erhöht.')
      })
      .catch(function(error) {
        console.error('Der Runden-Index konnte nicht erhöht werden: ', error)
      })
  }
}

export const SET_WINNER = (group, quizId, winner) => {
  db.collection('groups')
    .doc(group)
    .collection('quizzes')
    .doc(quizId)
    .update({
      winner: winner,
    })
    .then(function() {
      console.info('Der Gewinner wurde gespeichert.')
    })
    .catch(function(error) {
      console.error('Der Gewinner konnte nicht gespeichert werden: ', error)
    })
}

export const ARCHIVE_QUIZ = (group, quizId) => {
  db.collection('groups')
    .doc(group)
    .collection('quizzes')
    .doc(quizId)
    .update({
      status: 'archived',
    })
    .then(function() {
      console.info('Das Quiz wurde erfolgreich archiviert.')
    })
    .catch(function(error) {
      console.error('Das Quiz konnte nicht archiviert werden: ', error)
    })
}

export const SAVE_REACTION = (group, questionId, user, reaction) => {
  console.log(questionId)
  console.log(user)

  db.collection('groups')
    .doc(group)
    .collection('questionPool')
    .doc(questionId)
    .collection('reactions')
    .doc(reaction)
    .update({
      selectedBy: firebase.firestore.FieldValue.arrayUnion(user),
    })
    .then(function() {
      console.info('Die Reaktion wurde gespeichert.')
      console.log(questionId)
    })
    .catch(function(error) {
      console.error('Reaktionen werden erstellt.')

      db.collection('groups')
        .doc(group)
        .collection('questionPool')
        .doc(questionId)
        .collection('reactions')
        .doc(reaction)
        .set({
          selectedBy: firebase.firestore.FieldValue.arrayUnion(user),
        })
        .then(function() {
          console.info('Die Reaktion wurde gespeichert.')
          console.log(questionId)
        })
        .catch(function(error) {
          console.error('Die Reaktion konnte nicht gespeichert werden: ', error)
        })
    })
}
