import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ResultBadge } from '.'

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.theme.space.sm} ${props => props.theme.space.m};
  border-bottom: 1px solid ${props => props.theme.primary.base};

  &:last-child {
    border-bottom: none;
  }
`

const ResultGroup = styled.div`
  display: flex;
  order: ${props => props.order};
`

const CategoryContainer = styled.div`
  order: 2;
  color: ${props => props.theme.neutral.bright};
  font-size: ${props => props.theme.fontSize.s};
  font-weight: ${props => props.theme.fontWeight.bold};
`

const StatusBadge = styled.div`
  display: flex;
  width: 84px;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.primary.dark};
  font-size: ${props => props.theme.fontSize.s};
  font-weight: ${props => props.theme.fontWeight.medium};
`

function ResultRow(props) {
  const [challengerState, setChallengerState] = useState('exposed')
  const [opponentState, setOpponentState] = useState('exposed')
  const [challengerBadge, setChallengerBadge] = useState('')
  const [opponentBadge, setOpponentBadge] = useState('')

  function resetBadge() {
    setOpponentState('exposed')
    setOpponentBadge('')
    setChallengerState('exposed')
    setChallengerBadge('')
  }

  useEffect(() => {
    // IST AM ZUG

    let adversary = null

    if (props.role === 'challenger') {
      adversary = 'opponent'
    } else {
      adversary = 'challenger'
    }

    if (props.turnOwner !== props.role && props.representedRound === props.quizRound[adversary]) {
      if (props.role === 'challenger' && props.results.opponentIndex === 0) {
        setOpponentState('hidden')
        setOpponentBadge('Ist dran!')
      } else if (props.role === 'opponent' && props.results.challengerIndex === 0) {
        setChallengerState('hidden')
        setChallengerBadge('Ist dran!')
      } else {
        resetBadge()
      }
    } else {
      resetBadge()
    }

    // DU BIST DRAN

    if (props.turnOwner === props.role && props.representedRound === props.quizRound[props.role]) {
      if (props.role === 'challenger' && props.results.challengerIndex === 0) {
        setChallengerState('hidden')
        setChallengerBadge('Dein Zug')
      } else if (props.role === 'opponent' && props.results.opponentIndex === 0) {
        setOpponentState('hidden')
        setOpponentBadge('Dein Zug')
      }
    }
  }, [props])

  return (
    <Row>
      <ResultGroup order={props.role === 'challenger' ? '1' : '3'}>
        {challengerState === 'exposed' ? (
          <>
            <ResultBadge
              role="challenger"
              showQuestion={props.showQuestion}
              closeQuestionModal={props.closeQuestionModal}
              quizRound={props.representedRound}
              quizRoundIndex={0}
              result={props.results ? props.results.challenger_0.result : undefined}
            />
            <ResultBadge
              role="challenger"
              showQuestion={props.showQuestion}
              closeQuestionModal={props.closeQuestionModal}
              quizRound={props.representedRound}
              quizRoundIndex={1}
              result={props.results ? props.results.challenger_1.result : undefined}
            />
            <ResultBadge
              role="challenger"
              showQuestion={props.showQuestion}
              closeQuestionModal={props.closeQuestionModal}
              quizRound={props.representedRound}
              quizRoundIndex={2}
              result={props.results ? props.results.challenger_2.result : undefined}
            />
          </>
        ) : (
          <StatusBadge state={challengerBadge} unselectable="on">
            {challengerBadge}
          </StatusBadge>
        )}
      </ResultGroup>
      <CategoryContainer unselectable="on">{props.category}</CategoryContainer>
      <ResultGroup order={props.role === 'opponent' ? '1' : '3'}>
        {opponentState === 'exposed' ? (
          <>
            <ResultBadge
              role="opponent"
              showQuestion={props.showQuestion}
              closeQuestionModal={props.closeQuestionModal}
              quizRound={props.representedRound}
              quizRoundIndex={0}
              result={props.results ? props.results.opponent_0.result : undefined}
            />
            <ResultBadge
              role="opponent"
              showQuestion={props.showQuestion}
              closeQuestionModal={props.closeQuestionModal}
              quizRound={props.representedRound}
              quizRoundIndex={1}
              result={props.results ? props.results.opponent_1.result : undefined}
            />
            <ResultBadge
              role="opponent"
              showQuestion={props.showQuestion}
              closeQuestionModal={props.closeQuestionModal}
              quizRound={props.representedRound}
              quizRoundIndex={2}
              result={props.results ? props.results.opponent_2.result : undefined}
            />
          </>
        ) : (
          <StatusBadge state={opponentBadge} unselectable="on">
            {opponentBadge}
          </StatusBadge>
        )}
      </ResultGroup>
    </Row>
  )
}

export default ResultRow
