import React, { useEffect } from 'react'
import { db } from '../config'

export function useProfile(uid) {
  const [profileError, setProfileError] = React.useState(false)
  const [profileLoading, setProfileLoading] = React.useState(true)
  const [existingProfile, setExistingProfile] = React.useState(false)
  const [profile, setProfile] = React.useState()
  const [memberships, setMemberships] = React.useState()

  useEffect(() => {
    let unsubscribe = () => {}
    let membershipList = []

    uid &&
      (unsubscribe = db
        .collection('users')
        .doc(uid)
        .collection('memberships')
        .onSnapshot(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            membershipList.push(doc.id)
          })
        }))

    setMemberships(membershipList)

    console.log(membershipList)

    return () => unsubscribe()
  }, [uid])

  useEffect(() => {
    let unsubscribe = () => {}

    console.log(uid)

    uid &&
      (unsubscribe = db
        .collection('users')
        .doc(uid)
        .onSnapshot(function(doc) {
          if (doc.exists) {
            setProfile({ uid: doc.id, ...doc.data(), memberships: memberships })
            setExistingProfile(true)
          } else {
            setExistingProfile(false)
          }
        }))

    return () => unsubscribe()
  }, [memberships, uid])

  return {
    profileError,
    profileLoading,
    existingProfile,
    profile,
    memberships,
  }
}
