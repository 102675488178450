import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

const PanelContainer = styled(motion.div)`
  background: ${props => props.theme.neutral.bright};
  border-radius: ${props => props.theme.radius.m};
  box-shadow: ${props => props.theme.shadow.light};
  margin-bottom: ${props => props.theme.space.xs};
  padding: 20px;

  &:last-child {
    margin-bottom: 0px;
  }
`

function Panel(props) {
  return <PanelContainer>{props.children}</PanelContainer>
}

export default Panel
