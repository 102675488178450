import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CHANGE_GROUPDATA } from '../../database/actions/groupActions'
import { CloseButton } from '../components/buttons'
import { SingleStepDialog } from '../components/dialog'
import { ColorCodePicker, ColorPicker, Input } from '../components/inputs'
import { Layer } from '../components/layout'

const Header = styled.div`
  position: relative;
  padding: ${props => props.theme.space.sm};
  color: ${props => props.theme.neutral.bright};
  font-weight: ${props => props.theme.fontWeight.medium};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Section = styled.section`
  margin-bottom: ${props => props.theme.space.m};

  &:last-child {
    margin-bottom: 0px;
  }
`

const SwatchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`

const ColorSwatch = styled(motion.div)`
  position: relative;
  height: 32px;
  width: 100%;
  border-radius: 8px;
  box-shadow: ${props => props.theme.shadow.light};
  background-color: ${props => props.color};
  margin-right: ${props => props.theme.space.s};

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
    top: 50%;
    left: 50%;
    border-radius: 12px;
    background-color: ${props => props.color};
    opacity: 0.2;
    border: ${props => (props.active ? '4px' : '0px')} solid ${props => props.color};
    transform: translate(-50%, -50%);
    transition: border 0.3s;
  }

  &:last-child {
    margin-right: 0px;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: -15%;
    left: 0%;
    background-color: ${props => props.color};
    opacity: 0.3;
    border-radius: 100%;
    filter: blur(4px);
  }
`

const ColorPickerContainer = styled(motion.div)`
  background-color: ${props => props.theme.neutral.light};
  padding: ${props => props.theme.space.m};
  border-radius: 8px;
  margin-top: ${props => props.theme.space.m};
  z-index: 1000;
`

function GroupEditor(props) {
  const [groupName, setGroupName] = useState(props.context.activeGroup.name)
  const [customThemeColors, setCustomThemeColors] = useState(props.context.activeGroup.customThemeColors)
  const [customThemeHSL, setCustomThemeHSL] = useState(props.context.activeGroup.customThemeHSL)
  const [customThemeHEX, setCustomThemeHEX] = useState(props.context.activeGroup.customThemeHEX)

  const [color, setColor] = useState(
    new window.w3color('hsl(' + customThemeHSL.primary[0] + ',' + customThemeHSL.primary[1] + '%,' + customThemeHSL.primary[2] + '%')
  )
  const [hue, setHue] = useState()
  const [saturation, setSaturation] = useState()
  const [lightness, setLightness] = useState()

  const [activeSwatch, setActiveSwatch] = useState()

  const saveChanges = () => {
    CHANGE_GROUPDATA(props.context.userProfile.activeGroup, groupName, customThemeColors, customThemeHSL, customThemeHEX)
    props.close()
  }

  useEffect(() => {
    let newThemeHSL
    let newThemeHEX

    switch (activeSwatch) {
      case 0:
        newThemeHSL = {
          primary: [hue, saturation, lightness],
          secondary: customThemeHSL.secondary,
          tertiary: customThemeHSL.tertiary,
        }
        newThemeHEX = [color.toHexString(), customThemeColors[1], customThemeColors[2]]
        break
      case 1:
        newThemeHSL = {
          primary: customThemeHSL.primary,
          secondary: [hue, saturation, lightness],
          tertiary: customThemeHSL.tertiary,
        }
        newThemeHEX = [customThemeColors[0], color.toHexString(), customThemeColors[2]]
        break
      case 2:
        newThemeHSL = {
          primary: customThemeHSL.primary,
          secondary: customThemeHSL.secondary,
          tertiary: [hue, saturation, lightness],
        }
        newThemeHEX = [customThemeColors[0], customThemeColors[1], color.toHexString()]
        break
    }

    newThemeHSL && setCustomThemeHSL(newThemeHSL)
    newThemeHEX && setCustomThemeColors(newThemeHEX)
    newThemeHEX && setCustomThemeHEX(newThemeHEX)
  }, [hue, saturation, lightness])

  const onChange = ({ hue, saturation, lightness }) => {
    setColor(new window.w3color(`hsl(${hue}, ${saturation}%), ${lightness}%`))
    setHue(hue)
    setSaturation(saturation)
    setLightness(lightness)
  }

  const onHEXCodeChange = hex => {
    const hexCode = hex.replace(/\#/g, '')
    const newColor = new window.w3color(`${hexCode}`)
    hexCode.length === 6 && setColor(newColor)
  }

  const onHSLCodeChange = hslArray => {
    const newColor = new window.w3color(`hsl(${hslArray[0]}, ${hslArray[1]}%), ${hslArray[2]}%`)
    setColor(newColor)
  }

  const onRGBCodeChange = rgbArray => {
    setColor(new window.w3color(`rgb(${rgbArray[0]}, ${rgbArray[1]}), ${rgbArray[2]}`))
  }

  function changeGroupName(newName) {
    setGroupName(newName)
  }

  const selectSwatch = index => {
    index !== activeSwatch ? setActiveSwatch(index) : setActiveSwatch()
  }

  useEffect(() => {
    const primaryColor = customThemeColors[0].replace(/\#/g, '')
    const secondaryColor = customThemeColors[1].replace(/\#/g, '')
    const tertiaryColor = customThemeColors[2].replace(/\#/g, '')

    switch (activeSwatch) {
      case 0:
        setColor(new window.w3color(`${primaryColor}`))
        break
      case 1:
        setColor(new window.w3color(new window.w3color(`${secondaryColor}`)))
        break
      case 2:
        setColor(new window.w3color(new window.w3color(`${tertiaryColor}`)))
        break
    }
  }, [activeSwatch])

  return (
    <Layer gradientStart="top">
      <Header>
        Gruppeneinstellungen
        <CloseButton closeFunction={props.close} />
      </Header>
      <SingleStepDialog primaryLabel="Aktualisieren" primaryAction={saveChanges}>
        <Section>
          <h3>Gruppenname</h3>
          <Input placeholder="Gruppenname" value={groupName} emitValue={changeGroupName} maxLength="30" />
        </Section>
        <Section>
          <h3>Theme</h3>
          <SwatchContainer>
            <ColorSwatch
              active={activeSwatch === 0 ? true : false}
              color={customThemeColors[0]}
              whileTap={{ scale: 0.9 }}
              onClick={() => selectSwatch(0)}
            />
            <ColorSwatch
              active={activeSwatch === 1 ? true : false}
              color={customThemeColors[1]}
              whileTap={{ scale: 0.9 }}
              onClick={() => selectSwatch(1)}
            />
            <ColorSwatch
              active={activeSwatch === 2 ? true : false}
              color={customThemeColors[2]}
              whileTap={{ scale: 0.9 }}
              onClick={() => selectSwatch(2)}
            />
          </SwatchContainer>
          <AnimatePresence>
            {activeSwatch !== undefined && (
              <ColorPickerContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <ColorCodePicker
                  hsl={color.toHslString()}
                  rgb={color.toRgbString()}
                  hex={color.toHexString()}
                  emitHSL={onHSLCodeChange}
                  emitRGB={onRGBCodeChange}
                  emitHEX={onHEXCodeChange}
                />
                <ColorPicker hue={color.hue} saturation={color.sat * 100} lightness={color.lightness * 100} onChange={onChange} />
              </ColorPickerContainer>
            )}
          </AnimatePresence>
        </Section>
      </SingleStepDialog>
    </Layer>
  )
}

export default GroupEditor
