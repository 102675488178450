import { motion } from 'framer-motion'
import React, { useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { Context } from '../../context/ContextProvider'
import { usePrevious } from '../../hooks/usePrevious'
import { Spinner } from '../components/atoms'

const Container = styled(motion.div)`
  width: 100%;
  height: calc(100vh - 128px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

function Preloader(props) {
  const context = useContext(Context)

  const [loading, setLoading] = useState(true)
  const [spinner, setSpinner] = useState(false)

  const prevQuestionPool = usePrevious(context.questionPool)

  useEffect(() => {
    console.log(context)
    if (context.activeGroup) {
      setSpinner(true)
    }
  }, [context.activeGroup])

  useEffect(() => {
    if (context.questionPool) {
      setSpinner(false)
      setLoading(false)
    } else if (context.questionPool && prevQuestionPool && prevQuestionPool !== context.questionPool) {
      setSpinner(false)
      setLoading(false)
    }
  }, [context.questionPool])

  return (
    <Container key="preloader" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      {loading === false && (
        <Redirect
          to={{
            pathname: '/' + context.userProfile.activeGroup,
          }}
        />
      )}
      {spinner && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <Spinner>Fragen werden geladen ...</Spinner>
        </motion.div>
      )}
    </Container>
  )
}

export default Preloader
