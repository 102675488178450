import React, { useContext, useEffect } from 'react'
import { Context } from '../../context/ContextProvider'
import { db } from '../config'

export function useUserStatistics() {
  const context = useContext(Context)

  const [error] = React.useState(false)
  const [userStatistics, setUserStatistics] = React.useState()

  useEffect(() => {
    const unsubscribe = db
      .collection('groups')
      .doc(context.userProfile.activeGroup)
      .collection('statistics')
      .onSnapshot(function(querySnapshot) {
        let statistics = {}

        querySnapshot.forEach(function(doc) {
          const data = doc.data()
          const id = doc.id

          statistics = { ...statistics, [id]: { victories: data.victory, draws: data.draws, defeats: data.defeats, id: doc.id } }
        })

        setUserStatistics(statistics)
      })

    return () => unsubscribe()
  }, [])

  return { error, userStatistics }
}
