import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: inline-block;
  width: auto;
  position: relative;
  height: 172px;
  display: flex;
  align-items: center;
`

const ColorSlider = styled.div`
  --handle-size: 64;
  height: 28px;
  width: 100%;
  border: 4px solid ${props => props.theme.neutral.light};
  border-radius: 14px;
  position: relative;
  cursor: pointer;
  background: linear-gradient(
    90deg,
    hsl(0, calc(var(--saturation, 100) * 1%), calc(var(--lightness, 50) * 1%)),
    hsl(36, calc(var(--saturation, 100) * 1%), calc(var(--lightness, 50) * 1%)),
    hsl(72, calc(var(--saturation, 100) * 1%), calc(var(--lightness, 50) * 1%)),
    hsl(108, calc(var(--saturation, 100) * 1%), calc(var(--lightness, 50) * 1%)),
    hsl(144, calc(var(--saturation, 100) * 1%), calc(var(--lightness, 50) * 1%)),
    hsl(180, calc(var(--saturation, 100) * 1%), calc(var(--lightness, 50) * 1%)),
    hsl(216, calc(var(--saturation, 100) * 1%), calc(var(--lightness, 50) * 1%)),
    hsl(252, calc(var(--saturation, 100) * 1%), calc(var(--lightness, 50) * 1%)),
    hsl(288, calc(var(--saturation, 100) * 1%), calc(var(--lightness, 50) * 1%)),
    hsl(324, calc(var(--saturation, 100) * 1%), calc(var(--lightness, 50) * 1%)),
    hsl(360, calc(var(--saturation, 100) * 1%), calc(var(--lightness, 50) * 1%))
  );
`

const SliderArc = styled.div`
  height: calc(var(--handle-size) * 2px + 4px);
  width: calc(var(--handle-size) * 2px + 4px);
  position: absolute;
  top: 50%;
  left: calc(var(--value, 0) * 1%);
  transform: translate(-50%, -50%);
  border-radius: 100%;
  opacity: 0.2;
  border: 4px solid ${props => props.theme.neutral.text};
  -webkit-clip-path: polygon(0 0, 100% 0, 50% 50%, 100% 100%, 0 100%, 50% 50%);
  clip-path: polygon(0 0, 100% 0, 50% 50%, 100% 100%, 0 100%, 50% 50%);
`

const SliderHandle = styled.div`
  height: calc(var(--handle-size) * 1px);
  width: calc(var(--handle-size) * 1px);
  border-radius: 100%;
  background: hsl(var(--hue, 0), calc(var(--saturation, 100) * 1%), calc(var(--lightness, 50) * 1%));
  border: 4px solid #fff;
  box-shadow: ${props => props.theme.shadow.light};
  touch-action: none;
  position: absolute;
  top: 50%;
  left: calc(var(--value, 0) * 1%);
  transform: translate(-50%, -50%);
  cursor: var(--cursor);
`

const SaturationHandle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: transparent;
  border: 0;
  transform: translate(-50%, -50%) rotate(90deg) rotate(calc(var(--angle) * 1deg)) translate(0, calc(var(--handle-size) * 1px));
  &:before {
    display: none;
  }
  &:after {
    content: '';
    position: absolute;
    height: 50%;
    width: 50%;
    border: 4px solid #fff;
    box-shadow: ${props => props.theme.shadow.light};
    border-radius: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(
      90deg,
      hsl(0, calc(var(--saturation, 50) * 1%), 50%),
      hsl(36, calc(var(--saturation, 50) * 1%), 50%),
      hsl(72, calc(var(--saturation, 50) * 1%), 50%),
      hsl(108, calc(var(--saturation, 50) * 1%), 50%),
      hsl(144, calc(var(--saturation, 50) * 1%), 50%),
      hsl(180, calc(var(--saturation, 50) * 1%), 50%),
      hsl(216, calc(var(--saturation, 50) * 1%), 50%),
      hsl(252, calc(var(--saturation, 50) * 1%), 50%),
      hsl(288, calc(var(--saturation, 50) * 1%), 50%),
      hsl(324, calc(var(--saturation, 50) * 1%), 50%),
      hsl(360, calc(var(--saturation, 50) * 1%), 50%)
    );
  }
`

const LightnessHandle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: transparent;
  border: 0;
  transform: translate(-50%, -50%) rotate(90deg) rotate(calc(var(--angle) * 1deg)) translate(0, calc(var(--handle-size) * 1px));
  &:before {
    display: none;
  }
  &:after {
    content: '';
    position: absolute;
    height: 50%;
    width: 50%;
    border: 4px solid #fff;
    box-shadow: ${props => props.theme.shadow.light};
    border-radius: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: hsl(0, 0%, calc(var(--lightness, 50) * 1%));
  }
`

const getAngle = (event, element, buffer) => {
  const { clientX: x, clientY: y } = event.touches && event.touches.length ? event.touches[0] : event
  const { x: handleX, y: handleY, width: handleWidth, height: handleHeight } = element.getBoundingClientRect()
  const handleCenterPoint = {
    x: handleX + handleWidth / 2,
    y: handleY + handleHeight / 2,
  }
  const angle = (Math.atan2(handleCenterPoint.y - y, handleCenterPoint.x - x) * 180) / Math.PI
  return Math.max(buffer, Math.min(180 - buffer, Math.abs(angle)))
}

const getInitialAngle = (value, buffer) => {
  return ((180 - buffer * 2) / 100) * value + buffer
}

function ColorPicker({
  hue: propsHue = 180,
  saturation: propsSaturation = 100,
  lightness: propsLightness = 50,
  handleSize = 64,
  BUFFER = 40,
  onChange,
}) {
  const [newColor, setNewColor] = useState(false)
  const [hue, setHue] = useState(propsHue)
  const [cursor, setCursor] = useState('grab')
  const [lightness, setLightness] = useState(propsLightness)
  const [saturation, setSaturation] = useState(propsSaturation)
  const [saturationAngle, setSaturationAngle] = useState(getInitialAngle(saturation, BUFFER))
  const [lightnessAngle, setLightnessAngle] = useState(180 + (180 - getInitialAngle(lightness, BUFFER)))
  const handleRef = useRef(null)
  const trackRef = useRef(null)

  useEffect(() => {
    setHue(propsHue)
    setSaturation(propsSaturation)
    setLightness(propsLightness)
  }, [propsHue, propsSaturation, propsLightness])

  const updateHue = e => {
    // Return if we are tapping a handle
    if (e.target.dataset.hslSliderHandle) return
    const { clientX: x } = e.touches && e.touches.length ? e.touches[0] : e
    const { left: trackLeft, width: trackWidth } = trackRef.current.getBoundingClientRect()
    const newValue = (x - trackLeft) / trackWidth
    setHue(Math.max(0, Math.min(360, newValue * 360)))
    setNewColor(true)
  }

  const updateLightness = event => {
    const angle = getAngle(event, handleRef.current, BUFFER)
    const lightness = ((angle - BUFFER) / (180 - BUFFER * 2)) * 100
    setLightnessAngle(180 + (180 - angle))
    setLightness(lightness)
    setNewColor(true)
  }

  const updateSaturation = event => {
    const angle = getAngle(event, handleRef.current, BUFFER)
    const saturation = ((angle - BUFFER) / (180 - BUFFER * 2)) * 100
    setSaturation(saturation)
    setSaturationAngle(angle)
    setNewColor(true)
  }

  const handleUp = onMove => {
    const up = () => {
      setCursor('grab')
      document.documentElement.style.setProperty('--cursor', 'initial')
      window.removeEventListener('mousemove', onMove)
      window.removeEventListener('touchmove', onMove)
      window.removeEventListener('mouseup', up)
      window.removeEventListener('touchend', up)
    }
    return up
  }

  const handleDown = (onMove, stopPropagation) => e => {
    if (stopPropagation) e.stopPropagation()
    setCursor('grabbing')
    document.documentElement.style.setProperty('--cursor', 'grabbing')
    window.addEventListener('mousemove', onMove)
    window.addEventListener('touchmove', onMove)
    window.addEventListener('mouseup', handleUp(onMove))
    window.addEventListener('touchend', handleUp(onMove))
  }

  useEffect(() => {
    trackRef.current.addEventListener('click', updateHue)
    return () => {
      trackRef.current.removeEventListener('click', updateHue)
    }
  }, [])

  useEffect(() => {
    onChange({ hue, saturation, lightness })
    setNewColor(false)
  }, [newColor])

  return (
    <Container>
      <ColorSlider
        ref={trackRef}
        className="hsl-slider"
        style={{
          '--handle-size': handleSize,
          '--lightness': lightness,
          '--saturation': saturation,
        }}
      >
        <SliderArc
          className="hsl-slider__arc"
          style={{
            '--value': Math.max(0, Math.min(100, (hue / 360) * 100)),
          }}
        />
        <SliderHandle
          className="hsl-slider__handle"
          ref={handleRef}
          style={{
            '--cursor': cursor,
            '--value': Math.max(0, Math.min(100, (hue / 360) * 100)),
            '--hue': hue,
          }}
          onMouseDown={handleDown(updateHue)}
          onTouchStart={handleDown(updateHue)}
          title="Set hue"
        >
          <SaturationHandle
            data-hsl-slider-handle="true"
            className="hsl-slider__handle hsl-slider__handle--saturation"
            onMouseDown={handleDown(updateSaturation, true)}
            onTouchStart={handleDown(updateSaturation, true)}
            style={{ '--angle': saturationAngle }}
            title="Set saturation"
          />
          <LightnessHandle
            data-hsl-slider-handle="true"
            className="hsl-slider__handle hsl-slider__handle--lightness"
            onMouseDown={handleDown(updateLightness, true)}
            onTouchStart={handleDown(updateLightness, true)}
            style={{ '--angle': lightnessAngle }}
            title="Set lightness"
          />
        </SliderHandle>
      </ColorSlider>
    </Container>
  )
}

export default ColorPicker

ColorPicker.propTypes = {
  hue: PropTypes.number,
  handleSize: PropTypes.number,
  saturation: PropTypes.number,
  lightness: PropTypes.number,
  BUFFER: PropTypes.number,
  onChange: PropTypes.func,
}
