import React, { useEffect } from 'react'
import { db } from '../config'

export function useQuizMessages(group, quizId) {
  const [error] = React.useState(false)
  const [loading] = React.useState(true)
  const [messages, setMessages] = React.useState()
  const [messageNumber, setMessageNumber] = React.useState(0)

  let messageList = []

  useEffect(() => {
    const unsubscribe = db
      .collection('groups')
      .doc(group)
      .collection('quizzes')
      .doc(quizId)
      .collection('messages')
      .onSnapshot(function(snapshot) {
        snapshot.docChanges().forEach(function(change) {
          const data = change.doc.data()

          const message = {
            body: data.body,
            sender: data.sender,
            receiver: data.receiver,
            timestamp: data.timestamp,
          }

          if (change.type === 'added') {
            messageList.push(message)
            setMessages(messageList)
            setMessageNumber(messageList.length)
          }
        })
      })

    return () => unsubscribe()
  }, [])

  return {
    error,
    loading,
    messages,
    messageNumber,
  }
}
