import { db } from '../config'

export const SEND_MESSAGE = (group, quizId, sender, receiver, message) => {
  const timestamp = new Date()
  
  db.collection('groups')
    .doc(group)
    .collection('quizzes')
    .doc(quizId)
    .collection('messages')
    .doc(timestamp.valueOf().toString())
    .set({
      timestamp: timestamp,
      sender: sender,
      receiver: receiver,
      body: message,
    })
    .then(function() {
      console.info('Die Nachricht wurde erfolgreich versendet.')
    })
    .catch(function(error) {
      console.error('Die Nachricht konnte nicht gesendet werden: ', error)
    })
}
