import firebase from 'firebase/app'
import { db } from '../config'
import { CREATE_QUESTIONS } from './quizActions'

export const CREATE_REQUEST = (
  group,
  requestId,
  challengerId,
  challengerName,
  opponentId,
  opponentName,
  requestCallback,
  modalCallback
) => {
  const timestamp = new Date()

  const quizIdPraefix = requestId.replace('request', 'quiz')

  let challengerQuizExists = true
  let opponentQuizExists = true
  let requestExists = true

  checkChallengerQuizzes()

  function checkChallengerQuizzes() {
    db.collection('groups')
      .doc(group)
      .collection('quizzes')
      .where('challenger', '==', challengerId)
      .where('opponent', '==', opponentId)
      .where('status', '==', 'open')
      .get()
      .then(function(querySnapshot) {
        querySnapshot.forEach(snapshot => console.log(snapshot.id))
        querySnapshot.size === 0 ? (challengerQuizExists = false) : (challengerQuizExists = true)
        checkOpponentQuizzes()
      })
      .catch(function(error) {
        console.log('Die Spiele des Herausforderes konnte nicht geladen werden: ', error)
      })
  }

  function checkOpponentQuizzes() {
    console.log(quizIdPraefix)
    db.collection('groups')
      .doc(group)
      .collection('quizzes')
      .where('challenger', '==', opponentId)
      .where('opponent', '==', challengerId)
      .where('status', '==', 'open')
      .get()
      .then(function(querySnapshot) {
        querySnapshot.size === 0 ? (opponentQuizExists = false) : (opponentQuizExists = true)
        checkRequests()
      })
      .catch(function(error) {
        console.log('Die Spiele des Gegenspielers konnte nicht geladen werden: ', error)
      })
  }

  function checkRequests() {
    console.log('Group' + group)
    db.collection('groups')
      .doc(group)
      .collection('requests')
      .where(firebase.firestore.FieldPath.documentId(), '==', requestId)
      .get()
      .then(function(querySnapshot) {
        querySnapshot.size === 0 ? (requestExists = false) : (requestExists = true)
        createNewRequest()
      })
      .catch(function(error) {
        console.log('Die Quiz-Einladungen konnten nicht geladen werden: ', error)
      })
  }

  function createNewRequest() {
    if (!challengerQuizExists && !opponentQuizExists && !requestExists) {
      db.collection('groups')
        .doc(group)
        .collection('requests')
        .doc(requestId)
        .set({
          timestamp: timestamp,
          challenger: challengerId,
          challengerName: challengerName,
          opponent: opponentId,
          opponentName: opponentName,
        })
        .then(function() {
          console.info('Die Quiz-Einladung wurde versendet.')
        })
        .catch(function(error) {
          console.error('Die Quiz-Einladung konnte nicht versendet werden: ', error)
        })
      requestCallback('success')
    } else {
      modalCallback('default', '✋🏻 Sorry', 'Du befindst dich mit diesem Spieler bereits in einem Quiz.', 'Alles klar', null, null, false)
      requestCallback('declined')
    }
  }
}

export const DELETE_REQUEST = (group, requestId) => {
  db.collection('groups')
    .doc(group)
    .collection('requests')
    .doc(requestId)
    .delete()
    .then(function() {
      console.info('Die Quiz-Einladung wurde entfernt.')
    })
    .catch(function(error) {
      console.error('Die Quiz-Einladung konnte nicht entfernt werden: ', error)
    })
}

export const CREATE_QUIZ = (group, quizId, challengerId, challengerName, opponentId, opponentName, questionPool) => {
  const timestamp = new Date()

  db.collection('groups')
    .doc(group)
    .collection('quizzes')
    .doc(quizId)
    .set({
      timestamp: timestamp,
      status: 'open',
      challenger: challengerId,
      challengerName: challengerName,
      opponent: opponentId,
      opponentName: opponentName,
      turnOwner: 'opponent',
      challengerRound: 0,
      opponentRound: 0,
      challengerScore: 0,
      opponentScore: 0,
      winner: null,
    })
    .then(function() {
      console.info('Das Quiz wurde erstellt.')
    })
    .catch(function(error) {
      console.error('Das Quiz konnte nicht erstellt werden: ', error)
    })

  db.collection('groups')
    .doc(group)
    .collection('quizzes')
    .doc(quizId)
    .collection('results')
    .doc('round_0')
    .set({
      challengerIndex: 0,
      opponentIndex: 0,
      opponent_0: { answer: null, label: null },
      opponent_1: { answer: null, label: null },
      opponent_2: { answer: null, label: null },
      challenger_0: { answer: null, label: null },
      challenger_1: { answer: null, label: null },
      challenger_2: { answer: null, label: null },
    })
    .then(function() {
      console.info('Die Quiz-Ergebnisse wurden vorbereitet.')
    })
    .catch(function(error) {
      console.error('Die Quiz-Ergebnisse konnten nicht vorbereitet werden: ', error)
    })

  db.collection('groups')
    .doc(group)
    .collection('quizzes')
    .doc(quizId)
    .collection('results')
    .doc('round_1')
    .set({
      challengerIndex: 0,
      opponentIndex: 0,
      opponent_0: { answer: null, label: null },
      opponent_1: { answer: null, label: null },
      opponent_2: { answer: null, label: null },
      challenger_0: { answer: null, label: null },
      challenger_1: { answer: null, label: null },
      challenger_2: { answer: null, label: null },
    })
    .then(function() {
      console.info('Der Index für Runde 1 wurde gesetzt.')
    })
    .catch(function(error) {
      console.error('Der Index für Runde 1 konnte nicht gesetzt werden: ', error)
    })

  db.collection('groups')
    .doc(group)
    .collection('quizzes')
    .doc(quizId)
    .collection('results')
    .doc('round_2')
    .set({
      challengerIndex: 0,
      opponentIndex: 0,
      opponent_0: { answer: null, label: null },
      opponent_1: { answer: null, label: null },
      opponent_2: { answer: null, label: null },
      challenger_0: { answer: null, label: null },
      challenger_1: { answer: null, label: null },
      challenger_2: { answer: null, label: null },
    })
    .then(function() {
      console.info('Der Index für Runde 2 wurde gesetzt.')
    })
    .catch(function(error) {
      console.error('Der Index für Runde 2 konnte nicht gesetzt werden: ', error)
    })

  db.collection('groups')
    .doc(group)
    .collection('quizzes')
    .doc(quizId)
    .collection('results')
    .doc('round_3')
    .set({
      challengerIndex: 0,
      opponentIndex: 0,
      opponent_0: { answer: null, label: null },
      opponent_1: { answer: null, label: null },
      opponent_2: { answer: null, label: null },
      challenger_0: { answer: null, label: null },
      challenger_1: { answer: null, label: null },
      challenger_2: { answer: null, label: null },
    })
    .then(function() {
      console.info('Der Index für Runde 3 wurde gesetzt.')
    })
    .catch(function(error) {
      console.error('Der Index für Runde 3 konnte nicht gesetzt werden: ', error)
    })

  CREATE_QUESTIONS(group, quizId, questionPool)
}

export const DELETE_ARCHIVE = uid => {
  db.collection('quizzes')
    .where('status', '==', 'archived')
    .delete()
    .then(function() {
      console.info('Das Archiv wurde geleert.')
    })
    .catch(function(error) {
      console.error('Das Archiv konnte nicht geleert werden: ', error)
    })
}
