import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { storage } from '../../../database/config'
import { EditButton } from '../buttons'

const ThumbnailWrapper = styled(motion.div)`
  position: relative;
  width: ${props => (props.size === 'small' ? '32px' : '56px')};
  height: ${props => (props.size === 'small' ? '32px' : '56px')};
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: ${props => (!props.hideIndicator ? '' : 'none')};
    border-radius: 100%;
    position: absolute;
    width: 6px;
    height: 6px;
    bottom: -14px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
    background: ${props => (props.status === 'active' ? props.theme.tertiary.base : 'transparent')};
  }
`

const Thumbnail = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: ${props => props.theme.radius.m};
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    filter: ${props => (props.status === 'active' ? 'saturate(1)' : 'saturate(0.6)')};
  }

  &:before {
    content: '';
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    top: 0px;
    left: 0px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: inset 0px 0px 6px rgba(255, 255, 255, 0.1);
    border-radius: ${props => props.theme.radius.m};
    z-index: 1500;
  }
`

const ImageContainer = styled(motion.div)`
  position: relative;
  z-index: 500;
`

const TileBody = styled.div`
  color: ${props => props.theme.neutral.bright};
  font-size: ${props => props.theme.fontSize.root};
  line-height: ${props => props.theme.lineHeight.root};
  position: relative;
  z-index: 1000;
`

const Background = styled(motion.div)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.primary.light};
  box-shadow: ${props => props.theme.shadow.base};
  transition: background-color 0.6s;
`

const EditButtonContainer = styled.div`
  position: absolute;
  bottom: -6px;
  right: -6px;
  z-index: 1000;
`

const PreloaderImage = styled.div`
  display: none;
  width: 0px;
  height: 0px;
`

function GroupThumbnail(props) {
  const [thumbnailURL, setThumbnailURL] = useState()
  const [thumbnailGS, setThumbnailGS] = useState()
  const [imageLoading, setImageLoading] = useState(true)

  const isMounted = useRef(false)

  useEffect(() => {
    if (props.size === 'small') {
      setThumbnailGS('gs://friendsquiz-2020.appspot.com/groups/' + props.groupId + '/' + props.thumbnailName + '_96x96')
    } else {
      setThumbnailGS('gs://friendsquiz-2020.appspot.com/groups/' + props.groupId + '/' + props.thumbnailName + '_168x168')
    }
  }, [])

  useEffect(() => {
    setImageLoading(true)
    if (props.size === 'small') {
      setThumbnailGS('gs://friendsquiz-2020.appspot.com/groups/' + props.groupId + '/' + props.thumbnailName + '_96x96')
    } else {
      setThumbnailGS('gs://friendsquiz-2020.appspot.com/groups/' + props.groupId + '/' + props.thumbnailName + '_168x168')
    }
  }, [props.thumbnailName])

  useEffect(() => {
    isMounted.current = true

    thumbnailGS &&
      storage
        .refFromURL(thumbnailGS)
        .getDownloadURL()
        .then(function(url) {
          isMounted.current && setThumbnailURL(url)
        })
        .catch(function(error) {
          switch (error.code) {
            case 'storage/object-not-found':
              console.warn('thumbnail not found')
              break
            case 'storage/unauthorized':
              console.warn('thumbnail unauthorized')
              break
            case 'storage/canceled':
              console.warn('thumbnail canceled')
              break
            case 'storage/unknown':
              console.warn('thumbnail unknow')
              break
            default:
              break
          }
        })

    return () => (isMounted.current = false)
  }, [thumbnailGS])

  const changeLoadingStatus = () => {
    setImageLoading(false)
  }

  return (
    <ThumbnailWrapper hideIndicator={props.hideIndicator} status={props.status} onClick={props.action && props.action} size={props.size}>
      {props.action && (
        <EditButtonContainer>
          <EditButton />
        </EditButtonContainer>
      )}
      <Thumbnail status={props.status}>
        <TileBody></TileBody>
        <AnimatePresence exitBeforeEnter>
          {thumbnailURL && (
            <ImageContainer key={thumbnailURL} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <img src={thumbnailURL} />
            </ImageContainer>
          )}
        </AnimatePresence>
        <Background image={thumbnailURL} />
      </Thumbnail>
      <PreloaderImage>
        <img onLoad={changeLoadingStatus} src={thumbnailURL} />
      </PreloaderImage>
    </ThumbnailWrapper>
  )
}

export default GroupThumbnail
