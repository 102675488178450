import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { ReactComponent as Request } from '../../../assets/icons/request.svg'
import { CREATE_QUIZ, DELETE_REQUEST } from '../../../database/actions/gameActions'
import { useModal } from '../../../hooks'
import { TeaserScore } from './'

const rotateIcon = keyframes`
  0% {
    transform: rotate(0deg)
  }
  15% {
    transform: rotate(180deg)
  }
  100% {
    transform: rotate(180deg)
  }
`

const TeaserPanel = styled(motion.div)`
  color: ${props => (props.status === 'active' ? props.theme.secondary.base : props.theme.primary.base)};
  background: ${props => props.theme.neutral.bright};
  border-radius: ${props => props.theme.radius.m};
  box-shadow: ${props => props.theme.shadow.light};
  margin-bottom: ${props => props.theme.space.xs};
  transition: filter 0.4s;

  &:last-child {
    margin-bottom: 0px;
  }

  a,
  span {
    width: 100%;
    display: inline-block;
    color: inherit;
    text-decoration: none;
    padding: ${props => props.theme.space.sm};
    font-weight: ${props => props.theme.fontWeight.medium};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`

const IconContainer = styled.div`
  position: relative;
  padding-left: ${props => props.theme.space.s};
  margin-left: ${props => props.theme.space.s};
  font-size: ${props => props.theme.fontSize.m};
  font-weight: ${props => props.theme.fontWeight.bold};
  width: 50px;
  display: flex;
  justify-content: center;

  svg {
    fill: ${props => (props.status === 'active' ? props.theme.secondary.base : props.theme.primary.base)};
    height: 20px;
    width: auto;
    animation: ${rotateIcon} linear;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.49, 0.01, 0, 1);
    animation-delay: 1s;
  }

  &:after {
    position: absolute;
    content: '';
    width: 1px;
    top: -40%;
    left: 0px;
    height: 180%;
    background-color: ${props => (props.status === 'active' ? props.theme.secondary.base : props.theme.primary.base)};
    opacity: 0.25;
  }
`

function Teaser(props) {
  const { showModal } = useModal()

  const [type, setType] = useState()
  const [title, setTitle] = useState()
  const [quizId, setQuizId] = useState()
  const [startQuiz, setStartQuiz] = useState(false)

  useEffect(() => {
    props.challenger === props.user.uid ? setTitle(props.opponentName) : setTitle(props.challengerName)
    props.id.includes('request') ? setType('request') : setType('quiz')
  }, [props])

  useEffect(() => {
    type === 'request' && setQuizId(props.id.replace('request-', '') + new Date().valueOf())
    type === 'quiz' && setQuizId(props.id)
  }, [type])

  function handleRequest() {
    if (props.challenger !== props.user.uid) {
      CREATE_QUIZ(
        props.userProfile.activeGroup,
        quizId,
        props.challenger,
        props.challengerName,
        props.opponent,
        props.opponentName,
        props.questionPool
      )
        DELETE_REQUEST(props.userProfile.activeGroup, props.id)

      setStartQuiz(true)
    } else showModal('default', '⏳ Geduld', 'Deine Herausforderung wurde noch nicht angenommen. Möchtest du sie löschen?', 'Löschen', {fn: deleteRequest}, true, null)
  }

  const clickHandler = () => {
    type === 'request' && handleRequest()
  }

  const deleteRequest = () => {
    DELETE_REQUEST(props.userProfile.activeGroup, props.id)
  }

  return (
    <TeaserPanel status={props.status} onClick={clickHandler} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      {type === 'request' ? (
        <div>
          <span>
            {title}
            <IconContainer status={props.status}>
              <Request />
            </IconContainer>
          </span>
        </div>
      ) : (
        <div>
          <Link to={'/' + props.userProfile.activeGroup + '/quiz/' + quizId}>
            {title}
            <TeaserScore
              id={props.id}
              status={props.status}
              challenger={props.challenger}
              user={props.user.uid}
              userProfile={props.userProfile}
            />
          </Link>
        </div>
      )}

      {startQuiz && <Redirect to={'/' + props.userProfile.activeGroup + '/quiz/' + quizId} />}
    </TeaserPanel>
  )
}

export default Teaser
