import { useContext } from 'react'
import { Notification } from '../context'

export function useNotification() {
  const notification = useContext(Notification)

  const showNotification = (nature, title, bodyText) => {
    notification.show(nature, title, bodyText)
  }

  const hideNotification = () => {
    notification.hide()
  }


  return {
    showNotification,
    hideNotification,
  }
}
