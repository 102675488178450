import React from 'react'
import styled from 'styled-components'

const Container = styled.div``

function Step(props) {
  return (
    <Container>{props.children}</Container>
  )
}

export default Step