import { AnimatePresence, motion } from 'framer-motion'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { Modal } from '../../../context/ModalProvider'
import { ConfirmModal, DefaultModal } from './'

const Layer = styled(motion.div)`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 30000;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1000;
    top: 0px;
    left: 0px;
    position: absolute;
    background-color: ${props => props.theme.primary.dark};
    opacity: 0.5;
  }
`

const layerTransition = {
  hidden: {
    opacity: 0,
    transition: {
      type: 'tween',
      duration: 0.3,
    },
  },
  visible: {
    opacity: 1,
  },
}

function GlobalModal() {
  const modal = useContext(Modal)

  return (
    <AnimatePresence>
      {modal.visible && (
        <Layer variants={layerTransition} initial="hidden" animate="visible" exit="hidden">
          {modal.type === 'confirmation' ? <ConfirmModal /> : <DefaultModal />}
        </Layer>
      )}
    </AnimatePresence>
  )
}

export default GlobalModal
