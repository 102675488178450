import React, { useEffect, useState } from 'react'

function Emoji(props) {
  const [emojiChar, setEmojiChar] = useState()

  useEffect(() => {
    let char

    switch (props.code) {
      case '128514':
        char = '😂'
        break
      case '129327':
        char = '🤯'
        break
      case '128525':
        char = '😍'
        break
      case '128553':
        char = '😩'
        break
      case '129488':
        char = '🧐'
        break
      default:
        console.log('Es wurde kein passendes Emoji gefunden.')
    }

    setEmojiChar(char)
  }, [])

  return <>{emojiChar}</>
}

export default Emoji
