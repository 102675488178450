import React, { useEffect } from 'react'
import { db } from '../config'

export function useReactions(group, questionID) {
  const [error, setError] = React.useState(false)
  const [loadingQuestionReactions, setLoadingQuestionReactions] = React.useState(true)
  const [questionReactions, setQuestionReactions] = React.useState()

  useEffect(() => {
    const unsubscribe = db
      .collection('groups')
      .doc(group)
      .collection('questionPool')
      .doc(questionID)
      .collection('reactions')
      .onSnapshot(function(querySnapshot) {
        let reactions = []

        querySnapshot.forEach(function(doc) {
          if (doc.exists) {
            reactions.push({ emoji: doc.id, selectedBy: doc.data().selectedBy })
          }
        })

        setQuestionReactions(reactions)
      })

    return () => unsubscribe()
  }, [group, questionID])

  return {
    error,
    loadingQuestionReactions,
    questionReactions,
  }
}
