import React from 'react'
import styled from 'styled-components'

const RowContainer = styled.div`
  display: flex;
  justify-content: ${props => props.justify};
`

function Row(props) {
  return <RowContainer justify={props.justify}>{props.children}</RowContainer>
}

export default Row
