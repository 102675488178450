import React, { useContext, useEffect } from 'react'
import { Context } from '../../context/ContextProvider'
import { UPDATE_QUIZSCORE } from '../actions/quizActions'

export function useResultInterpreter(quizId, results) {
  const context = useContext(Context)

  const [resultInterpretation, setResultInterpretation] = React.useState(false)

  const [quizResults, setQuizResults] = React.useState()
  const [challengerScore, setChallengerScore] = React.useState()
  const [challengerTurn, setChallengerTurn] = React.useState()
  const [challengerQnIndex, setChallengerQnIndex] = React.useState()
  const [opponentScore, setOpponentScore] = React.useState()
  const [opponentTurn, setOpponentTurn] = React.useState()
  const [opponentQnIndex, setOpponentQnIndex] = React.useState()
  const [quizCompleted, setQuizCompleted] = React.useState(false)

  useEffect(() => {
    results && results.round_3 !== undefined && setQuizResults(results)
  }, [results])

  useEffect(() => {
    let chgTurn = 0
    let oppTurn = 0

    if (quizResults) {
      Object.keys(quizResults).map((round, index) => {
        const { challengerIndex, opponentIndex } = quizResults[round]

        challengerIndex === 3 && chgTurn++
        opponentIndex === 3 && oppTurn++

        setChallengerTurn(chgTurn)
        setOpponentTurn(oppTurn)

      })
    }
  }, [quizResults])

  useEffect(() => {
    let chgScore = 0
    let oppScore = 0

    if (quizResults) {
      Object.keys(quizResults).map(round => {
        const {
          challengerIndex,
          opponentIndex,
          challenger_0,
          challenger_1,
          challenger_2,
          opponent_0,
          opponent_1,
          opponent_2,
        } = quizResults[round]

        const challengerRoundKey = 'round_' + challengerTurn
        const opponentRoundKey = 'round_' + opponentTurn

        challenger_0.result && chgScore++
        challenger_1.result && chgScore++
        challenger_2.result && chgScore++
        opponent_0.result && oppScore++
        opponent_1.result && oppScore++
        opponent_2.result && oppScore++

        if (round === challengerRoundKey) {
          setChallengerQnIndex(challengerIndex)
        }

        if (round === opponentRoundKey) {
          setOpponentQnIndex(opponentIndex)
        }

        if (challengerTurn) {
          setChallengerQnIndex(challengerIndex)
          setOpponentQnIndex(opponentIndex)
        }
      })

      setChallengerScore(chgScore)
      setOpponentScore(oppScore)
      setResultInterpretation(true)
    }
  }, [challengerTurn, opponentTurn])

  useEffect(() => {
    challengerScore && UPDATE_QUIZSCORE(context.userProfile.activeGroup, quizId, 'challenger', challengerScore)
    opponentScore && UPDATE_QUIZSCORE(context.userProfile.activeGroup, quizId, 'opponent', opponentScore)
  }, [challengerScore, opponentScore])

  useEffect(() => {
    if (challengerTurn === 4 && opponentTurn === 4 && challengerQnIndex === 3 && opponentQnIndex === 3) {
      setQuizCompleted(true)
    }
  }, [challengerTurn, opponentTurn, challengerQnIndex, opponentQnIndex])

  return {
    resultInterpretation,
    challengerScore,
    challengerTurn,
    challengerQnIndex,
    opponentScore,
    opponentTurn,
    opponentQnIndex,
    quizCompleted,
  }
}
