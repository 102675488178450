import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ColorCodePicker, ColorPicker } from '../inputs'

const Editor = styled.div``

const SwatchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`

const ColorSwatch = styled(motion.div)`
  position: relative;
  height: 36px;
  width: 100%;
  border-radius: 8px;
  box-shadow: ${props => props.theme.shadow.light};
  background-color: ${props => props.color};
  margin-right: ${props => props.theme.space.s};

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
    top: 50%;
    left: 50%;
    border-radius: 12px;
    background-color: ${props => props.color};
    opacity: 0.2;
    border: ${props => (props.active ? '4px' : '0px')} solid ${props => props.color};
    transform: translate(-50%, -50%);
    transition: border 0.3s;
  }

  &:last-child {
    margin-right: 0px;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: -15%;
    left: 0%;
    background-color: ${props => props.color};
    opacity: 0.3;
    border-radius: 100%;
    filter: blur(4px);
  }
`

const ColorPickerContainer = styled(motion.div)`
  background-color: ${props => props.theme.neutral.light};
  padding: ${props => props.theme.space.m};
  border-radius: 8px;
  margin-top: ${props => props.theme.space.m};
  z-index: 1000;
  overflow-y: hidden;
  overflow-x: visible;
`

function ThemeEditor(props) {
  const [themeHSL, setThemeHSL] = useState(props.themeHSL)
  const [themeHEX, setThemeHEX] = useState(props.themeHEX)

  const [color, setColor] = useState(
    new window.w3color('hsl(' + themeHSL.primary[0] + ',' + themeHSL.primary[1] + '%,' + themeHSL.primary[2] + '%')
  )

  const [hue, setHue] = useState()
  const [saturation, setSaturation] = useState()
  const [lightness, setLightness] = useState()

  const [activeSwatch, setActiveSwatch] = useState()

  useEffect(() => {
    let newThemeHSL
    let newThemeHEX

    console.log(activeSwatch)
    switch (activeSwatch) {
      case 0:
        newThemeHSL = {
          primary: [hue, saturation, lightness],
          secondary: themeHSL.secondary,
          tertiary: themeHSL.tertiary,
        }
        newThemeHEX = [color.toHexString(), themeHEX[1], themeHEX[2]]
        break
      case 1:
        newThemeHSL = {
          primary: themeHSL.primary,
          secondary: [hue, saturation, lightness],
          tertiary: themeHSL.tertiary,
        }
        newThemeHEX = [themeHEX[0], color.toHexString(), themeHEX[2]]
        break
      case 2:
        newThemeHSL = {
          primary: themeHSL.primary,
          secondary: themeHSL.secondary,
          tertiary: [hue, saturation, lightness],
        }
        newThemeHEX = [themeHEX[0], themeHEX[1], color.toHexString()]
        break
    }

    newThemeHEX && setThemeHEX(newThemeHEX)
    newThemeHSL && setThemeHSL(newThemeHSL)
    console.log('emitTheme')
    console.log(newThemeHEX)
    props.emitTheme(newThemeHEX, newThemeHSL)
  }, [hue, saturation, lightness])

  const onChange = ({ hue, saturation, lightness }) => {
    setColor(new window.w3color(`hsl(${hue}, ${saturation}%), ${lightness}%`))
    setHue(hue)
    setSaturation(saturation)
    setLightness(lightness)
  }

  const onHEXCodeChange = hex => {
    const hexCode = hex.replace(/\#/g, '')
    const newColor = new window.w3color(`${hexCode}`)
    hexCode.length === 6 && setColor(newColor)
  }

  const onHSLCodeChange = hslArray => {
    const newColor = new window.w3color(`hsl(${hslArray[0]}, ${hslArray[1]}%), ${hslArray[2]}%`)
    setColor(newColor)
  }

  const onRGBCodeChange = rgbArray => {
    setColor(new window.w3color(`rgb(${rgbArray[0]}, ${rgbArray[1]}), ${rgbArray[2]}`))
  }

  const selectSwatch = index => {
    index !== activeSwatch ? setActiveSwatch(index) : setActiveSwatch()
  }

  useEffect(() => {
    const primaryColor = themeHEX[0].replace(/\#/g, '')
    const secondaryColor = themeHEX[1].replace(/\#/g, '')
    const tertiaryColor = themeHEX[2].replace(/\#/g, '')

    switch (activeSwatch) {
      case 0:
        setColor(new window.w3color(`${primaryColor}`))
        break
      case 1:
        setColor(new window.w3color(new window.w3color(`${secondaryColor}`)))
        break
      case 2:
        setColor(new window.w3color(new window.w3color(`${tertiaryColor}`)))
        break
    }
  }, [activeSwatch])

  return (
    <Editor>
      <SwatchContainer>
        <ColorSwatch
          active={activeSwatch === 0 ? true : false}
          color={themeHEX[0]}
          whileTap={{ scale: 0.9 }}
          onClick={() => selectSwatch(0)}
        />
        <ColorSwatch
          active={activeSwatch === 1 ? true : false}
          color={themeHEX[1]}
          whileTap={{ scale: 0.9 }}
          onClick={() => selectSwatch(1)}
        />
        <ColorSwatch
          active={activeSwatch === 2 ? true : false}
          color={themeHEX[2]}
          whileTap={{ scale: 0.9 }}
          onClick={() => selectSwatch(2)}
        />
      </SwatchContainer>
      <AnimatePresence>
        {activeSwatch !== undefined && (
          <ColorPickerContainer
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
          >
            <ColorCodePicker
              hsl={color.toHslString()}
              rgb={color.toRgbString()}
              hex={color.toHexString()}
              emitHSL={onHSLCodeChange}
              emitRGB={onRGBCodeChange}
              emitHEX={onHEXCodeChange}
            />
            <ColorPicker hue={color.hue} saturation={color.sat * 100} lightness={color.lightness * 100} onChange={onChange} />
          </ColorPickerContainer>
        )}
      </AnimatePresence>
    </Editor>
  )
}

export default ThemeEditor
