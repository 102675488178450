import { AnimatePresence, motion, useSpring } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

const DialogContainer = styled(motion.div)`
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 1000;
  /* overflow: hidden; */

  h3 {
    color: ${props => props.theme.neutral.text};
  }
`

const Dialog = styled.div`
  position: relative;
`

const DialogWrapper = styled(motion.div)`
  width: ${props => props.childrenNumber * 100 + '%'};
  display: flex;
  flex-direction: row;
  height: auto;
`

const Step = styled(motion.div)`
  position: relative;
  margin-left: ${props => props.theme.space.sm};
  width: calc(100vw - 40px);
  height: auto;
  border-radius: 8px;
  padding-bottom: 50px;
  background: ${props => props.theme.neutral.bright};
  box-shadow: ${props => props.theme.shadow.base};
`

const Content = styled.div`
  height: 100%;
  padding: ${props => props.theme.space.m};
  display: flex;
  flex-direction: column;
`

const Navigator = styled.div`
  position: absolute;
  border-top: 1px solid ${props => props.theme.neutral.light};
  padding: ${props => props.theme.space.s} ${props => props.theme.space.m};
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 50px;
  color: ${props => props.theme.tertiary.base};
  font-weight: ${props => props.theme.fontWeight.medium};
`

const Backwards = styled(motion.div)`
  position: relative;
  display: inline-block;
`

const Forwards = styled(motion.div)`
  position: relative;
  text-align: right;
  float: right;
  display: block;
`

function MultiStepDialog(props) {
  const [step, setStep] = useState(0)

  const dialogRef = useRef(null)
  const wrapperRef = useRef(null)

  const x = useSpring(0, { stiffness: 200, damping: 20, mass: 1 })

  useEffect(() => {
    const newPosition = (dialogRef.current.clientWidth - 20) * step * -1
    x.set(newPosition)
    props.transferIndex(step)
  }, [step])

  const handleKeypress = e => {
    if (e.key === 'Enter') {
      moveForwardsOnKeypress()
    }
  }

  function moveForwardsOnKeypress(e) {
    console.log('moveForwardsOnKeypress')
    console.log(e.keyCode)
    if (e.keyCode == 13) {
      moveForwards()
    }
  }

  // useEffect(() => {
  //   dialogRef.current.addEventListener('keydown', handleKeypress, true)
  // }, [])

  // useEffect(() => {
  //   console.log('STEP')
  //   dialogRef.current.addEventListener('keydown', handleKeypress, true)
  // }, [step])

  const moveBackwards = () => {
    setStep(step - 1)
  }

  const moveForwards = () => {
    console.log(props)
    console.log(props.valide)
    setStep(step + 1)
    // props.valide && setStep(step + 1)
  }

  useEffect(() => {
    console.log(step)
    console.log(props.valide)
  })

  useEffect(() => {
    document.body.style.position = 'fixed'
    document.body.style.width = '100%'
    document.body.style.height = '100%'
  }, [])

  useEffect(() => {
    props.triggerStepForwards && moveForwards()
    props.triggerStepForwards && props.cancelTrigger()
  }, [props.triggerStepForwards])

  return (
    <DialogContainer initial={{ opacity: 0, y: -100 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -100 }}>
      <Dialog ref={dialogRef}>
        <DialogWrapper ref={wrapperRef} childrenNumber={React.Children.count(props.children)} style={{ x, originX: 0 }}>
          {React.Children.map(props.children, (child, index) => {
            return (
              <Step key={index}>
                <Content>{child}</Content>
                <Navigator>
                  <AnimatePresence>
                    {props.valide &&
                      (step === React.Children.count(props.children) - 1 ? (
                        <Forwards onClick={() => props.finalFn()} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                          {props.finalLabel}
                        </Forwards>
                      ) : (
                        <Forwards onClick={moveForwards} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                          Weiter
                        </Forwards>
                      ))}
                  </AnimatePresence>
                  <AnimatePresence>
                    {step > 0 && (
                      <Backwards onClick={moveBackwards} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        Zurück
                      </Backwards>
                    )}
                  </AnimatePresence>
                </Navigator>
              </Step>
            )
          })}
        </DialogWrapper>
      </Dialog>
    </DialogContainer>
  )
}

export default MultiStepDialog
