import React, { useEffect, useRef } from 'react'
import { useCategories } from '.'
import { db } from '../config'

export function useQuestionpool(groupID) {
  const [error, setError] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [activeGroup, setActiveGroup] = React.useState()
  const [databaseQuestionPool, setDatabaseQuestionPool] = React.useState()

  const isMounted = useRef(false)

  useEffect(() => {
    groupID && setActiveGroup(groupID)
  }, [groupID])

  const { categories } = useCategories(activeGroup)

  useEffect(() => {
    isMounted.current = true
    let newPool = {}

    if (categories) {
      categories.map(category => {
        newPool = {
          [category]: [],
          ...newPool,
        }
      })
    }

    if (groupID) {
      db.collection('groups')
        .doc(groupID)
        .collection('questionPool')
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            const questionId = doc.id
            const questionData = doc.data()
            let question = {}

            question = { id: questionId, ...questionData, views: 0 }
                newPool[questionData.category].push(question)

            // db.collection('groups')
            //   .doc(groupID)
            //   .collection('questionPool')
            //   .doc(doc.id)
            //   .collection('views')
            //   .get()
            //   .then(function(querySnapshot) {
            //     let views = []

            //     querySnapshot.forEach(function(doc) {
            //       views.push(doc.id)
            //     })
                
            //   })
          })
          isMounted.current && setDatabaseQuestionPool(newPool)
          console.log('set QuestionPool')
        })
        .catch(function(error) {
          console.log('Error getting documents: ', error)
        })
    }

    return () => (isMounted.current = false)
  }, [categories])

  return {
    error,
    loading,
    categories,
    databaseQuestionPool,
  }
}
