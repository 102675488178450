import React from 'react'
import styled from 'styled-components'
import signet from '../../../assets/cuiz-logo.svg'

const LogoContainer = styled.div`
  display: inline-block;
  width: auto;
  height: ${props => (props.size === 'small' && '24px') || (props.size === 'large' && '80px')};

  img {
    display: inline-block;
    height: 100%;
    width: auto;
  }
`

function Logo(props) {
  return (
    <LogoContainer size={props.size}>
      <img src={signet} alt={props.alt} />
    </LogoContainer>
  )
}

export default Logo
