import React, { useEffect, useState } from 'react'
import { useGroup, useProfile } from '../database/services'
import { defaultTheme } from '../ui/global'

export function useTheme(uid) {
  const [group, setGroup] = useState()

  const { profile } = useProfile(uid)
  const { groupData } = useGroup(group)

  const [theme, setTheme] = useState(defaultTheme)
  const [themeColors, setThemeColors] = useState()
  const [colorObjects, setColorObjects] = useState()
  const [customTheme, setCustomTheme] = React.useState()
  const [themeReady, setThemeReady] = React.useState(false)

  useEffect(() => {
    if (customTheme) {
      setTheme(customTheme)
      setThemeReady(true)
    }
  }, [customTheme])

  useEffect(() => {
    profile && setGroup(profile.activeGroup)
  }, [profile])

  useEffect(() => {
    if (groupData) {
      if (groupData.customThemeHEX) {
        setThemeColors(groupData.customThemeHEX)
      } else {
        setTheme(defaultTheme)
        setThemeReady(true)
      }
    } else {
      setThemeReady(true)
      // Das ist die Zeile, die Ärger macht!
    }
  }, [groupData])

  useEffect(() => {
    if (themeColors) {
      const primary = new window.w3color(`${themeColors[0]}`)
      const secondary = new window.w3color(`${themeColors[1]}`)
      const tertiary = new window.w3color(`${themeColors[2]}`)

      const primaryObj = {
        background: new window.w3color(`hsl(${primary.hue}, ${primary.sat}, ${0.92}`).toHexString(),
        light: new window.w3color(`hsl(${primary.hue}, ${primary.sat}, ${primary.lightness + 0.05}`).toHexString(),
        dark: new window.w3color(`hsl(${primary.hue}, ${primary.sat}, ${primary.lightness - 0.08}`).toHexString(),
        text: new window.w3color(`hsl(${primary.hue}, ${primary.sat}, ${primary.lightness - 0.125}`).toHexString(),
      }

      const secondaryObj = {
        background: new window.w3color(`hsl(${secondary.hue}, ${secondary.sat}, ${0.92}`).toHexString(),
        light: new window.w3color(`hsl(${secondary.hue}, ${secondary.sat}, ${secondary.lightness + 0.05}`).toHexString(),
        dark: new window.w3color(`hsl(${secondary.hue}, ${secondary.sat}, ${secondary.lightness - 0.08}`).toHexString(),
        text: new window.w3color(`hsl(${secondary.hue}, ${secondary.sat}, ${secondary.lightness - 0.125}`).toHexString(),
      }

      const tertiaryObj = {
        background: new window.w3color(`hsl(${tertiary.hue}, ${tertiary.sat}), ${0.92}`).toHexString(),
        light: new window.w3color(`hsl(${tertiary.hue}, ${tertiary.sat}), ${tertiary.lightness + 0.05}`).toHexString(),
        dark: new window.w3color(`hsl(${tertiary.hue}, ${tertiary.sat}), ${tertiary.lightness - 0.08}`).toHexString(),
        text: new window.w3color(`hsl(${tertiary.hue}, ${tertiary.sat}), ${tertiary.lightness - 0.125}`).toHexString(),
      }

      setColorObjects([primaryObj, secondaryObj, tertiaryObj])
    }
  }, [themeColors])

  useEffect(() => {
    colorObjects && createTheme(themeColors, colorObjects)
  }, [colorObjects])

  const createTheme = (themeColors, colorObjects) => {
    const newCustomTheme = {
      ...defaultTheme,
      primary: {
        base: themeColors[0],
        background: colorObjects[0].background,
        light: colorObjects[0].light,
        dark: colorObjects[0].dark,
        text: colorObjects[0].text,
      },
      secondary: {
        base: themeColors[1],
        background: colorObjects[1].background,
        light: colorObjects[1].light,
        dark: colorObjects[1].dark,
        text: colorObjects[1].text,
      },
      tertiary: {
        base: themeColors[2],
        background: colorObjects[2].background,
        light: colorObjects[2].light,
        dark: colorObjects[2].dark,
        text: colorObjects[2].text,
      },
    }
    setCustomTheme(newCustomTheme)
    localStorage.setItem('customTheme', JSON.stringify(newCustomTheme))
  }

  return {
    theme,
    themeReady,
  }
}
