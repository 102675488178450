import { AnimatePresence, motion } from 'framer-motion'
import React, { useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { Context } from '../../context/ContextProvider'
import { ADD_USER } from '../../database/actions/userActions'
import { FloatingButton } from '../components/buttons'
import { Input } from '../components/inputs'
import { ContentBox } from '../components/layout'

const Container = styled(motion.div)`
  /* overflow-x: hidden; */
`

function Registration(props) {
  const context = useContext(Context)

  const [username, setUsername] = useState('')
  const [slogan, setSlogan] = useState('')
  const [step, setStep] = useState(1)
  const [redirect, setRedirect] = useState(false)

  function registerProfil() {
    ADD_USER(props.user.uid, username, slogan, props.user.photoURL, databaseCallback)
    setRedirect(true)
  }

  const databaseCallback = () => {
    console.log('databaseCallback')
  }

  function moveForwards(step) {
    let newStep = step + 1
    setStep(newStep)
  }

  function moveBackwards(step) {
    let newStep = step - 1
    setStep(newStep)
  }

  function changeUserName(value) {
    setUsername(value)
  }

  function changeSlogan(value) {
    setSlogan(value)
  }

  useEffect(() => {
    !context.headerVisible && context.updateHeaderVisibility(false)
  }, [context])

  return (
    <Container initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <h2>
        Du bist neu hier? <span role="img">🧐</span>
      </h2>
      <ContentBox>
        <AnimatePresence exitBeforeEnter initial={false}>
          {step === 1 && (
            <motion.div
              key="step-1"
              initial={{ x: 0, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: 0, opacity: 0 }}
              transition={{ type: 'tween', duration: 0.2 }}
            >
              <h3>Wie sollen wir dich nennen?</h3>
              <Input placeholder="Dein Spielername" value={username} emitValue={changeUserName} maxLength="15" />
            </motion.div>
          )}
          {step === 2 && (
            <motion.div
              key="step-2"
              initial={{ x: 0, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: 0, opacity: 0 }}
              transition={{ type: 'tween', duration: 0.2 }}
            >
              <h3>Deine Kampfansage lautet?</h3>
              <Input placeholder="Deine Kampfansage" value={slogan} emitValue={changeSlogan} maxLength="30" />
            </motion.div>
          )}
          {/* {step === 3 && (
            <motion.div
              key="step-3"
              initial={{ x: 0, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: 0, opacity: 0 }}
              transition={{ type: 'tween', duration: 0.2 }}
            >
              <h3>Finde deine Quiz-Freunde ...</h3>
              <Search placeholder="Quiz-Gruppen durchsuchen" value={group} emitValue={selectGroup} maxLength="30" suggestions={groupList} />
            </motion.div>
          )} */}
        </AnimatePresence>
      </ContentBox>

      {username && (
        <>
          {(step === 1 && username && (
            <FloatingButton key="step-1-btn" onClick={() => moveForwards(step)}>
              Weiter geht's, {username}
            </FloatingButton>
          )) ||
            // (step === 2 && (
            //   <FloatingButton key="step-2-btn" backwards={() => moveBackwards(step)} onClick={() => moveForwards(step)}>
            //     Und weiter ...
            //   </FloatingButton>
            // )) ||
            (step === 2 && (
              <FloatingButton key="registerBtn" onClick={registerProfil} backwards={() => moveBackwards(step)}>
                Profil erstellen
              </FloatingButton>
            ))}
        </>
      )}

      {redirect && <Redirect to="/" />}
    </Container>
  )
}

export default Registration
