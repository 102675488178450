import React from 'react'
import styled from 'styled-components'
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-small.svg'

const Button = styled.button`
  border: none;
  outline: 0px;
  border: 0px;
  border-radius: 100%;
  background: ${props => props.theme.neutral.bright};
  box-shadow: ${props => props.theme.shadow.base};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;

  svg {
    fill: ${props => props.theme.secondary.base};
    width: 12px;
    height: 12px;
  }
`

function EditButton(props) {
  return (
    <Button>
      <EditIcon />
    </Button>
  )
}

export default EditButton