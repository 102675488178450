import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

const config = {
  apiKey: 'AIzaSyDege8KdFWJEsZwomUZonnly-QpQjVuhpw',
  authDomain: 'friendsquiz-2020.firebaseapp.com',
  databaseURL: 'https://friendsquiz-2020.firebaseio.com',
  projectId: 'friendsquiz-2020',
  storageBucket: 'friendsquiz-2020.appspot.com',
  messagingSenderId: '409529580738',
  appId: '1:409529580738:web:96f6d22521c871f89a0f5b',
}

firebase.initializeApp(config)

export const db = firebase.firestore()
export const storage = firebase.storage()
// export const messaging = firebase.messaging();

firebase.firestore().enablePersistence({ synchronizeTabs: true })
