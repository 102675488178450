import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import styled from 'styled-components'

const InnerContainer = styled(motion.div)`
  position: relative;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  padding: ${props => props.theme.space.m};
  display: flex;
  justify-content: space-around;
  z-index: 1000;
`

const Option = styled(motion.div)`
  width: auto;
  font-size: 32px;
`

function ReactionPicker(props) {
  const [reaction, setReaction] = useState(false)

  const selectReaction = emoji => {
    setReaction(emoji)
    props.selectReaction(emoji)
  }

  const containerVariants = {
    initial: {},
    visible: {
      transition: {
        staggerChildren: 0.075,
      },
    },
    exit: {
      transition: {
        staggerChildren: 0.03,
      },
    },
  }

  const optionVariants = {
    initial: {
      opacity: 0,
      y: 75,
      transition: {
        type: 'spring',
        stiffness: 80,
        mass: 0.4,
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        stiffness: 80,
        mass: 0.4,
      },
    },
    exit: {
      opacity: 0,
      y: 0,
    },
  }

  return (
    <AnimatePresence>
      {props.show && (
        <InnerContainer variants={containerVariants} initial="initial" animate="visible" exit="exit">
          <Option key="option-1" variants={optionVariants} whileTap={{ scale: 0.9 }} onClick={() => selectReaction('128514')}>
            &#128514;
          </Option>
          <Option key="option-2" variants={optionVariants} whileTap={{ scale: 0.9 }} onClick={() => selectReaction('129327')}>
            &#129327;
          </Option>
          <Option key="option-3" variants={optionVariants} whileTap={{ scale: 0.9 }} onClick={() => selectReaction('128525')}>
            &#128525;
          </Option>
          <Option key="option-4" variants={optionVariants} whileTap={{ scale: 0.9 }} onClick={() => selectReaction('129488')}>
            &#129488;
          </Option>
          <Option key="option-5" variants={optionVariants} whileTap={{ scale: 0.9 }} onClick={() => selectReaction('128553')}>
            &#128553;
          </Option>
        </InnerContainer>
      )}
    </AnimatePresence>
  )
}

export default ReactionPicker
