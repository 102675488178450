import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const Panel = styled(motion.div)`
  width: 100%;
  position: relative;
  height: auto;
  margin: 0px 0px 12px 0px;
  /* box-shadow: 0px 2px 0px 0px rgba(0,0,0,0.16); */

  a {
    text-decoration: none;
  }
`

const Wrapper = styled.div`
  padding: 20px;
  color: #2D44D4;
  background: #fff;
  border: 2px solid #ffc60b;
  border-radius: 12px;
  transform: translateY(0px);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
  
  transition: box-shadow 0.3s, transform 0.3s;

  &:hover {
    transform: translateY(-1px);
  }

  a {
    color: inherit;
  }
`

function QuizTeaser(props) {
  const [opponent, setOpponent] = useState()
  const [statusText, setStatusText] = useState('')

  useEffect(() => {
    if (props.user.uid === props.opponent) {
      setOpponent(props.challengerName)
      props.turnOwner === 'opponent' ? setStatusText('Du bist dran') : setStatusText('Dein Gegner ist dran')
    } else {
      setOpponent(props.challengerName)
      props.turnOwner === 'challenger' ? setStatusText('Du bist dran') : setStatusText('Dein Gegner ist dran')
    }
  }, [props])

  return (
    <Panel positionTransition initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} >
      <Wrapper>
        <NavLink
          to={{
            pathname: '/quiz',
            quizId: props.quizId,
          }}
        >
          {opponent} {props.turnOwner && <span>({statusText})</span>}
        </NavLink>
      </Wrapper>
    </Panel>
  )
}

export default QuizTeaser
