import { motion } from 'framer-motion'
import React, { useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { Context } from '../../context/ContextProvider'
import { CREATE_GROUP } from '../../database/actions/groupActions'
import { useNotification } from '../../hooks'
import { Spinner } from '../components/atoms'
import { CloseButton } from '../components/buttons'
import { MultiStepDialog } from '../components/dialog'
import { ThemeEditor } from '../components/groups'
import { ImageUploader, Textarea } from '../components/inputs'
import { Layer } from '../components/layout'

const Header = styled.div`
  position: relative;
  padding: ${props => props.theme.space.sm};
  color: ${props => props.theme.neutral.bright};
  font-weight: ${props => props.theme.fontWeight.medium};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LoadingContainer = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

function GroupProducer({ user, ...props }) {
  const context = useContext(Context)

  const [dialogIndex, setDialogIndex] = useState()
  const [triggerStepForwards, setTriggerStepForwards] = useState(false)

  const [groupID, setGroupID] = useState(
    new Date().valueOf() +
      '-' +
      Math.random()
        .toString(36)
        .substr(2, 9)
  )
  const [groupName, setGroupName] = useState('')
  const [thumbnailName, setThumbnailName] = useState('')

  const [themeHEX, setThemeHEX] = useState(['#37409a', '#f4d10f', '#e25e76'])
  const [themeHSL, setThemeHSL] = useState({ primary: [235, 47, 41], secondary: [51, 91, 51], tertiary: [235, 47, 41] })

  const [selectedImage, setSelectedImage] = useState('')
  const [imageUpload, setImageUpload] = useState(false)
  const [storageURL, setStorageURL] = useState('')

  const [valide, setValide] = useState(false)
  const [loading, setLoading] = useState(false)
  const [redirect, setRedirect] = useState(false)

  const { showNotification } = useNotification()

  const getDialogIndex = index => {
    setDialogIndex(index)
  }

  function emitSelectedImage(url) {
    setSelectedImage(url)
  }

  const emitImageName = name => {
    setThumbnailName(name)
  }

  function triggerUpload() {
    setImageUpload(true)
  }

  const emitStorageURL = url => {
    setStorageURL(url)
  }

  useEffect(() => {
    validator(dialogIndex)
  }, [dialogIndex, groupName, selectedImage])

  const validator = index => {
    switch (index) {
      case 0:
        groupName.length > 0 ? setValide(true) : setValide(false)
        break
      case 1:
        setValide(true)
        break
      case 2:
        selectedImage ? setValide(true) : setValide(false)
        break
    }
  }

  useEffect(() => {
    storageURL && createGroup()
  }, [storageURL])

  function moveForwardsOnKeypress(e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      valide && setTriggerStepForwards(true)
    }
  }

  function cancelTrigger() {
    setTriggerStepForwards(false)
  }

  const updateTheme = (hex, hsl) => {
    hex && setThemeHEX(hex)
    hsl && setThemeHSL(hsl)
  }

  function completeDialog() {
    selectedImage ? triggerUpload() : createGroup()
  }

  const createGroup = () => {
    console.log(themeHSL)
    console.log(themeHEX)
    setLoading(true)
    context.showPreloader()
    CREATE_GROUP(user.uid, groupID, groupName, themeHSL, themeHEX, thumbnailName, successCallback, showNotification)
  }

  const successCallback = () => {
    console.log('successful')
    props.close()
    setRedirect(true)
  }

  return (
    <Layer>
      {!loading ? (
        <motion.div initial={{ opacity: 1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <Header>
            Neue Gruppe erstellen
            <CloseButton closeFunction={props.close} />
          </Header>
          <MultiStepDialog
            transferIndex={getDialogIndex}
            customAction={[2, triggerUpload]}
            validator={validator}
            valide={valide}
            finalFn={completeDialog}
            finalLabel="Gruppe erstellen"
            triggerStepForwards={triggerStepForwards}
            cancelTrigger={cancelTrigger}
          >
            <>
              <h3>Gruppennamen festlegen</h3>
              <Textarea
                nature="hero"
                autoFocus={dialogIndex === 0 ? true : false}
                placeholder="Wie soll die Gruppe heißen?"
                value={groupName}
                emitValue={setGroupName}
                maxLength="140"
                onKeyPress={moveForwardsOnKeypress}
              />
            </>
            <>
              <h3>Theme anpassen</h3>
              <ThemeEditor themeHEX={themeHEX} themeHSL={themeHSL} emitTheme={updateTheme} />
            </>
            <>
              <h3>Gruppenbild hinzufügen</h3>
              <ImageUploader
                group={groupID}
                triggerUpload={imageUpload}
                emitImageName={emitImageName}
                emitStorageURL={emitStorageURL}
                emitSelectedImage={emitSelectedImage}
              />
            </>
          </MultiStepDialog>
        </motion.div>
      ) : (
        <LoadingContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <Spinner />
        </LoadingContainer>
      )}

      {redirect && <Redirect to="/" />}
    </Layer>
  )
}

export default GroupProducer
