import React, { useEffect } from 'react'
import { db } from '../config'

export function useGroupMembers(groupId, user) {
  const [error, setError] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [groupMembers, setGroupMembers] = React.useState([])

  useEffect(() => {
    let unsubscribe = () => {}

    groupId &&
      user &&
      (unsubscribe = db
        .collectionGroup('memberships')
        .where('groupID', '==', groupId)
        .onSnapshot(function(querySnapshot) {
          let userList = []
          querySnapshot.forEach(function(doc) {
            const data = doc.data()
            const user = {
              userId: doc.ref.parent.parent.id,
            }
            userList.push(user)
          })

          setLoading(false)
          setGroupMembers(userList)
        }))

    return () => unsubscribe()
  }, [groupId, user])

  return {
    error,
    loading,
    groupMembers,
  }
}
