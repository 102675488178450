import React, { useState } from 'react'

export const Modal = React.createContext()
export const Provider = Modal.Provider
export const Consumer = Modal.Consumer

export function ModalProvider(props) {
  const [visible, setVisible] = useState(false)
  const [type, setType] = useState('default')
  const [title, setTitle] = useState()
  const [bodyText, setBodyText] = useState()
  const [ctaLabel, setCtaLabel] = useState()
  const [action, setAction] = useState()
  const [cancelOption, setCancelOption] = useState(false)
  const [confirmValue, setConfirmValue] = useState()

  return (
    <Provider
      value={{
        visible,
        type,
        title,
        bodyText,
        ctaLabel,
        action,
        cancelOption,
        confirmValue,
        show: (type, title, bodyText, ctaLabel, action, cancelOption, confirmValue) => {
          setVisible(true)
          setType(type)
          setTitle(title)
          setBodyText(bodyText)
          setCtaLabel(ctaLabel)
          setAction(action)
          setCancelOption(cancelOption)
          setConfirmValue(confirmValue)
        },
        hide: () => {
          setVisible(false)
          setTimeout(() => {
            setType('default')
            setTitle()
            setBodyText()
            setCtaLabel()
            setAction()
            setCancelOption(false)
            setConfirmValue()
          }, 300)
        },
      }}
    >
      {props.children}
    </Provider>
  )
}

export default ModalProvider
