import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

const Box = styled(motion.div)`
  position: relative;
  margin-bottom: ${props => props.theme.space.s};

  &:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: ${props => props.theme.radius.m};
    background: ${props => props.theme.primary.light};
    z-index: -1000;
    transition: filter 0.4s;
  }

  h1,
  h2,
  h3 {
    transition: filter 0.4s;
  }
`

const Content = styled.div`
  padding: ${props => (props.nature === 'no-padding' ? '0px' : props.theme.space.s)};
`

const Action = styled.button`
  border: none;
  /* border-top: 1px solid ${props => props.theme.primary.base}; */
  margin: ${props => props.theme.space.xs} ${props => props.theme.space.s} ${props => props.theme.space.s} ${props => props.theme.space.s};
  padding: ${props => props.theme.space.s};
  width: calc(100% - 32px);
  background: ${props => props.theme.primary.dark};
  outline: 0px;
  color: ${props => props.theme.neutral.bright};
  font-weight: ${props => props.theme.fontWeight.medium};
  font-size: ${props => props.theme.fontSize.s};
  text-transform: uppercase;
  border-radius: ${props => props.theme.radius.m};
  box-shadow: ${props => props.theme.shadow.light};
`

function ContextBox(props) {

  return (
    <Box>
      <Content nature={props.nature}>{props.children}</Content>
      {props.actionFn && props.actionLabel && <Action onClick={props.actionFn}>{props.actionLabel}</Action>}
    </Box>
  )
}

export default ContextBox
