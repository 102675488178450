import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg'

const ButtonContainer = styled(motion.div)`
  position: fixed;
  bottom: 80px;
  right: ${props => props.theme.space.sm};
  width: 60px;
  height: 60px;
  height: auto;
  z-index: 6000;
  display: flex;
  justify-content: center;
`

const Button = styled.div`
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: ${props => props.theme.tertiary.base};
  border-radius: 32px;
  color: ${props => props.theme.neutral.bright};
  box-shadow: ${props => props.theme.shadow.base};
  font-size: ${props => props.theme.fontSize.root};
  outline: none;
  border: none;

  svg {
    fill: ${props => props.theme.neutral.bright};
    width: 20px;
    height: 20px;
  }
`

function ActionButton(props) {
  const [redirect, setRedirect] = useState(false)

  function triggerRedirect() {
    setRedirect(true)
  }

  return (
    <>
      {redirect && <Redirect to={props.to} />}
      <ButtonContainer
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 100, opacity: 0 }}
        positionTransition
      >
        <Button onClick={props.onClick}><PlusIcon /></Button>
      </ButtonContainer>
    </>
  )
}

export default ActionButton
