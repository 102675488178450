import React from 'react'
import styled from 'styled-components'

const ColumnContainer = styled.div`
  width: ${props => (100 / 12 * props.dimension) + '%'};
`

function Column(props) {
  return <ColumnContainer dimension={props.dimension}>{props.children}</ColumnContainer>
}

export default Column
