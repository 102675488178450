import { motion } from 'framer-motion'
import React, { useContext, useEffect } from 'react'
import { Context } from '../../context/ContextProvider'
import { useQuizArchive } from '../../database/services'
import { FloatingButton } from '../components/buttons'
import { ContentBox } from '../components/layout'
import { Teaser } from '../components/teaser'
import { viewVariants } from '../global'

function Archive({ user, ...props }) {
  const context = useContext(Context)

  const { challengerQuizArchive, opponentQuizArchive } = useQuizArchive(user.uid)

  useEffect(() => {
    !context.headerVisible && context.updateHeaderVisibility(true)
    !context.headerArrow && context.updateHeaderArrow(true)
  }, [context])

  return (
    <motion.div initial="initial" animate="animate" exit="exit" variants={viewVariants}>
      <ContentBox>
        <h3>Beendete Spiele</h3>
        {challengerQuizArchive &&
          challengerQuizArchive.map(quiz => {
            return (
              <Teaser
                user={user}
                key={quiz.id.toString()}
                id={quiz.id}
                challenger={quiz.challenger}
                challengerName={quiz.challengerName}
                opponent={quiz.opponent}
                opponentName={quiz.opponentName}
                userProfile={context.userProfile}
              />
            )
          })}

        {opponentQuizArchive &&
          opponentQuizArchive.map(quiz => {
            return (
              <Teaser
                user={user}
                key={quiz.id.toString()}
                id={quiz.id}
                challenger={quiz.challenger}
                challengerName={quiz.challengerName}
                opponent={quiz.opponent}
                opponentName={quiz.opponentName}
                userProfile={context.userProfile}
              />
            )
          })}
      </ContentBox>

      <FloatingButton type="link" to="/">
        Zurück
      </FloatingButton>
    </motion.div>
  )
}

export default Archive
