import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from '.'
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow-left.svg'

const ButtonContainer = styled(motion.div)`
  position: ${props => props.position ? props.position : 'fixed'};
  bottom: ${props => props.position ? '0px' : props => props.theme.space.l};
  left: 0px;
  width: 100%;
  height: 54px;
  z-index: 6000;
  display: flex;
  justify-content: center;
  transition: filter 0.4s;
`

const BackwardsButton = styled.div`
  appearance: none;
  display: inline-block;
  width: 54px;
  height: 54px;
  border-radius: 100%;
  background-color: ${props => props.theme.neutral.bright};
  border-radius: 29px;
  box-shadow: ${props => props.theme.shadow.base};
  outline: none;
  border: none;
  margin-right: ${props => props.theme.space.s};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${props => props.theme.tertiary.base};
    width: 14px;
    height: 14px;
  }
`

function FloatingButton(props) {
  const [redirect, setRedirect] = useState(false)

  function triggerRedirect() {
    setRedirect(true)
  }

  return (
    <>
      {redirect && <Redirect to={props.to} />}
      <ButtonContainer
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 100, opacity: 0 }}
        positionTransition
        position={props.position}
      >
        {props.backwards && (
          <BackwardsButton onClick={props.backwards}>
            <ArrowIcon />
          </BackwardsButton>
        )}
        {props.type === 'link' ? (
          <Button onClick={triggerRedirect}>{props.children}</Button>
        ) : (
          <Button onClick={props.onClick}>{props.children}</Button>
        )}
      </ButtonContainer>
    </>
  )
}

export default FloatingButton
