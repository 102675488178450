import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as IconDelete } from '../../../assets/icons/delete.svg'
import { DELETE_QUESTION } from '../../../database/actions/poolActions'
import { storage } from '../../../database/config'
import { useReactions } from '../../../database/services'
import { useModal, useNotification } from '../../../hooks'
import { Emoji } from '../atoms'

const Card = styled(motion.div)`
  position: relative;
  color: ${props => props.theme.primary.base};
  background: ${props => props.theme.neutral.bright};
  border-radius: ${props => props.theme.radius.m};
  box-shadow: ${props => props.theme.shadow.light};
  margin-bottom: ${props => props.theme.space.s};
  height: 100%;

  &:last-child {
    margin-bottom: 0px;
  }
`

const Meta = styled.div`
  position: relative;
  display: inline-block;
  font-size: ${props => props.theme.fontSize.s};
  margin-bottom: ${props => props.theme.space.s};
  color: ${props => props.theme.secondary.base};
  padding: ${props => props.theme.space.s} 0px ${props => props.theme.space.xxs} 0px;

  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 3px;
    background: ${props => props.theme.secondary.base};
    border-radius: 0px 0px 3px 3px;
  }
`

const Question = styled.div`
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: ${props => props.theme.fontSize.l};
  line-height: ${props => props.theme.lineHeight.l};
  min-height: 104px;
  display: flex;
  align-items: flex-start;
`

const ImageContainer = styled.div`
  width: 24%;
  height: auto;
  overflow: hidden;
  border-radius: 4px;
  margin: ${props => props.theme.space.xxs} ${props => props.theme.space.s} 0px 0px;

  img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0px;
    padding: 0px;
  }
`

const TextContainer = styled.div`
  width: ${props => (props.type === 'image' ? '72%' : '100%')};
`

const Answers = styled(motion.ul)`
  position: relative;
  width: 100%;
  padding: 0px;
  margin: ${props => props.theme.space.s} 0px 0px 0px;
  list-style-type: none;
  transition: opacity 0.6s;
`

const Answer = styled.li`
  color: ${props =>
    props.nature ? (props.nature === 'correct' ? props.theme.positive.base : props.theme.negative.base) : props.theme.neutral.text};
  background: ${props =>
    props.nature
      ? props.nature === 'correct'
        ? props.theme.positive.background
        : props.theme.negative.background
      : props.theme.neutral.light};
  border: 2px solid
    ${props => (props.nature ? (props.nature === 'correct' ? props.theme.positive.base : props.theme.negative.base) : 'transparent')};
  border-radius: 8px;
  padding: 12px ${props => props.theme.space.s};
  margin-bottom: ${props => props.theme.space.xs};

  &:last-child {
    margin-bottom: 0px;
  }
`

const QuestionContainer = styled.div`
  padding: 0px ${props => props.theme.space.m} ${props => props.theme.space.m} ${props => props.theme.space.m};
  transition: opacity 0.6s;
`

const ActionContainer = styled(motion.footer)`
  position: absolute;
  width: 72%;
  display: flex;
  justify-content: center;
  bottom: -40px;
  left: 14%;
`

const Action = styled.button`
  padding: 0px;
  margin: 0px;
  border: none;
  width: 50%;
  color: ${props => props.theme.neutral.bright};
  background: transparent;
  font-size: ${props => props.theme.fontSize.m};
  font-weight: ${props => props.theme.fontWeight.medium};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: 0px;

  svg {
    fill: ${props => props.theme.neutral.bright};
    width: 14px;
    height: 14px;
    margin-right: ${props => props.theme.space.xs};
  }
`

const ReactionContainer = styled(motion.div)`
  margin-top: ${props => props.theme.space.m};
`

const Reaction = styled.span`
  display: inline-block;
  padding: ${props => props.theme.space.xs} ${props => props.theme.space.s};
  background: ${props => props.theme.secondary.background};
  border-radius: ${props => props.theme.space.m};
  font-size: ${props => props.theme.fontSize.s};
  font-weight: ${props => props.theme.fontWeight.medium};
  margin-right: ${props => props.theme.space.xs};
`

const ReactionCounter = styled.span`
  color: ${props => props.theme.secondary.base};
  margin-right: ${props => props.theme.space.xxs};
`

function QuestionCard(props) {
  const { questionReactions } = useReactions(props.group, props.questionID)
  const { showNotification } = useNotification()
  const { showModal } = useModal()

  const [imageURL, setImageURL] = useState()
  const [deleted, setDeleted] = useState(false)
  const [result, setResult] = useState()

  useEffect(() => {
    props.question.type === 'image' && downloadQuestionImage()
  }, [])

  const confirmDeletion = () => {
    setDeleted(true)
    setTimeout(() => {
      props.emitDeletion(props.questionID)
      DELETE_QUESTION(props.group, props.questionID, showNotification)
    }, 600)
  }

  const deleteQuestion = () => {
    showModal(
      'default',
      'Bist du dir sicher?',
      'Möchtest du wirklich die Frage löschen?',
      'Frage löschen',
      { fn: confirmDeletion },
      true,
      null
    )
  }

  const downloadQuestionImage = () => {
    storage
      .refFromURL('gs://friendsquiz-2020.appspot.com/groups/' + props.group + '/question-images/' + props.question.imageName + '_1024x1024')
      .getDownloadURL()
      .then(function(url) {
        setImageURL(url)
      })
      .catch(function(error) {
        console.log(error)
        switch (error.code) {
          case 'storage/object-not-found':
            break
          case 'storage/unauthorized':
            break
          case 'storage/canceled':
            break
          case 'storage/unknown':
            break
          default:
            break
        }
      })
  }

  useEffect(() => {
    if (props.ownResult) {
      props.ownResult.answer && props.owner === 'ego' && setResult(props.ownResult)
      props.ownResult.answer && props.owner === 'mate' && setResult(props.mateResult)
    }

    return () => setResult()
  }, [props.owner, props.ownResult, props.mateResult])

  return (
    <Card animate={{ y: deleted ? '85vh' : 0 }} transition={{ type: 'spring', mass: 1, stiffness: 100, damping: 12 }} unselectable="on">
      <QuestionContainer>
        <Meta>
          {new Date(props.question.timestamp.seconds * 1000).toLocaleDateString('en-US').replace(/\//g, '.')} – {props.question.category}
        </Meta>
        <Question>
          {imageURL && (
            <ImageContainer>
              <img src={imageURL} />
            </ImageContainer>
          )}
          <TextContainer type={props.question.type}>{props.question.question}</TextContainer>
        </Question>
        <Answers>
          <Answer nature={(props.showCorrectAnswer && 'correct') || (result && result.result && 'correct')}>
            {props.question.correctAnswer}
          </Answer>
          <Answer nature={result && result.answer === 'alternativeAnswer_a' && 'wrong'}>{props.question.alternativeAnswer_a}</Answer>
          <Answer nature={result && result.answer === 'alternativeAnswer_b' && 'wrong'}>{props.question.alternativeAnswer_b}</Answer>
          <Answer nature={result && result.answer === 'alternativeAnswer_c' && 'wrong'}>{props.question.alternativeAnswer_c}</Answer>
        </Answers>
        {questionReactions && (
          <AnimatePresence>
            {questionReactions.length > 0 && (
              <ReactionContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                {questionReactions.map(reaction => (
                  <Reaction>
                    <ReactionCounter>{reaction.selectedBy.length}</ReactionCounter>
                    <Emoji key={reaction.emoji} code={reaction.emoji} />
                  </Reaction>
                ))}
              </ReactionContainer>
            )}
          </AnimatePresence>
        )}
        {props.actions && (
          <ActionContainer>
            {/* <Action>
              <IconEdit /> Bearbeiten
            </Action> */}
            <Action onClick={deleteQuestion}>
              <IconDelete />
              Löschen
            </Action>
          </ActionContainer>
        )}
      </QuestionContainer>
    </Card>
  )
}

export default QuestionCard
