import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

const Container = styled(motion.div)`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 12000;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1000;
    top: 0px;
    left: 0px;
    position: absolute;
    background-color: ${props => props.theme.primary.dark};
    opacity: ${props => (props.nature === 'opaque' ? '0.92' : '0.8')};
    transition: background-color 0.6s;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1000;
    bottom: 0px;
    left: 0px;
    position: absolute;
    background: ${props => props.theme.primary.dark};
    background: ${props =>
      props.gradientStart === 'top'
        ? 'linear-gradient(0deg, ' + props.theme.primary.dark + ' 100%, rgba(0, 0, 0, 0) 0%);'
        : 'linear-gradient(0deg, ' + props.theme.primary.dark + ' 0%, rgba(0, 0, 0, 0) 100%);'};
    opacity: ${props => (props.nature === 'opaque' ? '0.9' : '0.8')};
    transition: background 0.6s;
  }
`

function Layer(props) {
  const variants = {
    visible: { opacity: 1 },
    hidden: {
      opacity: 0,
      transition: {
        when: 'afterChildren',
      },
    }
  }

  return (
    <Container
      gradientStart={props.gradientStart}
      onClick={props.action}
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={variants}
      nature={props.nature}
    >
      {props.children}
    </Container>
  )
}

export default Layer
