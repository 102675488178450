import { AnimatePresence, motion, useSpring, useTransform } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Spinner } from '../components/atoms'
import { FloatingButton } from '../components/buttons'
import { QuestionCard } from '../components/teaser'
import { DrawerFilter } from './'

const Layer = styled(motion.div)`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 12000;
  display: flex;
  justify-content: center;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1000;
    top: 0px;
    left: 0px;
    position: absolute;
    background-color: ${props => props.theme.primary.dark};
    opacity: 0.5;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1000;
    bottom: 0px;
    left: 0px;
    position: absolute;
    background: ${props => props.theme.primary.dark};
    background: ${props => 'linear-gradient(0deg, ' + props.theme.primary.dark + ' 0%, rgba(0, 0, 0, 0) 100%);'};
    opacity: 0.8;
  }
`

const Drawer = styled(motion.div)`
  position: absolute;
  width: calc(100% - 48px);
  height: 100vh;
  top: 0px;
`

const CardPosition = styled(motion.div)`
  position: absolute;
  width: 100%;
  left: 0px;
  top: 50%;
  z-index: 2000;
  transition: opacity 0.6s;
  padding-bottom: ${props => props.theme.space.l};
`

const DrawerCard = styled(motion.div)`
  position: relative;
  width: 100%;
  transition: height 0.6s;
  transform-origin: bottom;
`

function QuestionDrawer(props) {
  const [drawerIndex, setDrawerIndex] = useState()
  const [filterDrawer, setFilterDrawer] = useState(false)
  const [activeFilter, setActiveFilter] = useState(props.filter ? props.filter : 'all')
  const [activeCard, setActiveCard] = useState()
  const [questions, setQuestions] = useState()
  const [deletedQuestions, setDeletedQuestions] = useState()

  useEffect(() => {
    setDrawerIndex(0)
  }, [])

  const handleFilter = option => {
    setActiveFilter(option)
  }

  useEffect(() => {
    createQuestionArray()
  }, [activeFilter])

  const createQuestionArray = () => {
    let filteredQuestions = []

    if (activeFilter !== 'all') {
      filteredQuestions = props.userQuestions.filter(question => question.category === activeFilter)
      setQuestions(filteredQuestions)
      setDrawerIndex(0)
    } else {
      setQuestions(props.userQuestions)
      setDrawerIndex(0)
    }
  }

  const handleDeletion = questionID => {
    let newQuestionArray = questions.filter(question => {
      return question.id !== questionID
    })

    setQuestions(newQuestionArray)
  }

  const changeDrawerIndex = newIndex => {
    setDrawerIndex(newIndex)
  }

  const x = useSpring(0, { stiffness: 50, damping: 10, mass: 0.5 })
  const slowX = useTransform(x, value => value * 0.4)
  const fastX = useTransform(x, value => value * 1.6)
  const rotateValue = useTransform(x, value => value * 0.1)
  const newRotateValue = useSpring(0, { stiffness: 50, damping: 10, mass: 0.5 })
  const activePosition = useSpring(window.innerHeight * 0.2, { stiffness: 200, damping: 20, mass: 1 })

  function handleDragEnd(event, info) {
    let newIndex

    if (info.point.x < -0.5) {
      newIndex = drawerIndex + 1
      const userQuestionsLength = questions.length

      if (newIndex < userQuestionsLength) {
        changeDrawerIndex(newIndex)
      }
    } else if (info.point.x > 0.5) {
      newIndex = drawerIndex - 1
      newIndex >= 0 && changeDrawerIndex(newIndex)
    }

    newRotateValue.set(0)
  }

  function emitDragEvent(event, info) {
    newRotateValue.set(info.point.x * 0.2)
  }

  return (
    <Layer initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      {!props.loadingUserQuestions && questions ? (
        <>
          <DrawerFilter
            initialFilter={props.filter}
            allLabel="Alle Kategorien"
            filterOptions={props.userCategories}
            emitFilterValue={handleFilter}
            emitDrawerStatus={status => setFilterDrawer(status)}
            questionNumber={questions.length}
          />
          <Drawer
            // style={{ x: slowX }}
            initial={{y: '85vh'}}
            animate={{ y: filterDrawer ? '85vh' : 0 }}
            exit={{y: '85vh'}}
            transition={{ type: 'spring', mass: 1, stiffness: 100, damping: 12 }}
          >
            <AnimatePresence>
              {questions
                .sort((a, b) => b.timestamp - a.timestamp)
                .map((question, index) => {
                  let active = false
                  let preview = false
                  let previewPosition

                  if (index === drawerIndex) {
                    active = true
                  } else {
                    active = false
                  }

                  if (index === drawerIndex + 1) {
                    preview = true
                    previewPosition = 1
                  } else if (index === drawerIndex - 1) {
                    preview = true
                    previewPosition = -1
                  }

                  if (active || preview) {
                    return (
                      <CardPosition
                        key={'CardPosition-' + question.id}
                        active={active}
                        preview={preview}
                        previewPosition={previewPosition}
                        initial={{ x: preview ? (previewPosition === 1 ? '120%' : '-120%') : 0, y: '-50%' }}
                        animate={{ x: preview ? (previewPosition === 1 ? '120%' : '-120%') : 0, y: '-50%' }}
                        exit={{ x: preview ? (previewPosition === 1 ? '120%' : '-120%') : 0, y: '-50%' }}
                        transition={{ ease: 'easeOut', duration: 0.4 }}
                        positionTransition
                      >
                        <DrawerCard
                          key={'DrawerCard-' + question.id}
                          transition={{ ease: 'easeOut', duration: 0.4 }}
                          preview={preview}
                          previewPosition={previewPosition}
                          drag="x"
                          dragElastic={0.3}
                          dragConstraints={{ top: 0, bottom: 0, left: 0, right: 0 }}
                          onDrag={(event, info) => emitDragEvent(event, info)}
                          onDragEnd={(event, info) => handleDragEnd(event, info)}
                          style={{ rotate: !preview && newRotateValue }}
                        >
                          <QuestionCard
                            key={question.id}
                            group={props.group}
                            questionID={question.id}
                            question={question}
                            active={active}
                            emitDeletion={handleDeletion}
                            actions
                            showCorrectAnswer
                          />
                        </DrawerCard>
                      </CardPosition>
                    )
                  }
                })}
            </AnimatePresence>
          </Drawer>
        </>
      ) : (
        <Spinner />
      )}

      <FloatingButton onClick={props.closeQuestionDrawer}>Zurück</FloatingButton>
    </Layer>
  )
}

export default QuestionDrawer
