import { AnimatePresence, motion, useSpring } from 'framer-motion'
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Context } from '../../../context/ContextProvider'
import { CREATE_REQUEST } from '../../../database/actions/gameActions'
import { useUserStatistics } from '../../../database/services/useUserStatistics'
import { useModal } from '../../../hooks'
import { Player } from '../teaser'

const Layer = styled(motion.div)`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 12000;
`

const Container = styled(motion.div)`
  position: fixed;
  bottom: -64px;
  left: 0px;
  height: calc(100% - 26px);
  width: 100%;
  border-radius: 16px 16px 0px 0px;
  background-color: ${props => props.theme.primary.light};
  z-index: 14000;
  box-shadow: ${props => props.theme.shadow.base};
  overflow: hidden;

  h3 {
    margin-bottom: ${props => props.theme.space.m};
  }

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 16px;
    width: 48px;
    height: 5px;
    background: ${props => props.theme.primary.base};
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2.5px;
    z-index: 2000;
  }
`

const Headline = styled.div`
  position: absolute;
  padding: ${props => props.theme.space.xl} ${props => props.theme.space.l} ${props => props.theme.space.s} ${props => props.theme.space.l};
  text-align: center;
  width: 100%;
  z-index: 1000;
  background: ${props => props.theme.primary.light};
  box-shadow: ${props => props.scrolling === true && props.theme.shadow.base};
  transition: box-shadow 0.4s;

  h3 {
    margin: 0px 0px 0px 0px;
  }
`

const DragArea = styled(motion.div)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 80px;
  z-index: 7000;
  /* background-color: white; */
`

const List = styled(motion.div)`
  position: relative;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  padding: 88px ${props => props.theme.space.l} ${props => props.theme.space.xxl} ${props => props.theme.space.l};
  text-align: center;
`

function PlayerList(props) {
  const context = useContext(Context)

  const { userStatistics } = useUserStatistics()

  const { showModal } = useModal()
  const [scrolling, setScrolling] = useState(false)

  let listRef = React.createRef()

  function invitePlayer(playerId, playerName) {
    const userArray = [playerId.substring(0, 6), props.user.uid.substring(0, 6)]
    const sortedArray = userArray.sort()

    const requestId = 'request-' + sortedArray[0] + sortedArray[1]

    CREATE_REQUEST(
      context.userProfile.activeGroup,
      requestId,
      props.user.uid,
      context.userProfile.username,
      playerId,
      playerName,
      requestCallback,
      showModal
    )

    closeOverlay()
  }

  const requestCallback = status => {
    console.log(status)
  }

  const y = useSpring(window.innerHeight, { stiffness: 200, damping: 20, mass: 1 })

  function handleDragEvent(event, info) {
    if (info.point.y > 0) {
      y.set(window.innerHeight)
      props.closePlayerList()
    } else if (info.point.y < -0) {
      // y.set(0)
      y.set(0)
    } else {
      y.set(0)
    }
  }

  function emitDragEvent(event, info) {
    if (info.point.y > 0) {
      y.set(info.point.y * 1.6)
    } else {
      info.point.y > -64 ? y.set(info.point.y * 0.4) : y.set(info.point.y * 0)
    }
  }

  function closeOverlay() {
    props.closePlayerList()
    y.set(window.innerHeight)
  }

  useEffect(() => {
    props.status && y.set(0)
  }, [props.status])

  useEffect(() => {
    console.log(userStatistics)
  }, [userStatistics])

  useEffect(() => {
    listRef.current &&
      listRef.current.addEventListener(
        'scroll',
        function(e) {
          listRef.current && listRef.current.scrollTop === 0 ? setScrolling(false) : setScrolling(true)
        },
        { passive: true }
      )

    return window.removeEventListener('scroll', function(e) {})
  }, [listRef])

  return (
    <>
      <AnimatePresence>
        {props.status && <Layer onClick={closeOverlay} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} />}
      </AnimatePresence>

      <AnimatePresence>
        {props.status && (
          <Container
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6, type: 'tween' }}
            exit={{ opacity: 1 }}
            key="container"
            style={{ y }}
          >
            <DragArea
              drag="y"
              dragConstraints={{ top: 0, bottom: 0, left: 0, right: 0 }}
              dragMomentum={false}
              dragElastic={0.6}
              onDrag={(event, info) => emitDragEvent(event, info)}
              onDragEnd={(event, info) => handleDragEvent(event, info)}
            />
            <Headline scrolling={scrolling}>
              <h3>Gegen wen möchtest du antreten?</h3>
            </Headline>
            <List ref={listRef}>
              {props.groupMemberProfiles &&
                Object.values(props.groupMemberProfiles)
                  .filter(player => player.id !== props.userId)
                  .sort()
                  .map(player => (
                    <Player
                      key={player.id}
                      invitePlayer={invitePlayer}
                      opponentId={player.id}
                      username={player.username}
                      slogan={player.slogan}
                      statistics={userStatistics?.hasOwnProperty(player.id) ? userStatistics[player.id] : null}
                    />
                  ))}
            </List>
          </Container>
        )}
      </AnimatePresence>
    </>
  )
}

export default PlayerList
