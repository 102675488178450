import { useContext } from 'react'
import { Modal } from '../context'

export function useModal() {
  
  const modal = useContext(Modal)

  const showModal = (type, title, bodyText, ctaLabel, action, cancelOption, confirmValue) => {
    console.log(title)
    modal.show(type, title, bodyText, ctaLabel, action, cancelOption, confirmValue)
  }

  const hideModal = () => {
    modal.hide()
  }

  return {
    showModal,
    hideModal,
  }
}
