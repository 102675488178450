import { motion } from 'framer-motion'
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Modal } from '../../../context/ModalProvider'
import { Input } from '../../components/inputs'

const Container = styled(motion.div)`
  position: relative;
  width: calc(100% - 64px);
  height: auto;
  padding: ${props => props.theme.space.m};
  color: ${props => props.theme.neutral.bright};
  font-size: ${props => props.theme.fontSize.root};
  line-height: ${props => props.theme.lineHeight.root};
`

const Background = styled(motion.div)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.primary.light};
  border-radius: ${props => props.theme.radius.m};
  box-shadow: ${props => props.theme.shadow.base};
  z-index: 0;
`

const TextWrapper = styled(motion.div)`
  position: relative;
  z-index: 1000;

  h3 {
    margin-bottom: ${props => props.theme.space.xs};
  }

  p {
    margin: 0px 0px ${props => props.theme.space.s};
  }
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: ${props => props.theme.space.m};
`

const Button = styled.button`
  flex-grow: 1;
  color: ${props => (props.nature === 'primary' ? props.theme.secondary.base : props.theme.primary.dark)};
  border: 0px;
  margin-right: ${props => props.theme.space.xs};
  padding: ${props => props.theme.space.s} ${props => props.theme.space.s};
  background: ${props => (props.nature === 'primary' ? props.theme.tertiary.base : props.theme.primary.dark)};
  outline: 0px;
  color: ${props => (props.nature === 'primary' ? props.theme.neutral.bright : props.theme.neutral.bright)};
  font-weight: ${props => props.theme.fontWeight.medium};
  font-size: ${props => props.theme.fontSize.s};
  text-transform: uppercase;
  border-radius: ${props => props.theme.radius.m};
  box-shadow: ${props => props.theme.shadow.light};
  opacity: ${props => props.disabled ? '0.4' : '1'};
  transition: opacity 0.3s;

  &:last-child {
    margin: 0px;
  }
`

const textTransition = {
  hidden: {
    opacity: 0,
    transition: {
      type: 'tween',
      duration: 0.3,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.2,
    },
  },
}

const containerTransition = {
  hidden: {
    scale: 0.5,
  },
  visible: {
    scale: 1,
    transition: {
      type: 'spring',
      damping: 18,
      mass: 1.5,
      stiffness: 240,
    },
  },
}

function GlobalModal() {
  const modal = useContext(Modal)
  const [inputValue, setInputValue] = useState('')
  const [confirmed, setConfirmed] = useState(false)

  useEffect(() => {
    inputValue === modal.confirmValue ? setConfirmed(true) : setConfirmed(false)
  }, [inputValue])

  useEffect(() => {
    console.log(modal)
  })

  const handleAction = () => {
    confirmed && modal.action && modal.action.fn()
    confirmed && modal.hide()
  }

  const handleInput = value => {
    setInputValue(value)
  }

  return (
    <Container>
      <TextWrapper variants={textTransition} initial="hidden" animate={modal.visible ? 'visible' : 'hidden'}>
        <h3>{modal.title}</h3>
        <p>{modal.bodyText}</p>
        <p><strong>Bitte gib zur Bestätigung noch einmal den Gruppennamen an.</strong></p>
        <Input placeholder="Gruppenname" value={inputValue} emitValue={handleInput} maxLength={modal.confirmValue ? modal.confirmValue.length : '0'} />
        <ButtonContainer>
          <Button disabled={!confirmed} nature="primary" onClick={handleAction}>
            {modal.ctaLabel}
          </Button>
          {modal.cancelOption && (
            <Button nature="secondary" onClick={modal.hide}>
              Abbrechen
            </Button>
          )}
        </ButtonContainer>
      </TextWrapper>
      <Background variants={containerTransition} initial="hidden" animate={modal.visible ? 'visible' : 'hidden'} />
    </Container>
  )
}

export default GlobalModal
