import React, { useEffect, useState } from 'react'
import { useQuizList, useRequestList } from '../database/services'

export const GameStore = React.createContext()
export const Provider = GameStore.Provider
export const Consumer = GameStore.Consumer

export function GameStoreProvider({ activeGroup, ...props }) {
  const { requestList, challengeList } = useRequestList(activeGroup, props.uid)
  const { challengerQuizList, opponentQuizList } = useQuizList(activeGroup, props.uid)

  const [activeGameList, setActiveGameList] = useState()
  const [waitingGameList, setWaitingGameList] = useState()

  useEffect(() => {
    let activeItems = []
    if (challengerQuizList && opponentQuizList && challengeList) {
      activeItems = activeItems.concat(challengerQuizList.filter(quiz => quiz.challenger === props.uid && quiz.turnOwner === 'challenger'))
      activeItems = activeItems.concat(opponentQuizList.filter(quiz => quiz.opponent === props.uid && quiz.turnOwner === 'opponent'))
      activeItems = activeItems.concat(challengeList)
      setActiveGameList(activeItems)
    }
  }, [props.uid, challengeList, challengerQuizList, opponentQuizList])

  useEffect(() => {
    let waitingItems = []
    if (challengerQuizList && opponentQuizList && requestList) {
      waitingItems = waitingItems.concat(challengerQuizList.filter(quiz => quiz.challenger === props.uid && quiz.turnOwner === 'opponent'))
      waitingItems = waitingItems.concat(opponentQuizList.filter(quiz => quiz.opponent === props.uid && quiz.turnOwner === 'challenger'))
      waitingItems = waitingItems.concat(requestList)
      setWaitingGameList(waitingItems)
    }
  }, [props.uid, requestList, challengerQuizList, opponentQuizList])

  return (
    <Provider
      value={{
        quizzes: {
          requestList: requestList,
          challengeList: challengeList,
          challengerQuizList: challengerQuizList,
          opponentQuizList: opponentQuizList,
        },
      }}
    >
      {props.children}
    </Provider>
  )
}

export default GameStoreProvider
