import { AnimatePresence, motion, useSpring } from 'framer-motion'
import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Context } from '../../context/ContextProvider'
import { useUserQuestions } from '../../database/services'
import { PlayerList } from '../components/groups'
import { TabBar } from '../components/partials'
import { GroupEditor, GroupThumbnailEditor, QuestionDrawer, QuestionProducer } from '../tunnels'
import { Dashboard, GroupManager, PoolManager, Profile } from './'

const Container = styled(motion.div)`
  padding: 0px;
`

const Slider = styled(motion.div)`
  box-sizing: border-box;
  position: relative;
  display: flex;
  width: calc(400% + 120px);
`

const ViewSlide = styled(motion.div)`
  position: relative;
  width: 100%;
  height: auto;
  margin-right: 40px;
  min-height: calc(100vh - 92px);
  /* overflow-y: scroll; */

  &:last-child {
    margin-right: 0px;
  }
`

function MainView({ user, ...props }) {
  const context = useContext(Context)

  const [sliderIndex, setSliderIndex] = useState(0)

  const [swipeInput, setSwipeInput] = useState(false)
  const [playerListStatus, setPlayerListStatus] = useState(false)
  const [activeGroup, setActiveGroup] = useState(false)
  const [userId, setUserId] = useState(false)

  const { userQuestions, userCategories, userQuestionNumber, loadingUserQuestions } = useUserQuestions(activeGroup, userId)

  useEffect(() => {
    context && setActiveGroup(context.userProfile.activeGroup)
    context && setUserId(context.userProfile.uid)
  }, [context])

  useEffect(() => {
    console.log(containerRef.current.clientWidth)
  })

  const [showQuestionDrawer, setShowQuestionDrawer] = useState(false)
  const [questionDrawerFilter, setQuestionDrawerFilter] = useState('all')
  const [showQuestionProducer, setShowQuestionProducer] = useState(false)
  const [showGroupThumbnailEditor, setShowGroupThumbnailEditor] = useState(false)
  const [showGroupEditor, setShowGroupEditor] = useState(false)
  const [slideWidth, setSlideWidth] = useState(0)

  const containerRef = useRef(null)
  const mainViewIndexRef = useRef(sliderIndex)

  useEffect(() => {
    containerRef && setSlideWidth(containerRef.current.clientWidth + 40)
    containerRef && console.log(containerRef.current.clientWidth)
  }, [containerRef])

  function changeSliderIndex(newIndex) {
    setSliderIndex(newIndex)
    localStorage.setItem('mainViewIndex', newIndex)
    mainViewIndexRef.current = newIndex
  }

  function moveSliderForwards() {
    mainViewIndexRef.current < 3 && changeSliderIndex(mainViewIndexRef.current + 1)
  }

  function moveSliderBackwards() {
    mainViewIndexRef.current > 0 && changeSliderIndex(mainViewIndexRef.current - 1)
  }

  function handleQuestionDrawer(preset) {
    !showQuestionDrawer ? setQuestionDrawerFilter(preset) : setQuestionDrawerFilter('all')
    setShowQuestionDrawer(!showQuestionDrawer)
  }

  function handleQuestionProducer() {
    setShowQuestionProducer(!showQuestionProducer)
  }

  function handleGroupThumbnailEditor() {
    setShowGroupThumbnailEditor(!showGroupThumbnailEditor)
  }

  function handleGroupEditor() {
    setShowGroupEditor(!showGroupEditor)
  }

  function openPlayerList() {
    setPlayerListStatus(true)
  }

  function closePlayerList() {
    setPlayerListStatus(false)
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress, false)

    return () => window.removeEventListener('keydown', handleKeyPress, false)
  }, [])

  const handleKeyPress = event => {
    if (event.key === 'ArrowRight') {
      moveSliderForwards()
    } else if (event.key === 'ArrowLeft') {
      moveSliderBackwards()
    }
  }

  useEffect(() => {
    !context.headerVisible && context.updateHeaderVisibility(true)
    context.headerArrow && context.updateHeaderArrow(false)
  }, [context])

  const x = useSpring(slideWidth * sliderIndex * -1, { stiffness: 200, damping: 20, mass: 1 })

  useEffect(() => {
    console.log(context)
  })

  function handleDragEvent(event, info) {
    if (info.point.x > 10) {
      sliderIndex > 0 && setSliderIndex(sliderIndex - 1)
      sliderIndex > 0 && localStorage.setItem('mainViewIndex', sliderIndex - 1)
    } else if (info.point.x < -10) {
      sliderIndex < 3 && setSliderIndex(sliderIndex + 1)
      sliderIndex < 3 && localStorage.setItem('mainViewIndex', sliderIndex + 1)
    }
    setSwipeInput(false)
  }

  useEffect(() => {
    x.set(slideWidth * sliderIndex * -1)
  }, [sliderIndex])

  function emitDragEvent(event, info) {
    // if (info.point.x > 60) {
    //   sliderIndex != 0 && setSliderIndex(0)
    //   x.set(0)
    // } else if (info.point.x < -60) {
    //   sliderIndex != 1 && setSliderIndex(1)
    //   x.set(-window.innerWidth)
    // }
    setSwipeInput(true)
  }

  function shareGroupLink() {
    if (navigator.share) {
      navigator
        .share({
          title: context.activeGroup.name + ' teilen',
          url: `https://${window.location.hostname}?invite=${context.userProfile.activeGroup}`,
        })
        .then(() => {
          console.log('Du hast erfolgreich die Gruppe geteilt.')
        })
        .catch(console.error)
    } else {
      // fallback
    }
  }

  const viewTransition = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  }

  return (
    <motion.div key="dashboard" initial="initial" animate="animate" exit="exit" variants={viewTransition} onKeyPress={handleKeyPress}>
      <Container ref={containerRef}>
        <TabBar
          sliderIndex={sliderIndex}
          changeSliderIndex={changeSliderIndex}
          actions={[openPlayerList, handleQuestionProducer, shareGroupLink]}
        />
        <Slider
          drag="x"
          onDragEnd={(event, info) => handleDragEvent(event, info)}
          onDrag={(event, info) => emitDragEvent(event, info)}
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={0.2}
        >
          <ViewSlide style={{ x }} key="dashboard" transition={{ duration: 0.4, type: 'tween' }}>
            <Dashboard visible={sliderIndex === 0 ? true : false} user={user} {...props} />
          </ViewSlide>
          <ViewSlide key="questionpool" style={{ x }} transition={{ duration: 0.2, type: 'tween' }}>
            <PoolManager
              user={user}
              context={context}
              userQuestionNumber={userQuestionNumber}
              handleQuestionDrawer={handleQuestionDrawer}
              handleQuestionProducer={handleQuestionProducer}
              {...props}
            />
          </ViewSlide>
          <ViewSlide key="community" style={{ x }} transition={{ duration: 0.2, type: 'tween' }}>
            <GroupManager
              groupMemberProfiles={context.groupMemberProfiles}
              user={user}
              context={context}
              handleGroupEditor={handleGroupEditor}
              handleGroupThumbnailEditor={handleGroupThumbnailEditor}
              {...props}
            />
          </ViewSlide>
          <ViewSlide key="profile" style={{ x }} transition={{ duration: 0.2, type: 'tween' }}>
            <Profile user={user} context={context} handleQuestionDrawer={handleQuestionDrawer} {...props} />
          </ViewSlide>
        </Slider>
        <PlayerList
          userId={userId}
          groupMemberProfiles={context.groupMemberProfiles}
          status={playerListStatus}
          closePlayerList={closePlayerList}
          user={user}
        />
        <AnimatePresence>
          {/* {!swipeInput && sliderIndex === 0 && <FloatingButton onClick={openPlayerList}> Neues Spiel</FloatingButton>} */}
          {/* {!swipeInput && sliderIndex === 0 && <ActionButton onClick={openPlayerList} />} */}
          {/* {!swipeInput && sliderIndex === 1 && <FloatingButton onClick={handleQuestionProducer}>Neue Frage erstellen</FloatingButton>} */}
        </AnimatePresence>
      </Container>

      <AnimatePresence>
        {showQuestionDrawer && (
          <QuestionDrawer
            closeQuestionDrawer={handleQuestionDrawer}
            loadingUserQuestions={loadingUserQuestions}
            userQuestions={userQuestions}
            userCategories={userCategories}
            group={context.userProfile.activeGroup}
            filter={questionDrawerFilter}
          />
        )}
        {showQuestionProducer && <QuestionProducer closeQuestionProducer={handleQuestionProducer} user={user} />}
        {showGroupThumbnailEditor && (
          <GroupThumbnailEditor
            context={context}
            visible={showGroupThumbnailEditor}
            close={handleGroupThumbnailEditor}
            user={user}
            group={context.userProfile.activeGroup}
            updateGroupContext={context.updateActiveGroup}
          />
        )}
        {showGroupEditor && <GroupEditor context={context} close={handleGroupEditor} />}
      </AnimatePresence>
    </motion.div>
  )
}

export default MainView
