import React, { useContext, useEffect } from 'react'
import { Context } from '../../context/ContextProvider'
import { db } from '../config'

export function useProfileById(uid) {
  const context = useContext(Context)

  const [profileError, setProfileError] = React.useState(false)
  const [profileLoading, setProfilLoading] = React.useState(true)
  const [existingProfile, setExistingProfile] = React.useState(false)
  const [userProfile, setUserProfile] = React.useState()

  useEffect(() => {
    let unsubscribe = () => {}

    uid !== 'Spreadsheet' &&
      (unsubscribe = db
        .collection('users')
        .doc(uid)
        .onSnapshot(function(doc) {
          if (doc.exists) {
            setUserProfile(doc.data())
            setExistingProfile(true)
          } else {
            setExistingProfile(false)
          }
        }))

    return () => unsubscribe()
  }, [])

  return {
    profileError,
    profileLoading,
    existingProfile,
    userProfile,
  }
}
