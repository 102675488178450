import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CHANGE_GROUPTHUMBNAIL } from '../../database/actions/groupActions'
import { CloseButton } from '../components/buttons'
import { SingleStepDialog } from '../components/dialog'
import { ImageUploader } from '../components/inputs'
import { Layer } from '../components/layout'

const Header = styled.div`
  position: relative;
  padding: ${props => props.theme.space.sm};
  color: ${props => props.theme.neutral.bright};
  font-weight: ${props => props.theme.fontWeight.medium};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

function GroupThumbnailEditor(props) {

  const [initialThumbnailURL, setInitialThumbnailURL] = useState('gs://friendsquiz-2020.appspot.com/groups/' + props.group +  '/' +  props.context.activeGroup.thumbnail + '_1024x1024')
  const [thumbnailName, setThumbnailName] = useState('thumbnail-' + new Date().valueOf())



  const [clearUploader, setClearUploader] = useState('')
  const [imageUpload, setImageUpload] = useState(false)
  const [imageName, setImageName] = useState('')
  const [selectedImage, setSelectedImage] = useState('')
  const [storageURL, setStorageURL] = useState('')

  function emitSelectedImage(url) {
    setSelectedImage(url)
  }

  const emitImageName = name => {
    setImageName(name)
  }

  const emitStorageURL = url => {
    setStorageURL(url)
    CHANGE_GROUPTHUMBNAIL(props.group, thumbnailName)
    props.context.updateGroupThumbnail('gs://friendsquiz-2020.appspot.com/groups/' + props.group + '/' + thumbnailName + '_1024x1024')
  }

  const resetUploader = () => {
    setClearUploader(true)
  }

  function triggerUpload() {
    setImageUpload(true)
  }

  useEffect(() => {
    clearUploader && setClearUploader(false)
  }, [clearUploader])

  useEffect(() => {
    console.log(props)
  })

  return (
    <Layer gradientStart="top">
      <Header>
        Gruppenbild bearbeiten
        <CloseButton closeFunction={props.close} />
      </Header>
      <SingleStepDialog
        primaryLabel="Aktualisieren"
        primaryAction={triggerUpload}
        secondaryLabel="Neu hochladen"
        secondaryAction={resetUploader}
      >
        <ImageUploader
          group={props.group}
          clear={clearUploader}
          initialImage={initialThumbnailURL}
          triggerUpload={imageUpload}
          emitImageName={emitImageName}
          emitStorageURL={emitStorageURL}
          emitSelectedImage={emitSelectedImage}
          imageName={thumbnailName}
        />
      </SingleStepDialog>
    </Layer>
  )
}

export default GroupThumbnailEditor
