import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as IconCorrect } from '../../../assets/icons/correct.svg'
import { ReactComponent as IconWrong } from '../../../assets/icons/wrong.svg'

const Badge = styled(motion.div)`
  width: 12px;
  height: 12px;
  margin: 0px ${props => props.theme.space.m} 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:last-child {
    margin: 0px;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`

const IconContainer = styled(motion.span)`
  svg {
    fill: ${props => (props.nature === 'wrong' ? props.theme.primary.dark : props.theme.positive.base)};
  }
`

function ResultBadge(props) {

  const showQuestionModal = () => {
    props.result !== undefined && props.showQuestion(props.quizRound, props.quizRoundIndex, props.role)
  }

  // const hideQuestionModal = () => {
  //   console.log('hide!')
  //   props.closeQuestionModal()
  // }

  return (
    <Badge whileTap={{ scale: 0.8 }} result={props.results ? props.results.challenger_0 : undefined} onClick={showQuestionModal} unselectable="on">
      {/* {props.result === undefined && <IconCorrect />} */}
      {props.result === false && (
        <IconContainer nature="wrong" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <IconWrong />
        </IconContainer>
      )}
      {props.result === true && (
        <IconContainer nature="correct" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <IconCorrect />
        </IconContainer>
      )}
    </Badge>
  )
}

export default ResultBadge
