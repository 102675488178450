import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const AreaContainer = styled.div`
  position: relative;
`

const TextArea = styled.textarea`
  display: block;
  border: 0px solid #fff;
  border: ${props => props.nature === 'hero' ? 'none' :  '2px solid ' + props.theme.primary.dark};
  background-color: ${props => props.theme.neutral.bright};
  outline: none;
  padding: ${props => props.nature === 'hero' ? '0px' :  props.theme.space.m};
  margin: 0px 0px 8px 0px;
  font-size: ${props => props.nature === 'hero' ? props.theme.fontSize.xl : props.theme.fontSize.m};
  color: ${props => props.theme.primary.base};
  width: 100%;
  height: ${props => props.nature === 'hero' ? '160px' : '96px'};
  border-radius: 8px;
  transition: border 0.6s;
  line-height: ${props => props.nature === 'hero' ? props.theme.lineHeight.xl : props.theme.lineHeight.m}

  ::placeholder {
    color: ${props => props.theme.neutral.text};
  }

  &:focus {
    border: 2px solid ${props => props.nature === 'hero' ? 'none' :  '2px solid' + props.theme.secondary.base};
  }

  &:disabled {
    border: 2px solid transparent;
    color: ${props => props.theme.primary.dark};

    &::placeholder {
      color: ${props => props.theme.primary.dark};
    }
  }
`

const Validator = styled.div`
  position: absolute;
  top: ${props => props.theme.space.s};
  right: ${props => props.theme.space.m};
  color: ${props => props.theme.neutral.light};
  font-weight: ${props => props.theme.fontWeight.medium};
`

function Textarea(props) {

  function changeHandler(event) {
    event.target.value.length <= props.maxLength && props.emitValue(event.target.value)
  }

  const textareaElement = useRef(null);

  // useEffect(() => {
  //   textareaElement.current.addEventListener('keydown', function(event) {
  //     if (event.key === 'Enter') {
  //       event.preventDefault();
  //     }
  //   })
  // }, [])

  useEffect(() => {
    if (textareaElement.current && props.autoFocus) {
      textareaElement.current.focus();
    }
  }, [textareaElement, props.autoFocus]);

  return (
    <AreaContainer>
      <TextArea
        nature={props.nature}
        type="text"
        placeholder={props.placeholder}
        value={props.value}
        onChange={event => changeHandler(event)}
        ref={textareaElement}
        onKeyPress={props.onKeyPress}
      />
      {/* <Validator>{props.maxLength - props.value.length}</Validator> */}
    </AreaContainer>
  )
}

export default Textarea
