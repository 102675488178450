import { motion } from 'framer-motion'
import React, { useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { Context } from '../../context/ContextProvider'
import { SAVE_QUESTION } from '../../database/actions/poolActions'
import { useCategories } from '../../database/services'
import { useNotification } from '../../hooks'
import { FloatingButton } from '../components/buttons'
import { Dropdown, ImageUploader, Input, Textarea } from '../components/inputs'
import { ContentBox } from '../components/layout'

const viewTransition = {
  enter: { opacity: 1 },
  exit: { opacity: 0 },
}

const Container = styled(motion.div)`
  padding: 0px 0px ${props => props.theme.space.xxl} 0px;
`

const Headline = styled.h2``

function QuestionEditor({ user, ...props }) {
  const context = useContext(Context)

  const [group, setGroup] = useState()
  const [type, setType] = useState('text')
  const [newQuestion, setNewQuestion] = useState('')
  const [correctAnswer, setCorrectAnswer] = useState('')
  const [alternativeAnswer_a, setAlternativeAnswer_a] = useState('')
  const [alternativeAnswer_b, setAlternativeAnswer_b] = useState('')
  const [alternativeAnswer_c, setAlternativeAnswer_c] = useState('')
  // const [categoryList, setCategoryList] = useState()
  const [category, setCategory] = useState('')
  const [imageURL, setImageURL] = useState('')
  const [complete, setComplete] = useState(false)
  const [successNotification, setSucessNotification] = useState(false)
  const [redirect, setRedirect] = useState(false)

  const { categories } = useCategories(group)

  const { notificationStatus } = useNotification(
    successNotification,
    'positive',
    'Mega! Deine Frage wurde hinzugefügt.',
    'Sie kann unmittelbar in einem Quiz auftauchen. Also sei wachsam!'
  )

  useEffect(() => {
    !context.headerArrow && context.updateHeaderArrow(true)

    if (context) {
      setGroup(context.userProfilee.activeGroup)
    }
  }, [context])

  // useEffect(() => {
  //   const list = Object.keys(context.questionPool)
  //   setCategoryList(list)
  // }, [])

  function handleNewQuestion(value) {
    setNewQuestion(value)
  }

  function handleCorrectAnswer(value) {
    setCorrectAnswer(value)
  }

  function handleFirstFakeAnswer(value) {
    setAlternativeAnswer_a(value)
  }

  function handleSecondFakeAnswer(value) {
    setAlternativeAnswer_b(value)
  }

  function handleThirdFakeAnswer(value) {
    setAlternativeAnswer_c(value)
  }

  function handleCategory(value) {
    setCategory(value)
  }

  function saveQuestion() {
    console.log('save-question')
    SAVE_QUESTION(
      context.userProfile.activeGroup,
      context.userProfile.uid,
      category,
      type,
      newQuestion,
      correctAnswer,
      alternativeAnswer_a,
      alternativeAnswer_b,
      alternativeAnswer_c,
      imageURL
    )
    setSucessNotification(true)
    setRedirect(true)
  }

  useEffect(() => {
    if (
      newQuestion !== '' &&
      correctAnswer !== '' &&
      alternativeAnswer_a !== '' &&
      alternativeAnswer_b !== '' &&
      alternativeAnswer_c !== ''
    ) {
      setComplete(true)
    } else {
      setComplete(false)
    }
  }, [newQuestion, correctAnswer, alternativeAnswer_a, alternativeAnswer_b, alternativeAnswer_c])

  return (
    <>
      <Container initial="exit" animate="enter" exit="exit" variants={viewTransition}>
        <Headline>Neue Frage erstellen</Headline>
        <ContentBox>
          <h3>Deine Frage</h3>
          <Textarea autoFocus placeholder="Wie lautet deine Frage?" value={newQuestion} emitValue={handleNewQuestion} maxLength="140" />
        </ContentBox>
        <ContentBox>
          <h3>Die richtige Antwort</h3>
          <Input key="correctAnswer" placeholder="Korrekte Antwort" value={correctAnswer} emitValue={handleCorrectAnswer} maxLength="40" />
        </ContentBox>
        <ContentBox>
          <h3>Frei erfundene Antworten</h3>
          <Input key="fakeAnswer-1" placeholder="Erste Lüge" value={alternativeAnswer_a} emitValue={handleFirstFakeAnswer} maxLength="40" />
          <Input
            key="fakeAnswer-2"
            placeholder="Zweite Finte "
            value={alternativeAnswer_b}
            emitValue={handleSecondFakeAnswer}
            maxLength="40"
          />
          <Input
            key="fakeAnswer-3"
            placeholder="Dritte Falle"
            value={alternativeAnswer_c}
            emitValue={handleThirdFakeAnswer}
            maxLength="40"
          />
        </ContentBox>
        <ContentBox>
          <h3>In welche Kategorie passt deine Frage?</h3>
          <Dropdown
            key="category"
            placeholder="Wähle eine Kategorie aus"
            value={category}
            emitValue={handleCategory}
            maxLength="40"
            options={categories}
          />
        </ContentBox>
        <ContentBox>
          <h3>Bild hochladen (optional)</h3>
          <ImageUploader />
        </ContentBox>
        {complete && <FloatingButton onClick={saveQuestion}>Frage speichern</FloatingButton>}
      </Container>

      {redirect && <Redirect to="/" />}
    </>
  )
}

export default QuestionEditor
