import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as Icon } from '../../../assets/icons/arrow-right.svg'

const Button = styled.div`
  color: ${props => props.theme.neutral.bright};
  background: ${props => props.theme.primary.dark};
  border-radius: ${props => props.theme.radius.m};
  box-shadow: ${props => props.theme.shadow.light};
  transition: box-shadow 0.3s, transform 0.3s;

  a {
    width: 100%;
    font-size: ${props => props.theme.fontSize.s};
    display: inline-block;
    color: inherit;
    text-decoration: none;
    padding: ${props => props.theme.space.sm};
    font-weight: ${props => props.theme.fontWeight.medium};
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const IconContainer = styled.span`
  width: 8px;
  height: 12px;
  display: flex;
  align-items: center;

  svg {
    display: inline-block;
    margin: 0px;
    padding: 0px;
    width: 8px;
    height: 12px;
    fill: ${props => props.theme.neutral.bright};
  }
`

function LinkButton(props) {
  return (
    <Button>
      <NavLink to={props.to}>
        {props.children}
        <IconContainer>
          <Icon />
        </IconContainer>
      </NavLink>
    </Button>
  )
}

export default LinkButton
