import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useProfileById } from '../../../database/services'

const Layer = styled(motion.div)`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 12000;
  overflow-y: scroll;
`

const Container = styled(motion.div)`
  padding: ${props => props.theme.space.xl} ${props => props.theme.space.m} ${props => props.theme.space.m} ${props => props.theme.space.m};
  position: relative;
  width: 100%;
  height: auto;
  color: ${props => props.theme.neutral.bright};
  font-size: ${props => props.theme.fontSize.root};
  line-height: ${props => props.theme.lineHeight.root};
`

const Message = styled(motion.div)`
  display: flex;
  justify-content: ${props => (props.type === 'sender' ? 'flex-start' : 'flex-end')};
  align-items: flex-start;
  margin-bottom: ${props => props.theme.space.s};
  ${props => console.log(props)}
`

const MessageContainer = styled.div`
  padding: ${props => props.theme.space.xs} ${props => props.theme.space.s};
  position: relative;
  max-width: calc(100% - 64px);
`

const ProfilImage = styled.div`
  margin: ${props => (props.type === 'sender' ? '0px ' + props.theme.space.s + ' 0px 0px' : '0px 0px 0px ' + props.theme.space.s)};
  background-image: url(${props => props.image});
  order: ${props => (props.type === 'sender' ? '-1' : '1')}
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: block;
  background-size: cover;
  box-shadow: ${props => props.theme.shadow.light};
  border: 2px solid ${props => props.theme.neutral.bright};
  position: relative;
  z-index: 1000;
`

const MessageBody = styled.div`
  position: relative;
  z-index: 1000;
  overflow: hidden;

  p {
    width: 100%;
    margin: 0px;
  }
`

const MessageBackground = styled(motion.div)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.primary.light};
  border-radius: ${props => props.theme.radius.m};
  box-shadow: ${props => props.theme.shadow.base};
  z-index: 0;
`

const MessageWrapper = styled(motion.div)`
  position: relative;
  z-index: 1000;
`

const layerTransition = {
  hidden: {
    opacity: 0,
    transition: {
      type: 'tween',
      duration: 0.3,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
    },
  },
}

const messageTransition = {
  hidden: {
    opacity: 0,
    y: 56,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

const containerTransition = {
  hidden: {
    scale: 0.5,
  },
  visible: {
    scale: 1,
    transition: {
      type: 'spring',
      damping: 18,
      mass: 1.5,
      stiffness: 240,
    },
  },
}

function MessageReader(props) {

  const [partnerPhoto, setPartnerPhoto] = useState()
  const [partnerId, setPartnerId] = useState(props.role === 'challenger' ? props.opponent : props.challenger)

  // props.role === 'challenger' ? props.opponent : props.challenger
  const { loadingProfil, userProfile } = useProfileById(partnerId)

  useEffect(() => {
    userProfile && setPartnerPhoto(userProfile.photoURL)
  }, [userProfile])

  return (
    <>
      <AnimatePresence>
        {props.show && props.messages && (
          <Layer variants={layerTransition} initial="hidden" animate="visible" exit="hidden" onClick={props.close}>
            <Container>
              <MessageWrapper>
                {Object.values(props.messages).map(message => (
                  <>
                    {console.log(message)}
                    {console.log(props.user.uid)}
                    <Message
                      variants={messageTransition}
                      positionTransition
                      type={message.sender === props.user.uid ? 'sender' : 'receiver'}
                    >
                      <ProfilImage image={message.sender === props.user.uid ? props.user.photoURL : partnerPhoto} type={message.sender === props.user.uid ? 'sender' : 'receiver'} />
                      <MessageContainer>
                        <MessageBody>
                          <p>{message.body}</p>
                        </MessageBody>
                        <MessageBackground type={message.sender === props.user.uid ? 'sender' : 'receiver'} variants={containerTransition} initial="hidden" animate={props.show ? 'visible' : 'hidden'} />
                      </MessageContainer>
                    </Message>
                  </>
                ))}
              </MessageWrapper>
            </Container>
          </Layer>
        )}
      </AnimatePresence>
    </>
  )
}

export default MessageReader
