import React, { useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  background: ${props => props.theme.secondary.background};
  border-radius: 8px;
  padding: ${props => props.theme.space.xxs};
`

const InnerContainer = styled.ul`
  position: relative;
  margin: 0px;
  list-style-type: none;
  padding: 0px;

  &:after {
    content: '';
    display: block;
    height: 100%;
    width: 50%;
    position: absolute;
    top: 0px;
    left: 0px;
    background: ${props => props.theme.secondary.base};
    border-radius: 6px;
    box-shadow: ${props => props.theme.shadow.light};
    transform: ${props => (props.index === 0 ? 'translateX(0px)' : 'translateX(100%)')};
    transition: transform 0.3s;
    pointer-events: none;
  }
`

const Tab = styled.li`
  position: relative;
  z-index: 1000;
  display: inline-block;
  width: 50%;
  text-align: center;
  padding: ${props => props.theme.space.xs};
  color: ${props => (props.active ? props.theme.neutral.bright : props.theme.secondary.base)};
  font-weight: ${props => props.theme.fontWeight.medium};
  cursor: pointer;
  transition: color 0.3s;
`

function TabSwitch(props) {
  const [index, setIndex] = useState(0)

  const handleAction = identifier => {
    props.action(identifier)
    setIndex(identifier)
  }

  return (
    <Container>
      <InnerContainer index={index}>
        <Tab active={index === 0 ? true : false} onClick={() => handleAction(0)}>
          {props.labels[0]}
        </Tab>
        <Tab active={index === 1 ? true : false} onClick={() => handleAction(1)}>
          {props.labels[1]}
        </Tab>
      </InnerContainer>
    </Container>
  )
}

export default TabSwitch
