import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Context } from '../../../context/ContextProvider'
import { useProfileById } from '../../../database/services'

const QuestionWrapper = styled.div`
  color: ${props => props.theme.neutral.bright};
  font-size: ${props => props.theme.fontSize.xxl};
  line-height: ${props => props.theme.lineHeight.xxl};
  font-weight: ${props => props.theme.fontWeight.bold};
  text-align: left;
`

const Category = styled.div`
  color: ${props => props.theme.primary.text};
  font-size: ${props => props.theme.fontSize.root};
  font-weight: ${props => props.theme.fontWeight.medium};
  margin-bottom: ${props => props.theme.space.xs};
`

function TextQuestion(props) {
  const context = useContext(Context)

  const { loadingProfil, userProfile } = useProfileById(props.author)
  const [userName, setUserName] = useState()

  useEffect(() => {
    userProfile && setUserName(userProfile.username)
  }, [userProfile])

  useEffect(() => {
    !context.headerVisible && context.updateHeaderVisibility(true)
  }, [context])

  return (
    <QuestionWrapper>
      <Category>{props.cat} {userName && '· Frage von ' + userName}</Category>
      {props.text}
    </QuestionWrapper>
  )
}

export default TextQuestion
