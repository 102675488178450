import firebase from 'firebase/app'
import { db } from '../config'

export const ADD_USER = (uid, username, slogan, photoURL, callback) => {
  const timestamp = new Date()

  db.collection('users')
    .doc(uid)
    .set({
      username: username,
      slogan: slogan,
      victory: 0,
      defeats: 0,
      draws: 0,
      photoURL: photoURL,
      activeGroup: null,
      registrationDate: timestamp,
    })
    .then(function() {
      callback()
      console.info('Das neue Nutzerprofil wurde gespeichert.')
    })
    .catch(function(error) {
      console.error('Das Nutzerprofil konnte nicht gespeichert werden: ', error)
    })
}

export const UPDATE_USER = (uid, username, slogan) => {
  db.collection('users')
    .doc(uid)
    .update({
      slogan: slogan,
    })
    .then(function() {
      console.info('Das Nutzerprofil wurde aktualisiert.')
    })
    .catch(function(error) {
      console.error('Das Nutzerprofil konnte nicht aktualisiert werden: ', error)
    })
}

export const UPDATE_USERIMAGE = (uid, photoURL) => {
  db.collection('users')
    .doc(uid)
    .update({
      photoURL: photoURL,
    })
    .then(function() {
      console.info('Das Profilbild wurde gespeichert.')
    })
    .catch(function(error) {
      console.error('Das Profilbild konnte nicht gespeichert werden: ', error)
    })
}

export const UPDATE_USERSTATISTIC = (groupId, userId, result) => {
  let resultKey = null

  switch (result) {
    case 'victory':
      resultKey = 'victory'
      break
    case 'defeat':
      resultKey = 'defeats'
      break
    case 'draw':
      resultKey = 'draws'
      break
    default:
      resultKey = 'error'
  }

  const userStatisticRef = db
    .collection('groups')
    .doc(groupId)
    .collection('statistics')
    .doc(userId)

  userStatisticRef
    .get()
    .then(function(doc) {
      if (doc.exists) {
        updateUserStatistics()
      } else {
        createUserStatistics()
      }
    })
    .catch(function(error) {
      console.log('Die Frage konnte nicht abgerufen werden:', error)
    })

  const createUserStatistics = () => {
    userStatisticRef
      .set({
        [resultKey]: firebase.firestore.FieldValue.increment(1),
      })
      .then(function() {
        console.info('Deine Spielstatistik wurde angelegt.')
      })
      .catch(function(error) {
        console.error('Deine Spielstatistik konnte nicht angelegt werden: ', error)
      })
  }

  const updateUserStatistics = () => {
    userStatisticRef
      .update({
        [resultKey]: firebase.firestore.FieldValue.increment(1),
      })
      .then(function() {
        console.info('Deine Spielstatistik wurde aktualisiert.')
      })
      .catch(function(error) {
        console.error('Deine Spielstatistik konnte nicht aktualisiert werden: ', error)
      })
  }
}

export const CHANGE_ONLINESTATUS = (uid, onlineStatus) => {
  db.collection('users')
    .doc(uid)
    .update({
      online: onlineStatus,
    })
    .then(function() {
      console.info('Dein Online-Status wurde angepasst.')
    })
    .catch(function(error) {
      console.error('Dein Online-Status konnte nicht angepasst werden: ', error)
    })
}
