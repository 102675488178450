import { AnimatePresence, motion } from 'framer-motion'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as Arrow } from '../../../assets/icons/arrow-left.svg'
import { Context } from '../../../context/ContextProvider'
import { GroupDrawer, GroupProducer } from '../../tunnels'
import { OptionButton } from '../buttons'
import { GroupThumbnail } from '../groups'

const Container = styled.div`
  width: 100%;
  height: auto;
  position: fixed;
  z-index: 10000;
  top: 0px;
  left: 0%;
  background: ${props => props.isScrolling && props.theme.primary.base};
  box-shadow: ${props => props.isScrolling && props.theme.shadow.base};
  transition: background 0.6s, box-shadow 0.4s;
`

const HeaderWrapper = styled(motion.header)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.theme.space.s} ${props => props.theme.space.sm} ${props => props.theme.space.s} ${props => props.theme.space.sm};

  a {
    text-decoration: none;
    color: inherit;
  }
`

const LogoWrapper = styled.div`
  color: #fff;
  display: flex;
  height: 32px;
  align-items: center;
  font-size: 1.1rem;
  transition: filter 0.4s;
`

const Name = styled.span`
  font-weight: ${props => props.theme.fontWeight.bold};
  color: #fff;
  padding: 0px 0px 0px 12px;

  sup {
    color: ${props => props.theme.primary.dark};
    position: relative;
    top: -4px;
    padding-left: 4px;
  }
`

const LogoContainer = styled(motion.div)`
  width: 32px;
  height: 24px;
`

const ThumbnailContainer = styled(motion.div)``

const GoBack = styled(motion.div)`
  width: 32px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${props => props.theme.secondary.base};
    width: 18px;
    height: 18px;
  }
`

function Header(props) {
  const context = useContext(Context)

  const [scrolling, setScrolling] = useState(false)
  const [showGroupDrawer, setShowGroupDrawer] = useState(false)
  const [showGroupProducer, setShowGroupProducer] = useState(false)

  useEffect(() => {
    window.addEventListener(
      'scroll',
      function(e) {
        window.scrollY === 0 ? setScrolling(false) : setScrolling(true)
      },
      { passive: true }
    )

    return window.removeEventListener('scroll', function(e) {})
  }, [])

  return (
    <>
      <AnimatePresence>
        {showGroupDrawer && (
          <GroupDrawer
            key="modal"
            show={showGroupDrawer}
            user={props.user}
            userProfile={context.userProfile}
            close={() => setShowGroupDrawer(false)}
            openGroupDialog={() => setShowGroupProducer(true)}
            context={context}
          />
        )}
        {showGroupProducer && <GroupProducer user={props.user} close={() => setShowGroupProducer(false)} />}
      </AnimatePresence>
      <Container isScrolling={scrolling}>
        <Helmet>
          {context
            ? context.activeGroup && (
                <meta
                  name="theme-color"
                  content={context.activeGroup.customThemeColors ? context.activeGroup.customThemeColors[0] : '#374498'}
                />
              )
            : '#374498'}
          {context.activeGroup ? <title>{context.activeGroup.name ? ('cuiz - ' + context.activeGroup.name) : 'cuiz'}</title> : <title>cuiz</title>}
        </Helmet>
        <AnimatePresence>
          {context.headerVisible && (
            <HeaderWrapper initial={{ y: '-100%', opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: '-100%', opacity: 0 }}>
              <LogoWrapper>
                <AnimatePresence exitBeforeEnter>
                  {context.headerArrow ? (
                    <GoBack
                      key="historyBack"
                      initial={{ y: '-100%', opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: '0%', opacity: 0 }}
                      transition={{type: 'tween', duration: 0.15}}
                    >
                      <Link to={'/' + context.userProfile.activeGroup}>
                        <Arrow />
                      </Link>
                    </GoBack>
                  ) : (
                    <ThumbnailContainer
                      key="thumbnail"
                      initial={{ y: '-100%', opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: '0%', opacity: 0 }}
                      transition={{type: 'tween', duration: 0.15}}
                      onClick={() => setShowGroupDrawer(true)}
                    >
                      <GroupThumbnail
                        size="small"
                        key={context.activeGroup.id}
                        groupId={context.activeGroup.id}
                        status={'active'}
                        thumbnailName={context.activeGroup.thumbnailName}
                      />
                    </ThumbnailContainer>
                  )}
                </AnimatePresence>
                <Name onClick={() => setShowGroupDrawer(true)}>
                  <AnimatePresence exitBeforeEnter>
                    {context.activeGroup ? (
                      <motion.span
                        key={context.activeGroup.name}
                        initial={{ y: '-100%', opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: '-100%', opacity: 0 }}
                      >
                        {context.activeGroup.name}
                      </motion.span>
                    ) : (
                      <motion.span
                        key="noGroup"
                        initial={{ y: '-100%', opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: '-100%', opacity: 0 }}
                      >
                        cuiz
                      </motion.span>
                    )}
                  </AnimatePresence>
                </Name>
              </LogoWrapper>
              {props.user && <OptionButton user={props.user} />}
            </HeaderWrapper>
          )}
        </AnimatePresence>
      </Container>
    </>
  )
}

export default Header
