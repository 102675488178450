import firebase from 'firebase/app'
import React, { useEffect, useState } from 'react'
import { Offline, Online } from 'react-detect-offline'
import { BrowserRouter as Router, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import WebFont from 'webfontloader'
import { ContextProvider, ModalProvider, NotificationProvider } from './context'
import { db } from './database/config'
import * as serviceWorker from './serviceWorker'
import { OfflineView, ViewController } from './ui/views'

// whyDidYouUpdate(React)

WebFont.load({
  google: {
    families: ['Karla:300,400,600,700'],
  },
})

const AppContainer = styled.div`
  min-height: 100vh;
  margin: 0px auto;
  box-sizing: border-box;
  font-family: 'Karla', sans-serif;
`

const App = function App() {
  const [currentUser, setCurrentUser] = useState(null)
  const [currentUserID, setCurrentUserID] = useState(null)
  const [redirect, setRedirect] = useState('')

  // initializePush()

  useEffect(() => {
    serviceWorker.register({
      onUpdate: registration => {
        registration.unregister().then(() => {
          console.info('service worker need update')
          showUpdateMessage()
        })
      },
      onSuccess: registration => {
        // console.info('service worker on success state')
        // console.log(registration)
      },
    })
  }, [])

  function showUpdateMessage() {
    const updateNotification = window.confirm('Ein Update ist verfügbar')
    if (updateNotification === true) {
      window.location.reload()
    } else {
      alert('Das war nicht schlau!')
    }
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        const userRef = db.collection('users').doc(user.uid)
        userRef.get().then(function(doc) {
          if (doc.exists) {
            setCurrentUser(user)
            setCurrentUserID(user.uid)
            console.log('Login!')
            console.log(user.uid)
            // setRedirect('dashboard')
          } else {
            setCurrentUser(user)
            setCurrentUserID(user.uid)
            setRedirect('registration')
          }
        })
      } else {
        setRedirect('login')
      }
    })
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setRedirect('')
    }, 100)
  }, [redirect])

  return (
    <AppContainer>
      <Offline>
        <OfflineView />
      </Offline>
      <Online>
        <Router>
          {redirect === 'login' && <Redirect to="/login" />}
          {redirect === 'registration' && <Redirect to="/registration" />}
          {redirect === 'dashboard' && <Redirect to="/" />}
          <ContextProvider user={currentUser} uid={currentUserID}>
            <NotificationProvider>
              <ModalProvider>
                <ViewController user={currentUser} uid={currentUserID} />
              </ModalProvider>
            </NotificationProvider>
          </ContextProvider>
        </Router>
      </Online>
    </AppContainer>
  )
}

export default App
