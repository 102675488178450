import React, { useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as SelectIcon } from '../../../assets/icons/select.svg'

const SearchContainer = styled.div`
  position: relative;
  display: ${props => (props.nature === 'hero' ? 'inline-block' : 'block')};
  width: ${props => (props.nature === 'hero' ? '220px' : '100%')};
  padding: ${props => (props.nature === 'hero' ? '4px 0px' : '0px')};
  left: ${props => (props.nature === 'hero' ? '-5px' : '0px')};
`

const SelectInput = styled.select`
  display: ${props => (props.nature === 'hero' ? 'inline-block' : 'block')};
  border: ${props => (props.nature === 'hero' ? 'none' : '2px solid ' + props.theme.primary.dark)};
  background-color: ${props => (props.nature === 'hero' ? 'transparent' : props => props.theme.neutral.bright)};
  outline: none;
  padding: ${props => (props.nature === 'hero' ? '0px' : props.theme.space.s)};
  font-size: ${props => (props.nature === 'hero' ? props.theme.fontSize.xl : props.theme.fontSize.l)};
  color: ${props => props.theme.primary.base};
  width: 100%;
  border-radius: 8px;
  transition: border 0.6s;
  font-weight: ${props => props.theme.fontWeight.regular};

  ::placeholder {
    color: ${props => props.theme.neutral.light};
  }

  &:focus {
    border: ${props => (props.nature === 'hero' ? '0px' : '2px solid ' + props.theme.secondary.base)};
  }

  &:disabled {
    border: 2px solid transparent;
    color: ${props => props.theme.primary.dark};

    &::placeholder {
      color: ${props => props.theme.primary.dark};
    }
  }
`

const IconContainer = styled.div`
  position: absolute;
  height: 16px;
  width: auto;
  top: 50%;
  transform: translateY(-50%);
  right: ${props => props.theme.space.m};

  svg {
    height: 100%;
    width: auto;
    fill: ${props => props.theme.secondary.base};
  }
`

function Dropdown(props) {
  const [selectedValue, setSelectedValue] = useState('')
  const [searchResults, setSearchResults] = useState([])

  function changeHandler(event) {
    props.emitValue(event.target.value)
    setSelectedValue(event.target.value)
  }

  return (
    <SearchContainer nature={props.nature}>
      <SelectInput nature={props.nature} type="text" value={selectedValue} onChange={event => changeHandler(event)}>
        <option value="" defaultValue>
          {props.placeholder}
        </option>
        {props.options &&
          props.options.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
      </SelectInput>
      <IconContainer>
        <SelectIcon />
      </IconContainer>
    </SearchContainer>
  )
}

export default Dropdown
