import React, { useState } from 'react'

export const Notification = React.createContext()
export const Provider = Notification.Provider
export const Consumer = Notification.Consumer

export function NotificationProvider(props) {
  const [visible, setVisible] = useState(false)
  const [nature, setNature] = useState()
  const [title, setTitle] = useState()
  const [bodyText, setBodyText] = useState()

  return (
    <Provider
      value={{
        visible,
        nature,
        title,
        bodyText,
        show: (nature, title, bodyText) => {
          setVisible(true)
          setNature(nature)
          setTitle(title)
          setBodyText(bodyText)
        },
        hide: () => {
          setVisible(false)
          setNature()
          setTitle()
          setBodyText()
        }
      }}
    >
      {props.children}
    </Provider>
  )
}

export default NotificationProvider
