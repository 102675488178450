import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { UPDATE_USER } from '../../database/actions/userActions'
import { Spinner } from '../components/atoms'
import { Input } from '../components/inputs'
import { ContentBox } from '../components/layout'

const viewTransition = {
  enter: { opacity: 1 },
  exit: { opacity: 0 },
}

const Container = styled(motion.div)`
  padding: 0px 0px ${props => props.theme.space.xxl} 0px;
  color: ${props => props.theme.neutral.bright};
`

const ProfileDetails = styled.div`
  margin: ${props => props.theme.space.s} 0px ${props => props.theme.space.m} 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;

  h3 {
    margin-top: ${props => props.theme.space.s};
    text-transform: none;
  }
`

const ProfilImage = styled.div`
  background-image: url(${props => props.image});
  width: 72px;
  height: 72px;
  border-radius: 100%;
  display: block;
  background-size: cover;
  box-shadow: ${props => props.theme.shadow.light};
  border: 2px solid ${props => props.theme.neutral.bright};
`

function Profile({ user, context, ...props }) {
  const [username, setUsername] = useState('')
  const [sloganValue, setSloganValue] = useState('')

  function updateProfil() {
    UPDATE_USER(user.uid, username, sloganValue)
  }

  useEffect(() => {
    !context.headerVisible && context.updateHeaderVisibility(true)
  }, [context])

  useEffect(() => {
    context.userProfile && setSloganValue(context.userProfile.slogan)
  }, [context.userProfile])

  function changeSlogan(value) {
    setSloganValue(value)
  }

  return (
    <Container initial="exit" animate="enter" exit="exit" variants={viewTransition}>
      <ProfileDetails>
        <ProfilImage image={user.photoURL} />
        {/* <h3>{userProfile.username}</h3> */}
      </ProfileDetails>
      <ContentBox actionFn={updateProfil} actionLabel="Speichern">
        {context.userProfile ? (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <h3>Deine Kampfansage</h3>
            <Input placeholder="Deine Kampfansage" value={sloganValue} emitValue={changeSlogan} maxLength="30" />
          </motion.div>
        ) : (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Spinner position="center" />
          </motion.div>
        )}
      </ContentBox>
    </Container>
  )
}

export default Profile
