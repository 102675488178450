import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import '../../assets/fonts/Karla-Regular.ttf'

export const GlobalStyle = createGlobalStyle`

  ${normalize}

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    
    ::-webkit-scrollbar {
      width: 0px;
      background: transparent;
      
    }
  }

  body {
    font-size: ${props => props.theme.fontSize.root};
    /* font-family: ${props => props.theme.fontFamily.primary[0]}, ${props => props.theme.fontFamily.primary[1]}; */
    font-family: 'Karla', sans-serif;
    background: ${props => props.theme.primary.base} !important;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
    overflow-y: scroll;
    overscroll-behavior: contain;
    transition: background 0.6s;
  }

  h2 {
    color: ${props => props.theme.neutral.bright};
    font-size: ${props => props.theme.fontSize.xl};
    line-height: ${props => props.theme.fontSize.xl * 1.4};
    font-weight: ${props => props.theme.fontWeight.medium};
    margin: ${props => props.theme.space.xs} 0px ${props => props.theme.space.m} 0px;
  }

  h3 {
    color: ${props => props.theme.neutral.bright};
    font-size: ${props => props.theme.fontSize.s};
    line-height: ${props => props.theme.lineHeight.s * 1.4};
    font-weight: ${props => props.theme.fontWeight.bold};
    margin: 0px 0px ${props => props.theme.space.s} 0px;
    text-transform: uppercase;
  }

  h4 {
    color: ${props => props.theme.neutral.bright};
    font-size: ${props => props.theme.fontSize.m};
    line-height: ${props => props.theme.lineHeight.m};
    font-weight: ${props => props.theme.fontWeight.bold};
    margin: 0px 0px ${props => props.theme.space.xs} 0px;
  }

  strong {
    font-weight: ${props => props.theme.fontWeight.medium}
  }
`
