import React, { useEffect } from 'react'
import { db } from '../config'

export function useQuestionById(groupId, quizId, quizRound, questionIndex) {
  const [question, setQuestion] = React.useState()
  const [challengerResult, setChallengerResult] = React.useState()
  const [opponentResult, setOpponentResult] = React.useState()

  useEffect(() => {
    const roundKey = 'round_' + quizRound
    const questionKey = 'question_' + questionIndex

    db.collection('groups')
      .doc(groupId)
      .collection('quizzes')
      .doc(quizId)
      .collection('questions')
      .doc(roundKey)
      .get()
      .then(function(doc) {
        if (doc.exists) {
          const question = doc.data()[questionKey]
          setQuestion(question)
        } else {
          setQuestion(null)
        }
      })
  }, [groupId, quizId, quizRound, questionIndex])

  useEffect(() => {
    const roundKey = 'round_' + quizRound
    const challengerKey = 'challenger_' + questionIndex
    const opponentKey = 'opponent_' + questionIndex

    db.collection('groups')
      .doc(groupId)
      .collection('quizzes')
      .doc(quizId)
      .collection('results')
      .doc(roundKey)
      .get()
      .then(function(doc) {
        if (doc.exists) {
          console.log(doc.data())
          const challengerResult = doc.data()[challengerKey]
          const opponentResult = doc.data()[opponentKey]

          setChallengerResult(challengerResult)
          setOpponentResult(opponentResult)
        } else {
          
        }
      })
  }, [groupId, quizId, quizRound, questionIndex])

  return {
    question,
    challengerResult,
    opponentResult
  }
}
