import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as SelectIcon } from '../../assets/icons/select.svg'

const CurrentContainer = styled(motion.div)`
  position: absolute;
  top: calc(16vh - 64px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 2000;
  text-align: center;
`

const CurrentValue = styled.div`
  display: flex;
  font-size: ${props => (props.nature === 'hero' ? props.theme.fontSize.xl : props.theme.fontSize.l)};
  color: ${props => props.theme.neutral.bright};
  font-weight: ${props => props.theme.fontWeight.medium};
  align-items: center;
`

const OptionList = styled(motion.ul)`
  min-width: 280px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  text-align: center;
`

const Option = styled(motion.li)`
  font-size: ${props => (props.nature === 'hero' ? props.theme.fontSize.xl : props.theme.fontSize.xl)};
  color: ${props => (props.nature === 'current' ? props.theme.secondary.base : props.theme.neutral.bright)};
  font-weight: ${props => props.theme.fontWeight.medium};
  margin-bottom: ${props => props.theme.space.m};
`

const IconContainer = styled.div`
  margin-left: ${props => props.theme.space.s};
  height: 16px;
  width: auto;

  svg {
    height: 100%;
    width: auto;
    fill: ${props => props.theme.secondary.base};
  }
`

const QuestionCounter = styled.div`
  color: ${props => props.theme.neutral.bright};
  margin-top: ${props => props.theme.space.xs};
  opacity: 0.8;
`

function DrawerFilter(props) {
  const [filterValue, setFilterValue] = useState()
  const [showOptionList, setShowOptionList] = useState(false)

  useEffect(() => {
    props.initialFilter ? setFilterValue(props.initialFilter) : setFilterValue('all')
  }, [])

  useEffect(() => {
    props.emitDrawerStatus(showOptionList)
  }, [showOptionList])

  const handleOptionList = () => {
    setShowOptionList(!showOptionList)
  }

  const selectOption = option => {
    setFilterValue(option)
    props.emitFilterValue(option)
    setShowOptionList(!showOptionList)
  }

  const optionVariants = {
    visible: i => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.1 + 0.3,
      },
    }),
    hidden: {
      opacity: 0,
      x: 100,
    },
  }

  return (
    <AnimatePresence exitBeforeEnter>
      {!showOptionList && (
        <CurrentContainer key="currentContainer" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <CurrentValue onClick={handleOptionList}>
            {filterValue === 'all' ? props.allLabel : filterValue}
            <IconContainer>
              <SelectIcon />
            </IconContainer>
          </CurrentValue>
          <QuestionCounter>
            {props.questionNumber} Fragen
          </QuestionCounter>
        </CurrentContainer>
      )}
      {props.filterOptions && showOptionList && (
        <OptionList key="filterOptions" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <Option
            nature={filterValue === 'all' ? 'current' : 'default'}
            key="all"
            onClick={() => selectOption('all')}
            variants={optionVariants}
            custom={0}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            {props.allLabel}
          </Option>
          {props.filterOptions.map((option, index) => (
            <Option
              nature={filterValue === option ? 'current' : 'default'}
              key={option}
              onClick={() => selectOption(option)}
              variants={optionVariants}
              custom={index + 1}
              initial="hidden"
              animate="visible"
              exit="hidden"
            >
              {option}
            </Option>
          ))}
        </OptionList>
      )}
    </AnimatePresence>
  )
}

export default DrawerFilter
