import { AnimatePresence, motion } from 'framer-motion'
import React, { useContext, useEffect, useState } from 'react'
import Confetti from 'react-confetti'
import { Redirect } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { Context } from '../../../context/ContextProvider'
import { CREATE_REQUEST } from '../../../database/actions/gameActions'
import { useQuestionById } from '../../../database/services'
import { useModal } from '../../../hooks'
import { Spinner } from '../atoms'
import { FloatingButton } from '../buttons'
import { ContentBox } from '../layout'
import { QuestionModal, ResultNotification, ResultRow, ScoreCounter } from './'

function QuizOverview(props) {
  const context = useContext(Context)
  const themeContext = useContext(ThemeContext)

  const [questionModalRound, setQuestionModalRound] = useState(null)
  const [questionModalRoundIndex, setQuestionModalIndex] = useState(null)
  const [questionModalOwner, setQuestionModalOwner] = useState(null)

  const { showModal } = useModal()
  const { question, challengerResult, opponentResult } = useQuestionById(
    context.userProfile.activeGroup,
    props.quizId,
    questionModalRound,
    questionModalRoundIndex
  )

  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState(false)
  const [showQuestionModal, setShowQuestionModal] = useState(false)

  useEffect(() => {
    !context.headerVisible && context.updateHeaderVisibility(true)
    !context.headerArrow && context.updateHeaderArrow(true)
  }, [context])

  useEffect(() => {
    if (props.quizResults) {
      if (props.quizResults.round_3 !== undefined && !props.quizLoading && !props.batchLoading) {
        setLoading(false)
      }
    }
  }, [props])

  function invitePlayer() {
    const playerId = props.role === 'challenger' ? props.challenger : props.opponent
    const mateId = props.role === 'challenger' ? props.opponent : props.challenger
    const playerName = props.role === 'challenger' ? props.opponentName : props.challengerName

    const userArray = [mateId.substring(0, 6), playerId.substring(0, 6)]
    const sortedArray = userArray.sort()

    const requestId = 'request-' + sortedArray[0] + sortedArray[1]

    CREATE_REQUEST(
      context.userProfile.activeGroup,
      requestId,
      props.user.uid,
      context.userProfile.username,
      mateId,
      playerName,
      requestCallback,
      showModal
    )
  }

  const requestCallback = status => {
    status === 'success' && setRedirect(true)
  }

  const showQuestion = (quizRound, quizRoundIndex, role) => {
    setShowQuestionModal(true)
    setQuestionModalRound(quizRound)
    setQuestionModalIndex(quizRoundIndex)
    role === props.role ? setQuestionModalOwner('ego') : setQuestionModalOwner('mate')
  }

  const closeQuestionModal = () => {
    setShowQuestionModal(false)
    setTimeout(() => {
      setQuestionModalRound(null)
      setQuestionModalIndex(null)
    }, 300)
  }

  useEffect(() => {
    console.log(props.winner)
  })

  return (
    <>
      <AnimatePresence>
        {showQuestionModal && (
          <QuestionModal
            question={question}
            groupId={context.userProfile.activeGroup}
            closeQuestionModal={closeQuestionModal}
            ownResult={props.role === 'challenger' ? challengerResult : opponentResult}
            mateResult={props.role === 'challenger' ? opponentResult : challengerResult}
            owner={questionModalOwner}
          />
        )}
      </AnimatePresence>
      {props.quizResult === 'victory' && (
        <Confetti
          colors={[
            themeContext.primary.light,
            themeContext.primary.dark,
            themeContext.secondary.base,
            themeContext.secondary.light,
            themeContext.secondary.dark,
            themeContext.tertiary.base,
            themeContext.tertiary.light,
            themeContext.tertiary.dark,
          ]}
          width={window.innerWidth}
          height={window.innerHeight}
        />
      )}
      {redirect && <Redirect to={'/' + context.userProfile.activeGroup} />}
      {!loading ? (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <ScoreCounter
            user={props.user}
            userProfile={context.userProfile}
            quizId={props.quizId}
            role={props.role}
            challenger={props.challenger}
            challengerName={props.challengerName}
            challengerScore={props.challengerScore}
            opponent={props.opponent}
            opponentName={props.opponentName}
            opponentScore={props.opponentScore}
            winner={props.winner}
          />

          <ContentBox nature="no-padding">
            <ResultRow
              role={props.role}
              category={props.quizCategories[0]}
              results={props.quizResults.round_0}
              quizRound={props.quizRound}
              representedRound={0}
              turnOwner={props.turnOwner}
              showQuestion={showQuestion}
              closeQuestionModal={closeQuestionModal}
            />

            <ResultRow
              role={props.role}
              category={props.quizCategories[1]}
              results={props.quizResults.round_1}
              quizRound={props.quizRound}
              representedRound={1}
              turnOwner={props.turnOwner}
              showQuestion={showQuestion}
              closeQuestionModal={closeQuestionModal}
            />

            <ResultRow
              role={props.role}
              category={props.quizCategories[2]}
              results={props.quizResults.round_2}
              quizRound={props.quizRound}
              representedRound={2}
              turnOwner={props.turnOwner}
              showQuestion={showQuestion}
              closeQuestionModal={closeQuestionModal}
            />

            <ResultRow
              role={props.role}
              category={props.quizCategories[3]}
              results={props.quizResults.round_3}
              quizRound={props.quizRound}
              representedRound={3}
              turnOwner={props.turnOwner}
              showQuestion={showQuestion}
              closeQuestionModal={closeQuestionModal}
            />
          </ContentBox>
        </motion.div>
      ) : (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <Spinner position="center" />
        </motion.div>
      )}

      {props.winner && <ResultNotification quizResult={props.quizResult} />}

      {!props.winner ? (
        props.turnOwner === props.role && <FloatingButton onClick={props.startQuestions}>Spielen</FloatingButton>
      ) : (
        <FloatingButton onClick={invitePlayer}>Nochmal</FloatingButton>
      )}
    </>
  )
}

export default QuizOverview
