import React from 'react'
import styled from 'styled-components'

const OfflineContainer = styled.div`
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
`

const Emoji = styled.div`
  font-size: 56px;
  margin-bottom: 32px;
`

function OfflineMessage() {
  return (
    <OfflineContainer>
      <Emoji><span role="img" aria-label="Trauriges Emoji">😢</span></Emoji>
      <h2>Uff, du bist offline!</h2>
      <p>Bist du im Chicago??</p>
    </OfflineContainer>
  )
}

export default OfflineMessage
