import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

const InputContainer = styled.div`
  position: relative;
`

const TextInput = styled.input`
  display: block;
  border: 0px solid #fff;
  border: 2px solid ${props => props.theme.primary.dark};
  background-color: ${props => props.theme.neutral.bright};
  outline: none;
  padding: ${props => (props.nature === 'small' ? '12px' : '16px')};
  font-size: 1rem;
  color: ${props => props.theme.primary.base};
  width: 100%;
  border-radius: 8px;
  transition: border 0.6s;
  font-weight: ${props => props.theme.fontWeight.regular};

  ::placeholder {
    color: ${props => props.theme.neutral.text};
  }

  &:focus {
    border: 2px solid ${props => props.theme.secondary.base};
  }

  &:disabled {
    border: 2px solid transparent;
    color: ${props => props.theme.primary.dark};

    &::placeholder {
      color: ${props => props.theme.primary.dark};
    }
  }
`

const Validator = styled.div`
  position: absolute;
  top: ${props => (props.nature === 'small' ? '14px' : '16px')};
  right: ${props => props.theme.space.m};
  color: ${props => props.theme.neutral.text};
  font-weight: ${props => props.theme.fontWeight.medium};
`

function Input(props) {
  const inputElement = useRef(null)

  useEffect(() => {
    if (inputElement.current && props.autoFocus) {
      inputElement.current.focus()
    }
  }, [props.autoFocus])

  function changeHandler(event) {
    event.target.value.length <= props.maxLength && props.emitValue(event.target.value)
  }

  return (
    <InputContainer>
      <TextInput
        type="text"
        placeholder={props.placeholder}
        value={props.value}
        onChange={event => changeHandler(event)}
        ref={inputElement}
        nature={props.nature}
      />
      {!props.hideCounter && <Validator nature={props.nature}>{props.maxLength - props.value.length}</Validator>}
    </InputContainer>
  )
}

export default Input
