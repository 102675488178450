import React, { useEffect } from 'react'
import { db } from '../config'

export function useQuizList(groupId, uid) {

  const [error] = React.useState(false)
  const [loadingChallengerQuizzes, setLoadingChallengerQuizzes] = React.useState(true)
  const [loadingOpponentQuizzes, setLoadingOpponentQuizzes] = React.useState(true)
  const [loadingQuizList, setLoadingQuizList] = React.useState(true)
  const [quizListLoaded, setQuizListLoaded] = React.useState(false)
  const [quizList] = React.useState([
    {
      id: 'quiz-test-id',
      challenger: 'E9U7GUuVVKZCsxgYVtsja00BrNk1',
      challengerName: 'Test 1',
      opponent: 's57VvRPqu8NZDbv2uSIb9VewXVh2',
      opponentName: 'Test 2',
      turnOwner: 'challenger',
    },
  ])
  const [challengerQuizList, setChallengerQuizList] = React.useState()
  const [opponentQuizList, setOpponentQuizList] = React.useState()

  useEffect(() => {
    let unsubscribe = () => {}

    groupId &&
      (unsubscribe = db
        .collection('groups')
        .doc(groupId)
        .collection('quizzes')
        .where('status', '==', 'open')
        .where('challenger', '==', uid)
        .onSnapshot(function(querySnapshot) {
          let challengerQuizzes = []

          querySnapshot.forEach(function(doc) {
            const data = doc.data()
            const quizItem = {
              id: doc.id,
              challenger: data.challenger,
              challengerName: data.challengerName,
              opponent: data.opponent,
              opponentName: data.opponentName,
              turnOwner: data.turnOwner,
            }

            challengerQuizzes.push(quizItem)

            // querySnapshot.docChanges().forEach(function(change) {
            //   console.log(change.doc.data())
            //   if (change.type === 'added') {
            //     const playerName = change.doc.data().challengerName
            //     const title = 'Du bist dran!'
            //     const message = change.doc.data().opponentName + ' hat gespielt!'

            //     triggerNotification(title, message)
            //   }
            // })
          })
          setChallengerQuizList(challengerQuizzes)
          setLoadingChallengerQuizzes(false)
        }))

    return () => unsubscribe()
  }, [groupId, uid])

  useEffect(() => {
    let unsubscribe = () => {}

    groupId &&
      (unsubscribe = db
        .collection('groups')
        .doc(groupId)
        .collection('quizzes')
        .where('status', '==', 'open')
        .where('opponent', '==', uid)
        .onSnapshot(function(querySnapshot) {
          let opponentQuizzes = []

          querySnapshot.forEach(function(doc) {
            const data = doc.data()
            const quizItem = {
              id: doc.id,
              challenger: data.challenger,
              challengerName: data.challengerName,
              opponent: data.opponent,
              opponentName: data.opponentName,
              turnOwner: data.turnOwner,
            }

            opponentQuizzes.push(quizItem)
          })
          setOpponentQuizList(opponentQuizzes)
          setLoadingOpponentQuizzes(false)
        }))

    return () => unsubscribe()
  }, [groupId, uid])

  useEffect(() => {
    setLoadingQuizList(false)
    setQuizListLoaded(true)
  }, [loadingChallengerQuizzes, loadingOpponentQuizzes])

  return {
    error,
    quizListLoaded,
    loadingQuizList,
    quizList,
    challengerQuizList,
    opponentQuizList,
  }
}
