import React, { useEffect } from 'react'
import { db } from '../config'

export function useQuestionBatches(group, quizId) {
  const [error] = React.useState(false)
  const [batchLoading, setBatchLoading] = React.useState(true)
  const [questionBatches, setQuestionBatches] = React.useState()

  useEffect(() => {
    const unsubscribe = db
      .collection('groups')
      .doc(group)
      .collection('quizzes')
      .doc(quizId)
      .collection('questions')
      .onSnapshot(function(querySnapshot) {
        let questionBatches = {
          batch_0: null,
          batch_1: null,
          batch_2: null,
          batch_3: null,
        }

        querySnapshot.forEach(function(doc) {
          const category = doc.data().category
          const index = doc.data().index
          const data = Object.values(doc.data())

          const newQuestion = (index, data) => {
            const questionObj = doc.data()['question_' + index]
            const object = {
              id: questionObj.id,
              author: questionObj.author,
              question: questionObj.question,
              type: questionObj.type,
              url: questionObj.url,
              imageName: questionObj.imageName,
              answers: [
                {label: "correctAnswer", value: questionObj.correctAnswer},
                {label: "alternativeAnswer_a", value: questionObj.alternativeAnswer_a},
                {label: "alternativeAnswer_b", value: questionObj.alternativeAnswer_b}, 
                {label: "alternativeAnswer_c", value: questionObj.alternativeAnswer_c}
              ],
            }
            return object
          }

          const newBatch = {
            index: index,
            category: category,
            questions: [newQuestion(0, data), newQuestion(1, data), newQuestion(2, data)],
          }

          switch (index) {
            case 0:
              questionBatches.batch_0 = newBatch
              break
            case 1:
              questionBatches.batch_1 = newBatch
              break
            case 2:
              questionBatches.batch_2 = newBatch
              break
            case 3:
              questionBatches.batch_3 = newBatch
              break
            default:
              break
          }
        })
        setQuestionBatches(questionBatches)
        setBatchLoading(false)
      })

    return () => unsubscribe()
  }, [])

  return { error, batchLoading, questionBatches }
}
