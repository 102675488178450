import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import styled from 'styled-components'
import { SEND_MESSAGE } from '../../../database/actions/chatActions'
import { FloatingButton } from '../buttons'
import { Textarea } from '../inputs'

const Layer = styled(motion.div)`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 12000;
`

const Position = styled.div`
  padding: ${props => props.theme.space.xl} ${props => props.theme.space.m} ${props => props.theme.space.m} ${props => props.theme.space.m};
`

const Container = styled(motion.div)`
  position: relative;
  padding: ${props => props.theme.space.m};
  color: ${props => props.theme.neutral.bright};
  font-size: ${props => props.theme.fontSize.root};
  line-height: ${props => props.theme.lineHeight.root};
`

const EditorWrapper = styled(motion.div)`
  position: relative;
  z-index: 1000;
`

const EditorBackground = styled(motion.div)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.primary.light};
  border-radius: ${props => props.theme.radius.m};
  box-shadow: ${props => props.theme.shadow.base};
  z-index: 0;
`

const layerTransition = {
  hidden: {
    opacity: 0,
    transition: {
      type: 'tween',
      duration: 0.3,
    },
  },
  visible: {
    opacity: 1,
  },
}

const wrapperTransition = {
  hidden: {
    opacity: 0,
    transition: {
      type: 'tween',
      duration: 0.3,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.2,
    },
  },
}

const containerTransition = {
  hidden: {
    scale: 0.5,
  },
  visible: {
    scale: 1,
    transition: {
      type: 'spring',
      damping: 18,
      mass: 1.5,
      stiffness: 240,
    },
  },
}

function MessageEditor(props) {
  const [message, setMessage] = useState('')

  const inputRef = React.createRef()

  function handleInput(value) {
    setMessage(value)
  }

  function sendMessage() {
    let sender = ''
    let receiver = ''
    
    if (props.role === 'challenger') {
      sender = props.challenger
      receiver = props.opponent
    } else {
      sender = props.opponent
      receiver = props.challenger
    }

    SEND_MESSAGE(props.userProfile.activeGroup, props.quizId, sender, receiver, message)
    setMessage('')
    props.close()
  }

  function closeEditor(event) {
    if(event.target.getAttribute('data-name') === 'layer' ) {
      setMessage('')
      props.close()
    }
  }

  return (
    <>
      <AnimatePresence>
        {props.show && (
          <Layer
            data-name="layer"
            variants={layerTransition}
            initial="hidden"
            animate="visible"
            exit="hidden"
            onClick={event => closeEditor(event)}
          >
            <Position>
              <Container>
                <EditorWrapper variants={wrapperTransition}>
                  <h3>Nachricht an {props.role === 'challenger' ? props.opponentName : props.challengerName}</h3>
                  <Textarea autoFocus placeholder="Deine Nachricht" value={message} emitValue={handleInput} maxLength="140" />
                </EditorWrapper>
                <EditorBackground variants={containerTransition} initial="hidden" animate={props.show ? 'visible' : 'hidden'} />
              </Container>
            </Position>
            <FloatingButton onClick={sendMessage}>Senden</FloatingButton>
          </Layer>
        )}
      </AnimatePresence>
    </>
  )
}

export default MessageEditor
