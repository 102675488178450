import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'
import { CHANGE_GROUP, DELETE_GROUP, LEAVE_GROUP } from '../../database/actions/groupActions'
import { useGroup } from '../../database/services'
import { useModal, useNotification } from '../../hooks'
import { GroupThumbnail, PlayerGallery } from '../components/groups'
import { ContentBox } from '../components/layout'

const viewTransition = {
  enter: { opacity: 1 },
  exit: { opacity: 0 },
}

const Container = styled(motion.div)`
  padding: 0px 0px ${props => props.theme.space.xxl} 0px;
  color: ${props => props.theme.neutral.bright};
`

const GroupDetails = styled.div`
  display: flex;
  align-items: center;
`

const GroupName = styled.div`
  margin: 0px 0px 0px ${props => props.theme.space.s};

  h3 {
    margin: 0px 0px ${props => props.theme.space.xxs} 0px;
  }
`

const GroupRole = styled.div`
  font-size: ${props => props.theme.fontSize.s};
  color: ${props => props.theme.primary.dark};
`

function GroupManager({ user, context, ...props }) {
  const { groupData } = useGroup(context.userProfile.activeGroup)
  const { showModal } = useModal()
  const { showNotification } = useNotification()

  const changeGroupThumbnail = () => {
    props.handleGroupThumbnailEditor()
  }

  const leaveGroup = () => {
    const modalText = 'Möchtest du wirklich die Gruppe ' + groupData.name + ' verlassen?'
    showModal('default', 'Bist du dir sicher?', modalText, 'Ja, austreten', { fn: confirmLeaving }, true, null)
    console.log('Gruppe verlassen')
  }

  const deleteGroup = () => {
    const modalText = 'Möchtest du wirklich die Gruppe ' + groupData.name + ' mit allen Fragen und Mitgliedern löschen?'
    showModal('confirmation', 'Willst du das wirklich tun?', modalText, 'Ja, löschen', { fn: confirmDeletion }, true, groupData.name)
    console.log('Gruppe verlassen')
  }

  const confirmLeaving = () => {
    const newGroup =
      context.userProfile.memberships[0] !== context.userProfile.activeGroup
        ? context.userProfile.memberships[0]
        : context.userProfile.memberships[1]
    context.showPreloader()
    CHANGE_GROUP(context.userProfile.uid, newGroup)
    LEAVE_GROUP(context.userProfile.activeGroup, context.userProfile.uid, showNotification)
  }

  const confirmDeletion = () => {
    const newGroup =
      context.userProfile.memberships[0] !== context.userProfile.activeGroup
        ? context.userProfile.memberships[0]
        : context.userProfile.memberships[1]
    context.showPreloader()
    CHANGE_GROUP(context.userProfile.uid, newGroup)
    !groupData.protected && DELETE_GROUP(context.userProfile.activeGroup, showNotification)
  }

  return (
    <Container initial="exit" animate="enter" exit="exit" variants={viewTransition}>
      <ContentBox actionFn={props.handleGroupEditor} actionLabel="Gruppe verwalten">
        {groupData && (
          <GroupDetails>
            <GroupThumbnail
              key={groupData.id}
              user={user}
              groupId={context.userProfile.activeGroup}
              status="active"
              hideIndicator
              thumbnailName={groupData.thumbnailName}
              action={changeGroupThumbnail}
            />
            <GroupName>
              <h3>{groupData.name}</h3>
              <GroupRole>Du hast Admin-Rechte</GroupRole>
            </GroupName>
          </GroupDetails>
        )}
      </ContentBox>
      {groupData && (
        <ContentBox
          actionFn={groupData.founder === context.userProfile.uid ? deleteGroup : leaveGroup}
          actionLabel={groupData.founder === context.userProfile.uid ? 'Gruppe löschen' : 'Aus der Gruppen austreten'}
        >
          <h3>{props.groupMemberProfiles.length} Mitglieder</h3>
          <PlayerGallery groupMemberProfiles={props.groupMemberProfiles} />
        </ContentBox>
      )}
    </Container>
  )
}

export default GroupManager
