import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as AllDoneIllustration } from '../../../assets/illustrations/all-done.svg'

const Container = styled(motion.div)`
  padding: ${props => props.theme.space.l} 0px ${props => props.theme.space.xl} 0px;

  svg {
    margin: 0px;
    width: 100%;
    height: auto;

    .cls-2,
    .cls-3 {
      fill: ${props => props.theme.primary.dark};
      opacity: 0.4;
    }

    .cls-4 {
      fill: ${props => props.theme.primary.dark};
    }

    .cls-5 {
      fill: ${props => props.theme.primary.text};
    }

    .cls-6 {
      fill: ${props => props.theme.primary.light};
    }

    .cls-7 {
      fill: ${props => props.theme.primary.dark};
      opacity: 0.4;
    }

    .cls-10 {
      fill: ${props => props.theme.secondary.base};
    }
  }
`

const TextContainer = styled.div`
  text-align: center;
  margin-top: ${props => props.theme.space.xl};
`

function EmptyState(props) {
  return (
    <Container initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <AllDoneIllustration />
      <TextContainer>
        <h3>Genug entspannt.</h3>
        <h2>Fordere jemanden heraus!</h2>
      </TextContainer>
    </Container>
  )
}

export default EmptyState
