import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FactStage } from '../components/atoms'
import { Column, Row } from '../components/grid'
import { ContentBox } from '../components/layout'
import { PoolVisualizer } from '../components/mainview'

const viewTransition = {
  enter: { opacity: 1 },
  exit: { opacity: 0 },
}

const Container = styled(motion.div)`
  padding: 0px 0px ${props => props.theme.space.xxl} 0px;
  color: ${props => props.theme.neutral.bright};
`

const Hr = styled.div`
  width: 1px;
  display: block;
  height: 44px;
  margin: 0px ${props => props.theme.space.m} 0px 0px;
  background: ${props => props.theme.primary.base};
`

function GroupManager({ user, context, ...props }) {
  const [questionNumber, setQuestionNumber] = useState(0)
  const [categoryNumber, setCategoryNumber] = useState(0)

  function countCategories(pool) {
    const categoryNumber = Object.keys(pool).length

    setCategoryNumber(categoryNumber)
  }

  function countQuestions(pool) {
    let questionNumber = 0

    Object.values(pool).map(category => {
      questionNumber = questionNumber + category.length
    })

    setQuestionNumber(questionNumber)
  }

  useEffect(() => {
    countCategories(context.questionPool)
    countQuestions(context.questionPool)
  }, [])

  const handleAction = () => {
    props.userQuestionNumber === 0 ? props.handleQuestionProducer() : props.handleQuestionDrawer()
  }

  return (
    <Container initial="exit" animate="enter" exit="exit" variants={viewTransition}>
      <ContentBox actionFn={handleAction} actionLabel={props.userQuestionNumber === 0 ? "Erstelle deine erste Frage" : "Deine Fragen verwalten"}>
        <h3>Quizpool</h3>
        <PoolVisualizer user={user} pool={context.questionPool} handleQuestionDrawer={props.handleQuestionDrawer} />
        <Row justify="flex-start">
          <Column dimension={4}>
            <FactStage value={questionNumber} label="Fragen" />
          </Column>
          <Column dimension={4}>
            <FactStage value={categoryNumber} label="Rubriken" />
          </Column>
          <Hr />
          <Column dimension={4}>
            <FactStage
              value={props.userQuestionNumber === 0 ? 0 : Math.round((props.userQuestionNumber / questionNumber) * 100)}
              suffix="%"
              label="Dein Beitrag"
            />
          </Column>
        </Row>
      </ContentBox>
    </Container>
  )
}

export default GroupManager
