import { AnimatePresence, motion } from 'framer-motion'
import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Context } from '../../context/ContextProvider'
import { ADD_CATEGORY, SAVE_QUESTION } from '../../database/actions/poolActions'
import { useCategories } from '../../database/services'
import { useNotification } from '../../hooks'
import { CloseButton } from '../components/buttons'
import { TabSwitch } from '../components/controls'
import { MultiStepDialog } from '../components/dialog'
import { Dropdown, ImageUploader, Textarea } from '../components/inputs'
import { Layer } from '../components/layout'

const Header = styled.div`
  position: relative;
  padding: ${props => props.theme.space.sm};
  color: ${props => props.theme.neutral.bright};
  font-weight: ${props => props.theme.fontWeight.medium};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CategoryContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

function QuestionProducer({ user, ...props }) {
  const context = useContext(Context)

  const { showNotification } = useNotification()

  const producerRef = useRef(null)

  const [dialogIndex, setDialogIndex] = useState()
  const [triggerStepForwards, setTriggerStepForwards] = useState(false)

  const [group, setGroup] = useState()
  const [type, setType] = useState('text')
  const [valide, setValide] = useState(false)
  const [newQuestion, setNewQuestion] = useState()
  const [correctAnswer, setCorrectAnswer] = useState()
  const [alternativeAnswer_a, setAlternativeAnswer_a] = useState()
  const [alternativeAnswer_b, setAlternativeAnswer_b] = useState()
  const [alternativeAnswer_c, setAlternativeAnswer_c] = useState()
  const [newCategory, setNewCategory] = useState(false)
  const [category, setCategory] = useState()
  const [imageUpload, setImageUpload] = useState(false)
  const [imageName, setImageName] = useState('')
  const [selectedImage, setSelectedImage] = useState('')
  const [storageURL, setStorageURL] = useState('')
  const [complete, setComplete] = useState(false)

  const { categories } = useCategories(group)

  useEffect(() => {
    if (context) {
      setGroup(context.userProfile.activeGroup)
    }
  }, [context])

  function handleCategory(value) {
    if (value !== 'Kategorie wählen') {
      setCategory(value)
    }
  }

  useEffect(() => {
    validator(dialogIndex)
  }, [dialogIndex, newQuestion, correctAnswer, alternativeAnswer_a, alternativeAnswer_b, alternativeAnswer_c, category])

  const getDialogIndex = index => {
    setDialogIndex(index)
  }

  function emitSelectedImage(url) {
    console.log(url)
    setSelectedImage(url)
  }

  const emitImageName = name => {
    setImageName(name)
  }

  function triggerUpload() {
    setImageUpload(true)
  }

  const emitStorageURL = url => {
    setType('image')
    setStorageURL(url)
  }

  function validator(index) {
    const validatorValues = [
      newQuestion,
      correctAnswer,
      alternativeAnswer_a,
      alternativeAnswer_b,
      alternativeAnswer_c,
      category,
      selectedImage,
    ]
    if (validatorValues[index]) {
      if (validatorValues[index].length > 0) {
        setValide(true)
      } else {
        setValide(false)
      }
    } else if (index === 6) {
      setValide(true)
    } else {
      setValide(false)
    }
  }

  function completeDialog() {
    selectedImage ? triggerUpload() : saveQuestion()
  }

  useEffect(() => {
    storageURL && saveQuestion()
  }, [storageURL])

  function saveQuestion() {
    newCategory && ADD_CATEGORY(context.userProfile.activeGroup, context.userProfile.uid, category)

    SAVE_QUESTION(
      context.userProfile.activeGroup,
      context.userProfile.uid,
      category,
      type,
      newQuestion,
      correctAnswer,
      alternativeAnswer_a,
      alternativeAnswer_b,
      alternativeAnswer_c,
      imageName,
      storageURL
    )
    props.closeQuestionProducer()
    showNotification('positive', 'Mega! Deine Frage wurde hinzugefügt.', 'Sie kann unmittelbar in einem Quiz auftauchen. Also sei wachsam!')
  }

  function moveForwardsOnKeypress(e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      valide && setTriggerStepForwards(true)
    }
  }

  function cancelTrigger() {
    setTriggerStepForwards(false)
  }

  function handleCategorySwitcher(index) {
    setCategory('')
    index === 1 ? setNewCategory(true) : setNewCategory(false)
  }

  return (
    <Layer>
      <Header>
        Neue Frage erstellen
        <CloseButton closeFunction={props.closeQuestionProducer} />
      </Header>
      <MultiStepDialog
        transferIndex={getDialogIndex}
        customAction={[6, triggerUpload]}
        validator={validator}
        valide={valide}
        finalFn={completeDialog}
        finalLabel="Frage erstellen"
        triggerStepForwards={triggerStepForwards}
        cancelTrigger={cancelTrigger}
      >
        <>
          <h3>Deine Frage</h3>
          <Textarea
            nature="hero"
            autoFocus={dialogIndex === 0 ? true : false}
            placeholder="Wie lautet deine Frage?"
            value={newQuestion}
            emitValue={setNewQuestion}
            maxLength="140"
            onKeyPress={moveForwardsOnKeypress}
          />
        </>
        <>
          <h3>Die richtige Antwort</h3>
          <Textarea
            nature="hero"
            autoFocus={dialogIndex === 1 ? true : false}
            key="correctAnswer"
            placeholder="Korrekte Antwort"
            value={correctAnswer}
            emitValue={setCorrectAnswer}
            maxLength="40"
            onKeyPress={moveForwardsOnKeypress}
          />
        </>
        <>
          <h3>1. Alternative Antwort</h3>
          <Textarea
            nature="hero"
            autoFocus={dialogIndex === 2 ? true : false}
            key="fakeAnswer-1"
            placeholder="Erste Lüge"
            value={alternativeAnswer_a}
            emitValue={setAlternativeAnswer_a}
            maxLength="40"
            onKeyPress={moveForwardsOnKeypress}
          />
        </>
        <>
          <h3>2. Alternative Antwort</h3>
          <Textarea
            nature="hero"
            autoFocus={dialogIndex === 3 ? true : false}
            key="fakeAnswer-2"
            placeholder="Zweite Finte "
            value={alternativeAnswer_b}
            emitValue={setAlternativeAnswer_b}
            maxLength="40"
            onKeyPress={moveForwardsOnKeypress}
          />
        </>
        <>
          <h3>3. Alternative Antwort</h3>
          <Textarea
            nature="hero"
            autoFocus={dialogIndex === 4 ? true : false}
            key="fakeAnswer-3"
            placeholder="Dritte Falle"
            value={alternativeAnswer_c}
            emitValue={setAlternativeAnswer_c}
            maxLength="40"
            onKeyPress={moveForwardsOnKeypress}
          />
        </>
        <>
          <h3>Wähle eine Kategorie</h3>
          <AnimatePresence exitBeforeEnter>
            {!newCategory ? (
              <CategoryContainer key="existingCategory" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <Dropdown
                  nature="hero"
                  key="category"
                  placeholder="Kategorie wählen"
                  value={category}
                  emitValue={handleCategory}
                  options={categories}
                />
              </CategoryContainer>
            ) : (
              <CategoryContainer key="newCategory" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <Textarea
                  nature="hero"
                  autoFocus={dialogIndex === 5 ? true : false}
                  key="fakeAnswer-3"
                  placeholder="Neue Kategorie"
                  value={category}
                  emitValue={setCategory}
                  maxLength="40"
                  onKeyPress={moveForwardsOnKeypress}
                />
              </CategoryContainer>
            )}
          </AnimatePresence>
          <TabSwitch action={handleCategorySwitcher} labels={['auswählen', 'neu erstellen']} />
        </>
        <>
          <h3>Bild hochladen (optional)</h3>
          <ImageUploader
            group={group}
            triggerUpload={imageUpload}
            emitImageName={emitImageName}
            emitStorageURL={emitStorageURL}
            emitSelectedImage={emitSelectedImage}
            subfolder="question-images"
          />
        </>
      </MultiStepDialog>
    </Layer>
  )
}

export default QuestionProducer
