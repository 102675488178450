import { db } from '../config'

export const CREATE_GROUP = (
  author,
  groupId,
  groupName,
  themeHSL,
  themeHEX,
  thumbnailName,
  successCallback,
  notificationCallback
) => {
  const timestamp = new Date()
  const thumbnailURL = 'gs://friendsquiz-2020.appspot.com/groups/' + groupId + '/' + thumbnailName + '_1024x1024'

  db.collection('groups')
    .doc(groupId)
    .set({
      founder: author,
      timestamp: timestamp,
      name: groupName,
      customThemeColors: themeHEX,
      customThemeHEX: themeHEX,
      customThemeHSL: themeHSL,
      thumbnailName: thumbnailName,
      thumbnailURL: thumbnailURL,
      source: 'database',
      lastUpdate: timestamp,
    })
    .then(function() {
      console.info('Die neue Gruppe wurde gespeichert.')
      notificationCallback('positive', 'Gruppe erfolgreich erstellt!', 'Lade neue Mitglieder ein und erwecke die Gruppe zum Leben.')
    })
    .catch(function(error) {
      console.error('Die neue Gruppe konnte nicht gespeichert werden: ', error)
    })

  db.collection('users')
    .doc(author)
    .collection('memberships')
    .doc(groupId)
    .set({
      timestamp: timestamp,
    })
    .then(function() {
      console.info('Der Gründer wurde der Gruppe hinzugefügt.')
    })
    .catch(function(error) {
      console.error('Der Gründer konnte der Gruppe nicht hinzugefügt werden: ', error)
    })

  db.collection('users')
    .doc(author)
    .update({
      activeGroup: groupId,
    })
    .then(function() {
      console.info('Die neue Gruppe wurde auf aktiv gesetzt.')
      successCallback()
    })
    .catch(function(error) {
      console.error('Die neue Gruppe konnte nicht auf aktiv gesetzt werden: ', error)
    })
}

export const CHANGE_GROUPTHUMBNAIL = (groupId, thumbnailName) => {
  const timestamp = new Date()
  const thumbnailURL = 'gs://friendsquiz-2020.appspot.com/groups/' + groupId + '/' + thumbnailName + '_1024x1024'

  db.collection('groups')
    .doc(groupId)
    .update({
      thumbnailURL: thumbnailURL,
      thumbnailName: thumbnailName,
      lastUpdate: timestamp,
    })
    .then(function() {
      console.info('Das Gruppenbild wurde aktualisiert.')
    })
    .catch(function(error) {
      console.error('Das Gruppenbild konnte nicht aktualisiert werden: ', error)
    })
}

export const CHANGE_GROUPDATA = (groupId, newGroupName, newCustomThemeColors, newCustomThemeHSL, newCustomThemeHEX) => {
  db.collection('groups')
    .doc(groupId)
    .update({
      name: newGroupName,
      customThemeColors: newCustomThemeColors,
      customThemeHSL: newCustomThemeHSL,
      customThemeHEX: newCustomThemeHEX,
    })
    .then(function() {
      console.info('Die Gruppen-Einstellungen wurden gespeichert.')
    })
    .catch(function(error) {
      console.error('Die Gruppen-Einstellungen konnten nicht gespeichert werden: ', error)
    })
}

export const JOIN_GROUP = (groupId, userId) => {
  
  const userMembershipsRef = db
    .collection('users')
    .doc(userId)
    .collection('memberships')
    .doc(groupId)

  userMembershipsRef
    .get()
    .then(function(doc) {
      if (doc.exists) {
        CHANGE_GROUP(userId, groupId)
      } else {
        joinGroup()
      }
    })
    .catch(function(error) {
      console.log('Du konntest der Gruppe nicht beitreten:', error)
    })

  const joinGroup = () => {
    const timestamp = new Date()

    db.collection('users')
      .doc(userId)
      .collection('memberships')
      .doc(groupId)
      .set({
        groupID: groupId,
        timestamp: timestamp,
      })
      .then(function() {
        CHANGE_GROUP(userId, groupId)
        console.info('Du bist erfolgreich der Gruppe beigetreten.')
      })
      .catch(function(error) {
        console.error('Du konntest der Gruppe leider nicht beitreten.: ', error)
      })
  }
}

export const CHANGE_GROUP = (userId, newGroup) => {
  db.collection('users')
    .doc(userId)
    .update({
      activeGroup: newGroup,
    })
    .then(function() {
      console.info('Die Gruppe wurde erfolgreich gewechselt.')
    })
    .catch(function(error) {
      console.error('Die Gruppe konnte nicht gewechselt werden: ', error)
    })
}

export const LEAVE_GROUP = (groupId, userId, notificationCallback) => {
  db.collection('users')
    .doc(userId)
    .collection('memberships')
    .doc(groupId)
    .delete()
    .then(function() {
      console.info('Du bist aus der Gruppe ausgetreten.')
      notificationCallback('positive', 'Alles klar!', 'Du bist aus der Gruppe ausgetreten.')
    })
    .catch(function(error) {
      console.error('Das Austreten aus der Gruppe hat leider nicht geklappt.: ', error)
    })
}

export const DELETE_GROUP = (groupId, notificationCallback) => {
  db.collection('groups')
    .doc(groupId)
    .delete()
    .then(function() {
      console.info('Die Gruppe wurde erfolgreich gelöscht.')
      notificationCallback('positive', 'Es ist geschehen 🥺', 'Die Gruppe wurde erfolgreich gelöscht')
    })
    .catch(function(error) {
      console.error('Die Gruppe konnte nicht gelöscht werden: ', error)
    })
}
