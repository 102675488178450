import firebase from 'firebase/app'
import { db } from '../config'

export const SAVE_QUESTION = (
  groupId,
  userId,
  category,
  type,
  question,
  correctAnswer,
  alternativeAnswer_a,
  alternativeAnswer_b,
  alternativeAnswer_c,
  imageName,
  storageUrl
) => {
  const questionId = userId + new Date().getUTCMilliseconds()
  const timestamp = new Date()

  db.collection('groups')
    .doc(groupId)
    .collection('questionPool')
    .doc(questionId)
    .set({
      timestamp: timestamp,
      id: questionId,
      author: userId,
      category: category,
      type: type,
      question: question,
      correctAnswer: correctAnswer,
      alternativeAnswer_a: alternativeAnswer_a,
      alternativeAnswer_b: alternativeAnswer_b,
      alternativeAnswer_c: alternativeAnswer_c,
      imageName: imageName,
      url: storageUrl,
    })
    .then(function() {
      console.info('Die neue Frage wurde gespeichert.')
    })
    .catch(function(error) {
      console.error('Die neue Frage konnte nicht gespeichert werden: ', error)
    })
}

export const ADD_CATEGORY = (groupId, userId, category) => {
  const timestamp = new Date()

  db.collection('groups')
    .doc(groupId)
    .collection('categories')
    .doc(category)
    .set({
      timestamp: timestamp,
      author: userId,
    })
    .then(function() {
      console.info('Die neue Kategorie wurde erstellt.')
    })
    .catch(function(error) {
      console.error('Die neue Kategorie konnte nicht erstellt werden: ', error)
    })
}

export const DELETE_QUESTION = (groupId, questionId, notificationCallback) => {
  db.collection('groups')
    .doc(groupId)
    .collection('questionPool')
    .doc(questionId)
    .delete()
    .then(function() {
      console.log('Die Frage wurde erfolgreich gelöscht.')
      notificationCallback('positive', 'Erfolgreich gelöscht', 'Die Frage taucht in Folgenden Quizrunden nicht mehr auf.')
    })
    .catch(function(error) {
      console.error('Die Frage konnte nicht gelöscht werden.', error)
    })
}

export const ADD_QUESTIONVIEW = (groupId, questionId, userId) => {
  const timestamp = new Date()

  const questionRef = db
    .collection('groups')
    .doc(groupId)
    .collection('questionPool')
    .doc(questionId)
    .collection('views')
    .doc(userId)

  questionRef
    .get()
    .then(function(doc) {
      if (doc.exists) {
        updateViews()
      } else {
        createViewDoc()
      }
    })
    .catch(function(error) {
      console.log('Die Frage konnte nicht abgerufen werden:', error)
    })

  const createViewDoc = () => {
      questionRef
      .set({
        views: 1,
        lastView: timestamp,
      })
      .then(function() {
        console.info('Dein erster Fragenaufruf wurde gespeichert.')
      })
      .catch(function(error) {
        console.error('Dein Fragenaufruf konnte nicht gespeichert werden: ', error)
      })
    }

  const updateViews = () => {
    questionRef
    .update({
      views: firebase.firestore.FieldValue.increment(1),
      lastView: timestamp,
    })
    .then(function() {
      console.info('Dein Fragenaufruf wurde gespeichert.')
    })
    .catch(function(error) {
      console.error('Dein Fragenaufruf konnte nicht gespeichert werden: ', error)
    })
  }
}
