import { css } from 'styled-components';

export const breakpoints = {
	xs: '400',
	s: '720px',
	m: '980px',
  l: '1280px',
  xl: '1680px'
};

export const media = Object.keys(breakpoints).reduce((breakpoint, label) => {
	breakpoint[label] = (...args) => css`
		@media (min-width: ${breakpoints[label]}) {
			${css(...args)};
		}
	`;
	return breakpoint;
}, {});