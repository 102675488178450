import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: calc(100vh + 80px);
  z-index: 9000;
`

const Emoji = styled(motion.div)``

const EmojiWrapper = styled.div`
  position: absolute;
`

function EmojiConfetti(props) {
  const [emojiChar, setEmojiChar] = useState()
  const [emojiConfettiArray, setEmojiConfettiArray] = useState([])
  const [showConfetti, setShowConfetti] = useState(false)

  const randomValue = (min, max) => {
    const value = Math.floor(Math.random() * (max - min + 1)) + min
    return value
  }

  const createEmojiConfetti = () => {
    let index
    let emojiConfetti = []

    for (index = 0; index < props.density; index++) {
      emojiConfetti.push({
        key: index,
        emojiDec: props.emoji,
        fontSize: randomValue(12, 40),
        positionX: randomValue(10, 90) + '%',
        positionY: randomValue(10, 90) + '%',
        rotation: randomValue(-8, 8),
      })
    }

    setEmojiConfettiArray(emojiConfetti)

    console.log(emojiConfetti)
  }

  const handleConfetti = () => {
    setShowConfetti(false)

    let char

    switch (props.emoji) {
      case "128514":
        console.log('😂')
        char = '😂'
        break
      case "129327":
          char = '🤯'
          break
      case "128525":
        char = '😍'
        break
      case "128553":
        char = '😩'
        break
      case "129488":
        char = '🧐'
        break
      default:
        console.log('Es wurde kein passendes Emoji gefunden.')
    }

    setEmojiChar(char)
    createEmojiConfetti()
  }

  useEffect(() => {
    handleConfetti()
  }, [])

  useEffect(() => {
    handleConfetti()
  }, [props.emoji])

  useEffect(() => {
    emojiChar && createEmojiConfetti()
  }, [emojiChar])

  useEffect(() => {
    emojiConfettiArray && setShowConfetti(true)
  }, [emojiConfettiArray])

  const containerVariants = {
    visible: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
  }

  const emojiVariants = {
    visible: i => ({
      opacity: 1,
      y: 0,
      transition: {
        opacity: {
          type: 'tween',
          duration: 0.5,
        },
        y: {
          type: 'tween',
          duration: 2,
        },
        duration: 2,
        delay: i * 0.1,
      },
    }),
    initial: {
      opacity: 0,
      y: '100vh',
    },
    exit: {
      opacity: 0,
    },
  }

  return (
    <AnimatePresence exitBeforeEnter>
      {emojiConfettiArray && showConfetti && (
        <Container key={emojiConfettiArray[0].positionX} initial="hidden" animate="visible" exit="hidden" variants={containerVariants}>
          {emojiConfettiArray.map((emoji, index) => (
            <EmojiWrapper
              style={{
                fontSize: emoji.fontSize,
                left: emoji.positionX,
                top: 0,
                transform: 'rotate(' + emoji.rotation + 'deg)',
              }}
            >
              <Emoji key={emoji.key} variants={emojiVariants} custom={index} initial="initial" animate="visible" exit="exit">
                {emojiChar}
              </Emoji>
            </EmojiWrapper>
          ))}
        </Container>
      )}
    </AnimatePresence>
  )
}

export default EmojiConfetti
