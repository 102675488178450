import React, { useEffect, useState } from 'react'
import { CHANGE_GROUP, JOIN_GROUP } from '../database/actions/groupActions'
import { useGroup, useGroupMemberProfiles, useGroupMembers, useProfile, useQuestionpool } from '../database/services'

export const Context = React.createContext()
export const Provider = Context.Provider
export const Consumer = Context.Consumer

export function ContextProvider(props) {
  const [loading, setLoading] = useState(true)
  const [questionPool, setQuestionPool] = useState()
  const [headerVisible, setHeaderVisible] = useState(false)
  const [headerArrow, setHeaderArrow] = useState(false)
  const [userProfile, setUserProfile] = useState()
  const [activeGroupID, setActiveGroupID] = useState()
  const [activeGroup, setActiveGroup] = useState()
  const [blurMode, setBlurMode] = useState(false)
  const [notification, setNotification] = useState({})
  const [pendingInvite, setPendingInvite] = useState(null)

  const { profile } = useProfile(props.uid)
  const { groupData } = useGroup(activeGroupID)
  const { databaseQuestionPool } = useQuestionpool(activeGroupID)

  const { groupMembers } = useGroupMembers(activeGroupID, props.uid)
  const { groupMemberProfiles } = useGroupMemberProfiles(groupMembers)

  useEffect(() => {
    console.log(profile)
    profile && setUserProfile(profile)
  }, [profile])

  useEffect(() => {
    console.log(props)
    console.log(activeGroup)
  })

  useEffect(() => {
    const groupParameter = new URL(window.location.href).searchParams.get('invite')

    groupParameter && setPendingInvite(groupParameter)

    if (userProfile) {
      if (pendingInvite && userProfile.memberships.includes(pendingInvite)) {
        CHANGE_GROUP(userProfile.uid, pendingInvite)
        setActiveGroupID(pendingInvite)
        //window.history.pushState({}, 'Title', '/' + window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split('?')[0])
      } else if (pendingInvite) {
        JOIN_GROUP(pendingInvite, userProfile.uid)
        setActiveGroupID(groupParameter)
        //window.history.pushState({}, 'Title', '/' + window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split('?')[0])
      } else {
        setActiveGroupID(userProfile.activeGroup)
      }

      setPendingInvite(null)
    }
  }, [userProfile])

  useEffect(() => {
    groupData && setActiveGroup(groupData)
  }, [groupData])

  useEffect(() => {
    databaseQuestionPool && setQuestionPool(databaseQuestionPool)
  }, [databaseQuestionPool])

  useEffect(() => {
    questionPool && setLoading(false)
  }, [questionPool])

  return (
    <Provider
      value={{
        loading,
        pendingInvite,
        questionPool,
        updateQuestionPool: questionPool => {
          setQuestionPool(questionPool)
        },
        headerVisible,
        updateHeaderVisibility: visiblity => {
          setHeaderVisible(visiblity)
        },
        headerArrow,
        updateHeaderArrow: state => {
          setHeaderArrow(state)
        },
        userProfile,
        updateUserProfile: profil => {
          setUserProfile(profil)
        },
        activeGroupID,
        activeGroup,
        updateActiveGroup: group => {
          setActiveGroup(group)
        },
        groupMembers,
        groupMemberProfiles,
        updateGroupThumbnail: newThumbnailURL => {
          setActiveGroup({ ...activeGroup, thumbnail: newThumbnailURL })
        },
        blurMode,
        updateBlurMode: state => {
          setBlurMode(state)
        },
        notification,
        showNotification: (visibility, nature, title, text) => {
          setNotification({ visible: visibility, nature: nature, title: title, text: text })
        },
        showPreloader: () => {
          setLoading(true)
        },
        hidePreloader: () => {
          setLoading(false)
        },
      }}
    >
      {props.children}
    </Provider>
  )
}

export default ContextProvider
