import React, { useEffect } from 'react'
import { db } from '../config'

export function useGroupMemberProfiles(userIdArray) {
  const [groupMemberProfiles, setGroupMemberProfiles] = React.useState()

  useEffect(() => {
    let userProfilList = []

    userIdArray &&
      userIdArray.map(user => {

        db.collection('users')
          .doc(user.userId)
          .get()
          .then(function(doc) {
            if (doc.exists) {
              const data = doc.data()
              const profile = {
                id: user.userId,
                username: data.username,
                slogan: data.slogan,
                photoURL: data.photoURL,
              }
              userProfilList.push(profile)
            }
          })
          .catch(function(error) {
            console.log('Die Spielerprofile konnten nicht geladen werden:', error)
          })
      })

    setGroupMemberProfiles(userProfilList)
  }, [userIdArray])

  return {
    groupMemberProfiles,
  }
}
