import { motion, useAnimation } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const AnswerPanel = styled(motion.button)`
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 100%;
  text-align: left;
  font-weight: ${props => props.theme.fontWeight.bold};
  padding: ${props => props.theme.space.sm} ${props => props.theme.space.m};
  color: ${props =>
    (props.status === 'correct' && props.theme.positive.base) ||
    (props.status === 'false' && props.theme.tertiary.base) ||
    (props.status === 'reveal' && props.theme.positive.base) ||
    (props.evaluation && !props.select && props.theme.primary.text) ||
    props.theme.secondary.base};
  border-radius: ${props => props.theme.radius.m};
  box-shadow: ${props => props.theme.shadow.light};
  margin-bottom: ${props => props.theme.space.xs};
  border: 0px;
  /* border: ${props => (props.quizmateChoice ? '2px solid' : '0px solid')} ${props =>
  props.status === 'correct' ? props.theme.positive.base : props.theme.tertiary.base}; */
  background: ${props =>
    (props.status === 'reveal' && props.theme.primary.light) ||
    (props.evaluation && !props.select && props.theme.primary.light) ||
    props.theme.neutral.bright};
  transition: background-color 0.3s;
  outline: none;
  z-index: ${props => props.status === 'correct' && 1000};
`

const QuizmateLabel = styled.div`
  position: absolute;
  top: 0px;
  right: ${props => props.theme.space.s};
  padding: ${props => props.theme.space.xxs} ${props => props.theme.space.xs};
  font-size: ${props => props.theme.fontSize.xs};
  background: ${props =>
    (props.status === 'correct' && props.theme.positive.base) ||
    (props.status === 'false' && props.theme.tertiary.base) ||
    (props.status === 'reveal' && props.theme.positive.base) ||
    (props.evaluation && !props.select && props.theme.primary.text) ||
    props.theme.secondary.base};
  border-radius: 0px 0px 4px 4px;
  color: ${props => props.theme.neutral.bright};
`

function Answer(props) {
  const [status, setStatus] = useState()
  const [select, setSelect] = useState(false)
  const [quizmateChoice, setQuizmateChoice] = useState(false)

  const feedback = useAnimation()

  function emitUserInput() {
    props.emitUserInput(props.answerId, props.label)
    !props.evaluation && setSelect(true)
  }

  useEffect(() => {
    function revealStatus() {
      if (select === true && props.answerId === props.solutionId) {
        setStatus('correct')
      } else if (select === true && props.answerId !== props.solutionId) {
        setStatus('false')
      } else if (select === false && props.answerId === props.solutionId) {
        setStatus('reveal')
      }
    }

    props.evaluation && revealStatus()

    props.evaluation && setQuizmateChoice(props.quizmateResult.answer === props.label ? true : false)
  }, [props.evaluation])

  useEffect(() => {
    switch (status) {
      case 'false':
        feedback.start({
          x: [3, -3, 3, -3, 3, -3, 0],
          rotate: [2, -2, 2, -2, 2, -2, 0],
          transition: {
            duration: 0.8,
            ease: 'easeInOut',
          },
        })
        break
      case 'correct':
        feedback.start({
          scale: [1, 0.96, 1.04, 1],
          transition: {
            duration: 0.5,
            ease: 'easeInOut',
          },
        })
        break
      default:
        break
    }
  }, [status])

  return (
    <AnswerPanel
      animate={feedback}
      select={select}
      status={status}
      evaluation={props.evaluation}
      onClick={emitUserInput}
      quizmateChoice={quizmateChoice}
    >
      {props.text}
      {quizmateChoice && (
        <QuizmateLabel evaluation={props.evaluation} select={select} status={status}>
          {props.quizmateName}
        </QuizmateLabel>
      )}
    </AnswerPanel>
  )
}

export default Answer
