import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as IconAddQuestion } from '../../../assets/icons/add-question.svg'
import { ReactComponent as IconCatalog } from '../../../assets/icons/catalog.svg'
import { ReactComponent as IconGames } from '../../../assets/icons/games.svg'
import { ReactComponent as IconGroup } from '../../../assets/icons/group.svg'
import { ReactComponent as IconLaunch } from '../../../assets/icons/launch.svg'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg'
import { ReactComponent as IconSettings } from '../../../assets/icons/settings.svg'
import { ReactComponent as IconUser } from '../../../assets/icons/user.svg'

const Container = styled(motion.nav)`
  position: fixed;
  bottom: 0px;
  left: 0%;
  width: 100%;
  z-index: 8000;
`

const InnerContainer = styled.div`
  position: relative;
  margin: 0px auto;
  background-color: ${props => props.theme.primary.light};
  border-radius: 16px 16px 0px 0px;
  box-shadow: ${props => props.theme.shadow.topHeavyLight};
  padding: ${props => props.theme.space.xs};
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const Tab = styled(motion.div)`
  width: ${props => (props.expand ? '88px' : '72px')};
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  -webkit-user-select: none;

  svg {
    fill: ${props => (props.activeTab ? props.theme.secondary.base : props.theme.neutral.bright)};
    height: 24px;
    width: auto;
    transition: fill 0.6s;
  }
`

const TabLabel = styled.span`
  color: ${props => (props.activeTab ? props.theme.secondary.base : props.theme.neutral.bright)};
  font-size: ${props => props.theme.fontSize.xxs};
  margin-top: ${props => props.theme.space.xxs};
  font-weight: ${props => props.theme.fontWeight.medium};
  transition: color 0.6s;
`

const AddButton = styled(motion.button)`
  position: relative;
  width: 56px;
  height: 56px;
  display: inline-flex;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.tertiary.base};
  border-radius: 32px;
  color: ${props => props.theme.neutral.bright};
  box-shadow: ${props => props.theme.shadow.base};
  font-size: ${props => props.theme.fontSize.root};
  outline: none;
  border: none;
  margin: 0px auto;

  svg {
    fill: ${props => props.theme.neutral.bright};
    width: 16px;
    height: 16px;
    transform: ${props => (props.active ? 'rotate(45deg)' : 'rotate(0deg)')};
    transition: transform 0.6s;
  }
`

const ActionButton = styled(motion.button)`
  position: relative;
  width: 56px;
  height: 56px;
  display: inline-flex;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.neutral.bright};
  border-radius: 32px;
  color: ${props => props.theme.neutral.bright};
  box-shadow: ${props => props.theme.shadow.base};
  font-size: ${props => props.theme.fontSize.root};
  outline: none;
  border: none;
  margin: 0px auto;

  svg {
    fill: ${props => props.theme.tertiary.base};
    width: 24px;
    height: 24px;
  }
`

const ActionSelector = styled(motion.div)`
  position: fixed;
  bottom: 56px;
  left: 0px;
  width: 100%;
  height: 75vh;
  z-index: 7000;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1000;
    bottom: 0px;
    left: 0px;
    position: absolute;
    background: ${props => props.theme.primary.dark};
    background: ${props => 'linear-gradient(0deg, ' + props.theme.primary.dark + ' 0%, rgba(0, 0, 0, 0) 100%);'};
    opacity: 0.8;
  }
`

const ActionContainer = styled.div`
  position: absolute;
  bottom: 56px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 0px ${props => props.theme.space.s};
  align-items: flex-end;
`

const Action = styled(motion.div)`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100px;

  &:first-child {
  }

  &:nth-child(2) {
    margin-bottom: 28px;
  }
`

const ActionLabel = styled.span`
  color: ${props => (props.activeTab ? props.theme.secondary.base : props.theme.neutral.bright)};
  font-size: ${props => props.theme.fontSize.xs};
  margin-top: ${props => props.theme.space.xs};
  font-weight: ${props => props.theme.fontWeight.medium};
  transition: color 0.3s;
`

function TabBar(props) {
  const [activeTab, setActiveTab] = useState('home')
  const [showActionSelector, setShowActionSelector] = useState(false)

  useEffect(() => {
    switch (props.sliderIndex) {
      case 0:
        setActiveTab('home')
        break
      case 1:
        setActiveTab('catalog')
        break
      case 2:
        setActiveTab('community')
        break
      case 3:
        setActiveTab('profil')
        break
    }
  }, [props.sliderIndex])

  function triggerAction(index) {
    props.actions[index]()
    setShowActionSelector(false)
  }

  return (
    <>
      <Container initial={{ y: 80 }} animate={{ y: 0 }} exit={{ y: 80 }}>
        <InnerContainer>
          <Tab onClick={() => props.changeSliderIndex(0)} activeTab={activeTab === 'home' ? true : false} whileTap={{ scale: 0.9 }}>
            <IconGames />
            <TabLabel activeTab={activeTab === 'home' ? true : false}>Quizzen</TabLabel>
          </Tab>
          <Tab onClick={() => props.changeSliderIndex(1)} activeTab={activeTab === 'catalog' ? true : false} whileTap={{ scale: 0.9 }}>
            <IconCatalog />
            <TabLabel activeTab={activeTab === 'catalog' ? true : false}>Fragen</TabLabel>
          </Tab>
          <Tab expand>
            <AddButton
              active={showActionSelector}
              onClick={() => setShowActionSelector(!showActionSelector)}
              whileTap={{ scale: 0.9 }}
              animate={{ y: showActionSelector ? '-32px' : '0px' }}
              transition={{
                y: { type: 'spring', stiffness: 100, damping: 10, mass: 0.2 },
              }}
            >
              <PlusIcon />
            </AddButton>
          </Tab>
          <Tab onClick={() => props.changeSliderIndex(2)} activeTab={activeTab === 'community' ? true : false} whileTap={{ scale: 0.9 }}>
            <IconGroup />
            <TabLabel activeTab={activeTab === 'community' ? true : false}>Community</TabLabel>
          </Tab>
          <Tab onClick={() => props.changeSliderIndex(3)} activeTab={activeTab === 'profil' ? true : false} whileTap={{ scale: 0.9 }}>
            <IconSettings />
            <TabLabel activeTab={activeTab === 'profil' ? true : false}>Profil</TabLabel>
          </Tab>
        </InnerContainer>
      </Container>
      <AnimatePresence exitBeforeEnter>
        {showActionSelector && (
          <ActionSelector initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <ActionContainer>
              <Action
                initial={{ opacity: 0, y: '64px' }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: '64px' }}
                transition={{ delay: 0.08 }}
              >
                <ActionButton onClick={() => triggerAction(0)} whileTap={{ scale: 0.9 }}>
                  <IconLaunch />
                </ActionButton>
                <ActionLabel>Spielen</ActionLabel>
              </Action>
              <Action
                initial={{ opacity: 0, y: '64px' }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: '64px' }}
                transition={{ delay: 0.16 }}
              >
                <ActionButton onClick={() => triggerAction(1)} whileTap={{ scale: 0.9 }}>
                  <IconAddQuestion />
                </ActionButton>
                <ActionLabel>Neue Frage</ActionLabel>
              </Action>
              <Action
                initial={{ opacity: 0, y: '64px' }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: '64px' }}
                transition={{ delay: 0.24 }}
              >
                <ActionButton onClick={() => triggerAction(2)} whileTap={{ scale: 0.9 }}>
                  <IconUser />
                </ActionButton>
                <ActionLabel>Einladen</ActionLabel>
              </Action>
            </ActionContainer>
          </ActionSelector>
        )}
      </AnimatePresence>
    </>
  )
}

export default TabBar
