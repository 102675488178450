import { motion } from 'framer-motion'
import React, { useContext, useState } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg'
import { Context } from '../../context/ContextProvider'
import { CHANGE_GROUP } from '../../database/actions/groupActions'
import { useMemberships } from '../../database/services/useMemberships'
import { GroupThumbnail } from '../components/groups'
import { Layer } from '../components/layout'

const Drawer = styled(motion.div)`
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  padding: 36px;
`

const Group = styled(motion.div)`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.space.m};

  &:last-child {
    margin-bottom: 0px;
  }
`

const GroupDetails = styled.div`
  margin-left: ${props => props.theme.space.s};

  h3 {
    margin: 0px 0px ${props => props.theme.space.xxs} 0px;
  }
`

const Meta = styled.span`
  font-size: ${props => props.theme.fontSize.s};
  color: ${props => props.theme.neutral.bright};
  opacity: 0.8;
`

const Hr = styled.div`
  width: 100%;
  height: 1px;
  background: ${props => props.theme.neutral.bright};
  opacity: 0.1;
  margin: ${props => props.theme.space.l} 0px;
`

const AddButton = styled.button`
  width: 56px;
  height: 56px;
  background: rgba(255, 255, 255, 0.12);
  border-radius: ${props => props.theme.radius.m};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 16px;
    height: 16px;
    fill: ${props => props.theme.neutral.bright};
  }
`

function GroupDrawer(props) {
  const context = useContext(Context)

  const { memberships, membershipStatus, membershipObjects } = useMemberships(props.user)
  const [redirect, setRedirect] = useState(false)

  const closeDrawer = () => {
    props.close()
  }

  const changeGroup = newGroup => {
    if (props.userProfile.activeGroup !== newGroup) {
      context.showPreloader()
      setRedirect(true)
      CHANGE_GROUP(props.userProfile.uid, newGroup)
    }
  }

  const drawerVariants = {
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.3,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: 'afterChildren',
      },
    },
  }

  const groupVariants = {
    visible: i => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.1 + 0.3,
      },
    }),
    hidden: {
      opacity: 0,
      x: 100,
    },
  }

  return (
    <>
      {props.user && props.userProfile && (
        <Layer nature="opaque" action={closeDrawer}>
          <Drawer initial="hidden" animate="visible" variants={drawerVariants}>
            {membershipObjects.map((group, index) => {
              const creationDate = new Date(group.timestamp.seconds * 1000).toLocaleDateString()
              return (
                <Group key={index} onClick={() => changeGroup(group.id)} variants={groupVariants} custom={index} animate="visible">
                  <GroupThumbnail
                    size="large"
                    key={group.id}
                    groupId={group.id}
                    status={group.id === props.userProfile.activeGroup ? 'active' : 'passive'}
                    thumbnailName={group.thumbnailName}
                  />
                  <GroupDetails>
                    <h3>{group.name}</h3>
                    <Meta>Erstellt am {creationDate}</Meta>
                  </GroupDetails>
                </Group>
              )
            })}
            <Hr />
            <Group>
              <AddButton>
                <AddIcon />
              </AddButton>
              <GroupDetails onClick={props.openGroupDialog}>
                <h3>Neue Gruppe erstellen</h3>
                <Meta>Noch 2 Plätze verfügbar</Meta>
              </GroupDetails>
            </Group>
          </Drawer>
        </Layer>
      )}
      {redirect && <Redirect to="/" />}
    </>
  )
}

export default GroupDrawer
