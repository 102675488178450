import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as IconClose } from '../../../assets/icons/close.svg'

const Button = styled(motion.span)`
  width: 14px;
  height: 14px;
  outline: 0px;

  svg {
    fill: ${props => props.invert ? props.theme.primary.base : props.theme.neutral.bright};
    width: 14px;
    height: 14px;
  }
`

function CloseButton(props) {
  return(
    <Button invert={props.invert} onClick={props.closeFunction} whileTap={{ scale: 0.9 }}>
      <IconClose />
    </Button>
  )
}

export default CloseButton