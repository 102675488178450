import React, { useEffect } from 'react'
import { db } from '../config'

export function useUserQuestions(group, userID) {
  const [error, setError] = React.useState(false)
  const [loadingUserQuestions, setLoadingUserQuestions] = React.useState(true)
  const [userQuestions, setUserQuestions] = React.useState()
  const [userCategories, setUserCategories] = React.useState()
  const [userQuestionNumber, setUserQuestionNumber] = React.useState()

  useEffect(() => {
    let unsubscribe = () => {}

    group &&
      (unsubscribe = db
        .collection('groups')
        .doc(group)
        .collection('questionPool')
        .where('author', '==', userID)
        .onSnapshot(function(querySnapshot) {
          let questions = []
          let categories = []

          querySnapshot.forEach(function(doc) {
            const id = doc.id
            const questionData = doc.data()
            const publishingDate = new Date(questionData.timestamp.seconds * 1000).toLocaleDateString()

            !categories.includes(questionData.category) && categories.push(questionData.category)

            const question = {
              ...questionData,
            }

            questions.push(question)
          })

          setLoadingUserQuestions(false)
          setUserQuestions(questions)
          setUserCategories(categories)
          setUserQuestionNumber(questions.length)
        }))

    return () => unsubscribe()
  }, [group, userID])

  return {
    error,
    loadingUserQuestions,
    userQuestions,
    userCategories,
    userQuestionNumber,
  }
}
