import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as ReactionIcon } from '../../../assets/icons/reaction.svg'
import { ADD_QUESTIONVIEW } from '../../../database/actions/poolActions'
import { SAVE_REACTION, UPDATE_QUIZRESULT } from '../../../database/actions/quizActions'
import { FloatingButton } from '../buttons'
import { Answer, EmojiConfetti, ImageQuestion, ReactionPicker, TextQuestion, TimeGuard } from './'

const QuestionContainer = styled(motion.div)`
  width: 100%;
  height: calc(100vh - 104px);
  position: relative;
`

const AnswerBox = styled.div`
  position: absolute;
  bottom: 88px;
  left: 0px;
  width: 100%;
  height: auto;
`

const ActionContainer = styled.div`
  position: fixed;
  padding-bottom: ${props => props.theme.space.l};
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 50vh;
  z-index: 10000;
  display: flex;
  align-items: flex-end;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1000;
    bottom: 0px;
    left: 0px;
    position: absolute;
    background: ${props => props.theme.primary.dark};
    background: ${props => 'linear-gradient(0deg, ' + props.theme.primary.dark + ' 0%, rgba(0, 0, 0, 0) 100%);'};
    opacity: ${props => (props.showReactions ? 1 : 0)};
    transition: opacity 0.6s;
  }
`

const ReactionButton = styled(motion.div)`
  width: 40px;
  height: 40px;
  position: absolute;
  left: ${props => props.theme.space.m};
  bottom: ${props => props.theme.space.l};
  border-radius: 100%;
  background: ${props => props.theme.neutral.bright};
  box-shadow: ${props => props.theme.shadow.base};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11000;

  svg {
    fill: ${props => props.theme.secondary.base};
    width: 16px;
    height: auto;
  }
`

const ReactionContainer = styled(motion.div)`
  position: absolute;
  width: 100%;
  left: 0%;
  bottom: ${props => props.theme.space.xxl};
`

function Question(props) {
  const [solution] = useState(props.answers[0])
  const [newSolutionId, setNewSolutionId] = useState()
  const [randomAnswers, setRandomAnswers] = useState([])
  const [result, setResult] = useState()
  const [quizmateResult, setQuizmateResult] = useState()
  const [answerLabel, setAnswerLabel] = useState(null)
  const [evaluation, setEvaluation] = useState(false)
  const [databaseUpdate, setDatabaseUpdate] = useState(false)

  const [showReactions, setShowReactions] = useState(false)
  const [reaction, setReaction] = useState(false)
  const [reactionArray, setReactionArray] = useState([])
  const [confetti, setConfetti] = useState(false)

  useEffect(() => {
    setRandomAnswers(props.answers.sort(() => Math.random() - 0.5))

    const quizmateIndex = props.userRole === 'challenger' ? props.results.challengerIndex : props.results.opponentIndex
    const quizmateResultKey = (props.userRole === 'challenger' ? 'opponent' : 'challenger') + '_' + quizmateIndex

    setQuizmateResult(props.results[quizmateResultKey])
  }, [])

  useEffect(() => {
    setNewSolutionId(randomAnswers.findIndex(answer => answer === solution) + 1)
  }, [randomAnswers])

  function checkAnswer(answerId, label) {
    setAnswerLabel(label)
    setEvaluation(true)
    if (answerId) {
      answerId === newSolutionId ? setResult(true) : setResult(false)
    } else {
      setResult(false)
    }
  }

  function timeout() {
    setEvaluation(true)
    setResult(false)
  }

  const updateCallback = () => {
    setDatabaseUpdate(true)
  }

  useEffect(() => {
    if (evaluation && !databaseUpdate) {
      UPDATE_QUIZRESULT(props.group, props.quizID, props.userQuizRound, props.index, props.userRole, result, answerLabel, updateCallback)
      ADD_QUESTIONVIEW(props.group, props.questionId, props.user)
    }

    if (evaluation && props.index === 2) {
      props.nextQuizRound()

      switch (props.userQuizRound) {
        case 0:
          props.userRole === 'opponent' && props.switchTurn()
          break
        case 1:
          props.userRole === 'challenger' && props.switchTurn()
          break
        case 2:
          props.userRole === 'opponent' && props.switchTurn()
          break
        case 3:
          props.userRole === 'challenger' && props.switchTurn()
          break
      }
    }
  }, [result])

  function moveForward() {
    if (props.index < 2) {
      props.nextQuestion()
    } else {
      props.finishQuizRound()
    }
  }

  const selectReaction = emoji => {
    setShowReactions(false)
    setConfetti(true)
    setReaction(emoji)
    !reactionArray.includes(emoji) && SAVE_REACTION(props.group, props.questionId, props.user, emoji)
    !reactionArray.includes(emoji) && setReactionArray([...reactionArray, emoji])
  }

  return (
    <QuestionContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      {confetti && <EmojiConfetti emoji={reaction} density="30" />}
      {props.type === 'image' ? (
        <ImageQuestion author={props.author} cat={props.cat} text={props.text} imageName={props.imageName} imageURL={props.imageURL} />
      ) : (
        <TextQuestion author={props.author} cat={props.cat} text={props.text} />
      )}
      <AnswerBox>
        {randomAnswers.map((answer, index) => (
          <Answer
            key={index}
            answerId={(index = index + 1)}
            emitUserInput={checkAnswer}
            label={answer.label}
            quizmateResult={quizmateResult}
            quizmateName={props.quizmateName}
            text={answer.value}
            solution={solution}
            solutionId={newSolutionId}
            evaluation={evaluation}
          />
        ))}
      </AnswerBox>
      <TimeGuard timeout={timeout} defused={evaluation} />
      {evaluation && databaseUpdate && (
        <ActionContainer showReactions={showReactions}>
          <ReactionContainer>
            <ReactionPicker show={showReactions} selectReaction={selectReaction} />
          </ReactionContainer>
          <AnimatePresence>
            {!showReactions && (
              <ReactionButton
                onClick={() => setShowReactions(true)}
                whileTap={{ scale: 0.9 }}
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0, opacity: 0 }}
              >
                <ReactionIcon />
              </ReactionButton>
            )}
          </AnimatePresence>
          <FloatingButton position="relative" onClick={moveForward}>
            Weiter
          </FloatingButton>
        </ActionContainer>
      )}
    </QuestionContainer>
  )
}

export default Question
