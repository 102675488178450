import { AnimatePresence, motion, useSpring } from 'framer-motion'
import React, { useContext, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Notification } from '../../../context/NotificationProvider'

const Wrapper = styled(motion.div)`
  position: relative;
  bottom: -${props => props.theme.space.s};
  left: 0px;
  width: 100%;
  height: auto;
  position: fixed;
  z-index: 15000;
`

const DragArea = styled(motion.div)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
`

const Container = styled(motion.div)`
  background: ${props => props.theme.neutral.bright};
  padding: ${props => props.theme.space.m} ${props => props.theme.space.m} ${props => props.theme.space.xl} ${props => props.theme.space.m};
  color: ${props => (props.nature === 'positive' ? props.theme.positive.base : props.theme.negative.base)};
  box-shadow: ${props => props.theme.shadow.topHeavy};
  border-radius: 16px 16px 0px 0px;

  h4 {
    color: ${props => (props.nature === 'positive' ? props.theme.positive.base : props.theme.negative.base)};
  }
`

function GlobalNotification(props) {
  
  const notification = useContext(Notification)

  const ContainerRef = useRef(null)

  const y = useSpring(0, { mass: 1.5, stiffness: 20, damping: 12 })

  function handleDragEnd(event, info) {
    if (info.point.y > 20) {
      console.log(info.point.y)
      y.set(0)
      notification.hide()
    } else {
      y.set(0)
    }
  }

  function emitDragEvent(event, info) {
    if (info.point.y > 0) {
      y.set(info.point.y)
      info.point.y > 20 && notification.hide()
    } else if (info.point.y < 0) {
      info.point.y > -40 ? y.set(info.point.y) : y.set(info.point.y * 0)
    }
  }

  useEffect(() => {
    let timer

    if (notification.visible) {
      timer = window.setTimeout(() => {
        notification.hide()
      }, 5000)
    }
    return () => clearTimeout(timer)
  }, [notification.visible])

  return (
    <AnimatePresence>
      {notification.visible && (
        <Wrapper
          initial={{y: 100}}
          animate={{y: 0}}
          exit={{y: 124}}
        >
          <DragArea
            drag="y"
            dragElastic={0.6}
            dragConstraints={{ top: 0, bottom: 0, left: 0, right: 0 }}
            dragMomentum={false}
            onDrag={(event, info) => emitDragEvent(event, info)}
            onDragEnd={(event, info) => handleDragEnd(event, info)}
          ></DragArea>
          <Container ref={ContainerRef} nature={notification.nature} style={{ y }}>
            <h4>{notification.title}</h4>
            {notification.bodyText}
          </Container>
        </Wrapper>
      )}
    </AnimatePresence>
  )
}

export default GlobalNotification
