import React, { useEffect } from 'react'
import { db } from '../config'

export function useRequestList(groupId, uid) {

  const [error] = React.useState(false)
  const [requestListLoading, setRequestListLoading] = React.useState(true)
  const [requestList, setRequestList] = React.useState([])
  const [challengeList, setChallengeList] = React.useState([])
  const [challengeListLoading, setChallengeListLoading] = React.useState(true)

  let isMounted = true

  useEffect(() => {
    let unsubscribe = () => {}

    groupId &&
      (unsubscribe = db
        .collection('groups')
        .doc(groupId)
        .collection('requests')
        .where('opponent', '==', uid)
        .onSnapshot(function(querySnapshot) {
          let list = []
          querySnapshot.forEach(function(doc) {
            const data = doc.data()
            const listItem = {
              id: doc.id,
              challenger: data.challenger,
              challengerName: data.challengerName,
              opponent: data.opponent,
              opponentName: data.opponentName,
              timestamp: data.timestamp,
            }

            list.push(listItem)
          })
          setChallengeListLoading(false)
          setChallengeList(list)
        }))

    return () => unsubscribe()
  }, [groupId, uid])

  useEffect(() => {
    let unsubscribe = () => {}

    groupId &&
      (unsubscribe = db
        .collection('groups')
        .doc(groupId)
        .collection('requests')
        .where('challenger', '==', uid)
        .onSnapshot(function(querySnapshot) {
          let list = []
          querySnapshot.forEach(function(doc) {
            const data = doc.data()
            const listItem = {
              id: doc.id,
              challenger: data.challenger,
              challengerName: data.challengerName,
              opponent: data.opponent,
              opponentName: data.opponentName,
              timestamp: data.timestamp,
            }

            list.push(listItem)
          })
          setRequestListLoading(false)
          setRequestList(list)
        }))
        
    return () => unsubscribe()
  }, [groupId, uid])

  return {
    error,
    requestListLoading,
    requestList,
    challengeListLoading,
    challengeList,
  }
}
