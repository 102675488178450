import { AnimatePresence, motion } from 'framer-motion'
import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import packageJson from '../../../package.json'
import { Context } from '../../context/ContextProvider'

const Layer = styled(motion.div)`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1000;
    top: 0px;
    left: 0px;
    position: absolute;
    background-color: ${props => props.theme.primary.dark};
    opacity: 0.5;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1000;
    bottom: 0px;
    left: 0px;
    position: absolute;
    background: ${props => props.theme.primary.dark};
    background: ${props => 'linear-gradient(0deg, ' + props.theme.primary.dark + ' 0%, rgba(0, 0, 0, 0) 100%);'};
    opacity: 0.8;
  }
`

const PositionWrapper = styled.div`
  position: relative;
  width: calc(100% - 72px);
  height: auto;
`

const Container = styled(motion.div)`
  position: relative;
  width: 100%;
  height: auto;
  color: ${props => props.theme.neutral.bright};
  font-size: ${props => props.theme.fontSize.root};
  line-height: ${props => props.theme.lineHeight.root};
  padding: 0px ${props => props.theme.space.m};
`

const Background = styled(motion.div)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.primary.light};
  border-radius: ${props => props.theme.radius.m};
  box-shadow: ${props => props.theme.shadow.base};
  z-index: 0;
`

const TextWrapper = styled(motion.div)`
  position: relative;
  z-index: 1000;
`

const LinkItem = styled.div`
  border-bottom: 1px solid ${props => props.theme.primary.base};
  font-weight: ${props => props.theme.fontWeight.medium};

  a,
  span {
    display: inline-block;
    padding: 20px 0px;
    width: 100%;
  }

  &:last-child {
    border-bottom: 0px;
  }
`

const AppInfo = styled.div`
  position: fixed;
  bottom: ${props => props.theme.space.l};
  left: 50%;
  transform: translateX(-50%);
  color: ${props => props.theme.neutral.bright};
  opacity: 0.8;
  font-size: ${props => props.theme.fontSize.s};
  font-weight: ${props => props.theme.fontWeight.medium};
`

const layerTransition = {
  hidden: {
    opacity: 0,
    transition: {
      type: 'tween',
      duration: 0.3,
    },
  },
  visible: {
    opacity: 1,
  },
}

const textTransition = {
  hidden: {
    opacity: 0,
    transition: {
      type: 'tween',
      duration: 0.3,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.2,
    },
  },
}

const containerTransition = {
  hidden: {
    scale: 0.5,
  },
  visible: {
    scale: 1,
    transition: {
      type: 'spring',
      damping: 18,
      mass: 1.5,
      stiffness: 240,
    },
  },
}

function SwitchModal(props) {
  const context = useContext(Context)

  return (
    <>
      <AnimatePresence>
        {props.show && (
          <Layer onClick={props.close} variants={layerTransition} initial="hidden" animate="visible" exit="hidden">
            <PositionWrapper>
              <Container>
                <TextWrapper variants={textTransition} initial="hidden" animate={props.show ? 'visible' : 'hidden'}>
                  {props.links.map(link => {
                    return (
                      <LinkItem key={link.key} onClick={props.close}>
                        {link.type === 'link' ? (
                          <NavLink to={link.to}>{link.title}</NavLink>
                        ) : (
                          <span onClick={link.function}>{link.title}</span>
                        )}
                      </LinkItem>
                    )
                  })}
                </TextWrapper>
                <Background variants={containerTransition} initial="hidden" animate={props.show ? 'visible' : 'hidden'} />
              </Container>
            </PositionWrapper>
            <AppInfo>
              cuiz <sup>{packageJson.version} - beta</sup>
            </AppInfo>
          </Layer>
        )}
      </AnimatePresence>
    </>
  )
}

export default SwitchModal
