import firebase from 'firebase/app'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Cropper from 'react-easy-crop'
import styled from 'styled-components'
import { storage } from '../../../database/config'
import { getCroppedImg } from '../../global/helpers'

const UploadContainer = styled.div`
  border-radius: 8px;
  /* border: 2px solid ${props => props.theme.neutral.text}; */
  height: 0px;
  padding-bottom: 75%;
  position: relative;
  overflow: hidden;
  background: ${props => props.theme.neutral.light};
`

const ImageContainer = styled(motion.div)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
`

const InputContainer = styled(motion.div)``

const Input = styled.div`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

const Label = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  border-radius: 8px;
  background: ${props => props.theme.secondary.base};
  box-shadow: ${props => props.theme.shadow.light};
  color: ${props => props.theme.neutral.bright};
  min-width: 160px;
  text-align: center;
  padding: ${props => props.theme.space.s} ${props => props.theme.space.s};
`

const Preview = styled(motion.div)`
  position: absolute;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`

const FileContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0%;
  height: 0px;
  padding-bottom: ${props => (props.mediaSize && props.mediaSize.naturalWidth < props.mediaSize.naturalHeight ? '100%' : '75%')};
  width: 100%;
  border-radius: 8px;
  overflow: hidden;

  /* .reactEasyCrop_CropArea {
    max-width: 64%;
    max-height: 64%;
  } */

  &:after {
    content: '';
    z-index: -1000;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: ${props => 'url(' + props.blob + ')'};
    background-size: cover;
    filter: blur(4px);
    background-position: center;
  }

  /* img {
    position: relative;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: auto;
    height: 100%;
  } */
`

const Loader = styled(motion.div)`
  position: absolute;
  z-index: 1000;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: ${props => props.theme.primary.base};
    opacity: 0.8;
  }
`

const ProgressValue = styled(motion.span)`
  position: relative;
  z-index: 2000;
  position: relative;
  font-size: ${props => props.theme.fontSize.xl};
  color: ${props => props.theme.neutral.bright};
  font-weight: ${props => props.theme.fontWeight.bold};
`

const SuccessMessage = styled(motion.span)`
  position: relative;
  z-index: 2000;
  position: relative;
  font-size: ${props => props.theme.fontSize.m};
  color: ${props => props.theme.neutral.bright};
  font-weight: ${props => props.theme.fontWeight.medium};
`

function ImageUploader(props) {
  var storageRef = storage.ref()
  // Create a reference to 'mountains.jpg'

  const [defaultHeight, setDefaultHeight] = useState()

  const [file, setFile] = useState()
  const [blob, setBlob] = useState()
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState()
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState()
  const [progress, setProgress] = useState()
  const [mediaSize, setMediaSize] = useState()
  const [cropperReady, setCropperReady] = useState(false)

  const uploadContainerRef = useRef(null)
  const FileContainerRef = useRef(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  useEffect(() => {
    setDefaultHeight(uploadContainerRef.current.clientHeight)
    props.initialImage && setCropperReady(true)
  }, [])

  useEffect(() => {
    props.initialImage &&
      storage
        .refFromURL(props.initialImage)
        .getDownloadURL()
        .then(function(url) {
          setBlob(url)
        })
        .catch(function(error) {
          switch (error.code) {
            case 'storage/object-not-found':
              break
            case 'storage/unauthorized':
              break
            case 'storage/canceled':
              break
            case 'storage/unknown':
              break
            default:
              break
          }
        })
  }, [])

  useEffect(() => {
    props.clear && setBlob()
    props.clear && setCropperReady(false)
  }, [props.clear])

  useEffect(() => {
    croppedAreaPixels && createCroppedImage()
  }, [croppedAreaPixels])

  const createCroppedImage = async () => {
    const newImage = await getCroppedImg(blob, croppedAreaPixels)
    setCroppedImage(newImage)
  }

  useEffect(() => {
    props.emitSelectedImage(croppedImage)
  }, croppedImage)

  const handleUpload = event => {
    const newBlob = URL.createObjectURL(event.target.files[0])

    setFile(event.target.files[0])
    setBlob(newBlob)
  }

  useEffect(() => {
    if (blob) {
      const uploadedImage = new Image()
      uploadedImage.src = blob

      uploadedImage.onload = function() {
        setMediaSize({ naturalWidth: uploadedImage.width, naturalHeight: uploadedImage.height })
      }
    }
  }, [blob])

  useEffect(() => {
    if (mediaSize) {
      if (mediaSize.naturalWidth < mediaSize.naturalHeight) {
        console.log(FileContainerRef.current.clientHeight)
        console.log(defaultHeight)
        FileContainerRef.current.clientHeight > defaultHeight && setCropperReady(true)
      } else {
        setCropperReady(true)
      }
    }
  }, [mediaSize, FileContainerRef])

  const uploadImage = () => {
    const imageName = props.imageName ? props.imageName : 'image-' + new Date().getFullYear().toString() + '_' + new Date().valueOf()
    
    const storageTargetPath = props.subfolder ? 'groups/' + props.group + '/' + props.subfolder + '/' : 'groups/' + props.group + '/'
    console.log(storageTargetPath)

    const newRef = storageRef.child(storageTargetPath + imageName)
    const uploadTask = newRef.put(croppedImage)

    props.emitImageName(imageName)

    uploadTask.on(
      'state_changed',
      function(snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        setProgress(progress)
        console.log('Upload is ' + progress + '% done')
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED:
            console.log('Upload is paused')
            break
          case firebase.storage.TaskState.RUNNING:
            console.log('Upload is running')
            break
        }
      },
      function(error) {
        // Handle unsuccessful uploads
      },
      function() {
        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          props.emitStorageURL(downloadURL)
        })
      }
    )
  }

  useEffect(() => {
    props.triggerUpload && uploadImage()
  }, [props.triggerUpload])

  return (
    <>
      <UploadContainer ref={uploadContainerRef}>
        <AnimatePresence exitBeforeEnter>
          {blob ? (
            <ImageContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              {progress && (
                <Loader>
                  <AnimatePresence exitBeforeEnter>
                    {progress < 99 ? (
                      <ProgressValue initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        {Math.round(progress)} %
                      </ProgressValue>
                    ) : (
                      <SuccessMessage initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        Erfolgreich hochgeladen! 🎉
                      </SuccessMessage>
                    )}
                  </AnimatePresence>
                </Loader>
              )}
              <Preview>
                <FileContainer blob={blob} mediaSize={mediaSize} ref={FileContainerRef}>
                  {cropperReady && (
                    <Cropper
                      image={blob}
                      crop={crop}
                      zoom={zoom}
                      aspect={1 / 1}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />
                  )}
                </FileContainer>
              </Preview>
            </ImageContainer>
          ) : (
            <InputContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Label>
                <label htmlFor="fileUpload">Bild auswählen</label>
              </Label>
              <Input>
                <input type="file" id="fileUpload" onChange={handleUpload} accept="image/*" />
              </Input>
            </InputContainer>
          )}
        </AnimatePresence>
      </UploadContainer>
    </>
  )
}

export default ImageUploader
