import { AnimatePresence, motion } from 'framer-motion'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Context } from '../../context/ContextProvider'
import { ARCHIVE_QUIZ, INCREASE_ROUND, SET_WINNER, SWITCH_TURN } from '../../database/actions/quizActions'
import { UPDATE_USERSTATISTIC } from '../../database/actions/userActions'
import { useQuestionBatches, useQuiz, useQuizResults, useResultInterpreter } from '../../database/services'
import { Question, QuizOverview } from '../components/quiz'
import { viewVariants } from '../global'

function Quiz(props) {
  const context = useContext(Context)

  let { quizId } = useParams()

  const {
    quizLoading,
    turnOwner,
    challenger,
    challengerName,
    challengerRound,
    opponent,
    opponentName,
    opponentRound,
    role,
    winner,
    quizStatus,
  } = useQuiz(quizId, context.userProfile.uid)

  const { quizResults } = useQuizResults(context.userProfile.activeGroup, quizId)
  const {
    resultInterpretation,
    challengerScore,
    challengerQnIndex,
    challengerTurn,
    opponentScore,
    opponentQnIndex,
    opponentTurn,
    quizCompleted,
  } = useResultInterpreter(quizId, quizResults)

  const { batchLoading, questionBatches } = useQuestionBatches(context.userProfile.activeGroup, quizId)

  const [loading, setLoading] = useState(true)
  const [quizView, setQuizView] = useState()
  const [quizRound, setQuizRound] = useState()
  const [questionIndex, setQuestionIndex] = useState()
  const [quizCategories, setQuizCategories] = useState()
  const [questionBatch, setQuestionBatch] = useState()
  const [category, setCategory] = useState()

  const [quizResult, setQuizResult] = useState()

  useEffect(() => {
    if (!quizLoading && !batchLoading && quizResults && resultInterpretation) {
      setLoading(false)
    }
  }, [quizLoading, batchLoading, quizResults, resultInterpretation])

  useEffect(() => {
    if (!loading) {
      if (role === 'challenger') {
        setQuestionIndex(challengerQnIndex)
      } else {
        setQuestionIndex(opponentQnIndex)
      }

      setQuizRound({
        challenger: challengerTurn,
        opponent: opponentTurn,
      })
    }

    if (!batchLoading) {
      const currentRound = role === 'challenger' ? challengerTurn : opponentTurn
      updateQuestionBatch(currentRound)

      setQuizCategories([
        questionBatches.batch_0.category,
        questionBatches.batch_1.category,
        questionBatches.batch_2.category,
        questionBatches.batch_3.category,
      ])

      setQuizView('Overview')
    }
  }, [loading])

  useEffect(() => {
    setQuizRound({
      challenger: challengerTurn,
      opponent: opponentTurn,
    })
  }, [challengerTurn, opponentTurn])

  function startQuestions() {
    changeQuizView('Question')
  }

  function changeQuizView(newView) {
    setQuizView(newView)
  }

  function nextQuestion() {
    setQuestionIndex(questionIndex + 1)
  }

  function getNextRound() {
    let currentRound
    role === 'challenger' ? (currentRound = challengerTurn) : (currentRound = opponentTurn)
    const nextRound = currentRound + 1

    return nextRound
  }

  function finishQuizRound() {
    changeQuizView('Overview')

    const nextRound = role === 'challenger' ? challengerTurn : opponentTurn
    updateQuestionBatch(nextRound)
    setQuestionIndex(0)
  }

  useEffect(() => {
    quizCompleted && !winner && evaluateEndResult()
  }, [quizCompleted])

  function nextQuizRound() {
    if (role === 'challenger' && challengerTurn < 3) {
      INCREASE_ROUND(context.userProfile.activeGroup, quizId, getNextRound(), role)
    } else if (role === 'opponent' && opponentTurn < 4) {
      INCREASE_ROUND(context.userProfile.activeGroup, quizId, getNextRound(), role)
    }
  }

  function switchTurn() {
    if (turnOwner === 'challenger') {
      SWITCH_TURN(context.userProfile.activeGroup, quizId, 'opponent')
    } else {
      SWITCH_TURN(context.userProfile.activeGroup, quizId, 'challenger')
    }
  }

  function updateQuestionBatch(round) {
    switch (round) {
      case 0:
        setQuestionBatch(questionBatches.batch_0.questions)
        setCategory(questionBatches.batch_0.category)
        break
      case 1:
        setQuestionBatch(questionBatches.batch_1.questions)
        setCategory(questionBatches.batch_1.category)
        break
      case 2:
        setQuestionBatch(questionBatches.batch_2.questions)
        setCategory(questionBatches.batch_2.category)
        break
      case 3:
        setQuestionBatch(questionBatches.batch_3.questions)
        setCategory(questionBatches.batch_3.category)
        break
    }
  }

  function evaluateEndResult() {
    if (challengerScore > opponentScore) {
      SET_WINNER(context.userProfile.activeGroup, quizId, challenger)
    } else if (challengerScore < opponentScore) {
      SET_WINNER(context.userProfile.activeGroup, quizId, opponent)
    } else {
      SET_WINNER(context.userProfile.activeGroup, quizId, 'draw')
    }
    ARCHIVE_QUIZ(context.userProfile.activeGroup, quizId)
  }

  useEffect(() => {
    if (winner !== null && winner !== undefined) {
      if (quizStatus === 'open') {
        if (winner === context.userProfile.uid) {
          setQuizResult('victory')
          UPDATE_USERSTATISTIC(context.userProfile.activeGroup, context.userProfile.uid, 'victory')
        } else if (winner === 'draw') {
          setQuizResult('draw')
          UPDATE_USERSTATISTIC(context.userProfile.activeGroup, context.userProfile.uid, 'draw')
        } else {
          setQuizResult('defeat')
          UPDATE_USERSTATISTIC(context.userProfile.activeGroup, context.userProfile.uid, 'defeat')
        }
      } else {
        if (winner === context.userProfile.uid) {
          setQuizResult('victory')
        } else if (winner === 'draw') {
          setQuizResult('draw')
        } else {
          setQuizResult('defeat')
        }
      }
    }
  }, [winner])

  useEffect(() => {
    console.log(quizView)
  }, [quizView])

  return (
    <motion.div initial="initial" animate="animate" exit="exit" variants={viewVariants}>
      <AnimatePresence exitBeforeEnter initial={false}>
        {quizView === 'Overview' && (
          <QuizOverview
            user={props.user}
            quizLoading={quizLoading}
            batchLoading={batchLoading}
            role={role}
            changeView={changeQuizView}
            quizId={quizId}
            quizResults={quizResults}
            challenger={challenger}
            challengerName={challengerName}
            challengerScore={challengerScore}
            challengerTurn={challengerTurn}
            opponent={opponent}
            opponentName={opponentName}
            opponentScore={opponentScore}
            opponentTurn={opponentTurn}
            quizRound={quizRound}
            activePlayer={turnOwner}
            turnOwner={turnOwner}
            quizResult={quizResult}
            winner={winner}
            startQuestions={startQuestions}
            quizCategories={quizCategories}
          />
        )}
        {quizView === 'Question' &&
          batchLoading === false &&
          questionBatch.map((item, index) => {
            if (index === questionIndex) {
              return (
                <Question
                  questionId={item.id}
                  key={index}
                  index={index}
                  group={context.userProfile.activeGroup}
                  quizID={quizId}
                  user={context.userProfile.uid}
                  author={item.author}
                  type={item.type}
                  imageURL={item.url}
                  imageName={item.imageName}
                  cat={category}
                  text={item.question}
                  answers={item.answers}
                  nextQuestion={nextQuestion}
                  nextQuizRound={nextQuizRound}
                  finishQuizRound={finishQuizRound}
                  changeQuizView={changeQuizView}
                  userRole={role}
                  userQuizRound={quizRound[role]}
                  switchTurn={switchTurn}
                  results={quizResults['round_' + quizRound[role]]}
                  quizmateName={role === 'challenger' ? opponentName : challengerName}
                />
              )
            } else {
              return false
            }
          })}
        {quizView === 'Result' && <div>Das ist das Endergebnis!</div>}
      </AnimatePresence>
    </motion.div>
  )
}

export default Quiz
