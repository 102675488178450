import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useProfileById } from '../../../database/services'

const Panel = styled.figure`
  width: 100%;
  position: relative;
  height: auto;
  margin: 0px 0px ${props => props.theme.space.xs} 0px;
  box-shadow: ${props => props.theme.shadow.light};
  text-align: left;

  a {
    text-decoration: none;
  }
`

const LinkWrapper = styled.div`
  width: 100%;
  color: ${props => props.theme.secondary.base};
  background: ${props => props.theme.neutral.bright};
  border-radius: ${props => props.theme.radius.m};
  padding: ${props => props.theme.space.sm};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const PlayerInformation = styled.div``

const Name = styled.div`
  font-size: 1rem;
  font-weight: ${props => props.theme.fontWeight.medium};
`

const Slogan = styled.div`
  font-size: 0.9rem;
  margin-top: 6px;
  font-weight: ${props => props.theme.fontWeight.regular};
`

const WinRatio = styled.div`
  min-width: 52px;
  position: relative;
  padding-left: ${props => props.theme.space.s};
  margin-left: ${props => props.theme.space.s};
  font-size: ${props => props.theme.fontSize.m};
  font-weight: ${props => props.theme.fontWeight.bold};
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    position: absolute;
    content: '';
    width: 1px;
    top: 0%;
    left: 0px;
    height: 100%;
    background-color: ${props => props.theme.secondary.base};
    opacity: 0.25;
  }
`

function Player(props) {
  const [winRatio, setWinRatio] = useState()
  const { loadingProfil, userProfile } = useProfileById(props.opponentId)

  useEffect(() => {
    if (userProfile) {
      console.log(props)
      const victories = props.statistics?.victories ? props.statistics.victories : 0
      const defeats = props.statistics?.defeats ? props.statistics.defeats : 0
      const draws = props.statistics?.draws ? props.statistics.draws : 0
      const totalQuizCount = victories + defeats + draws
      totalQuizCount !== 0 ? setWinRatio(Math.round((victories / totalQuizCount) * 100) + '%') : setWinRatio('–')

      console.log(totalQuizCount)
      console.log(winRatio)
    }
  }, [userProfile, props.statistics])

  return (
    <Panel key={props.opponentId} onClick={() => props.invitePlayer(props.opponentId, props.username)}>
      <LinkWrapper status={props.status}>
        <PlayerInformation>
          <Name>{props.username}</Name>
          <Slogan>{props.slogan}</Slogan>
        </PlayerInformation>
        <WinRatio>{winRatio}</WinRatio>
      </LinkWrapper>
    </Panel>
  )
}

export default Player
