import React from 'react'
import styled from 'styled-components'

const StyledButton = styled.button`
  appearance: none;
  display: inline-block;
  width: auto;
  background-color: ${props => props.theme.tertiary.base};
  border-radius: 29px;
  color: ${props => props.theme.neutral.bright};
  font-weight: ${props => props.theme.fontWeight.bold};
  padding: 18px 48px;
  box-shadow: ${props => props.theme.shadow.base};
  font-size: ${props => props.theme.fontSize.root};
  outline: none;
  border: none;
`

function Button(props) {
  return (
    <StyledButton onClick={props.onClick}>
      {props.children}
    </StyledButton>
  )
}

export default Button
