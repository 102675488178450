import React, { useContext, useEffect } from 'react'
import { Context } from '../../context/ContextProvider'
import { db } from '../config'

export function useQuizArchive(uid) {
  const context = useContext(Context)

  const [error] = React.useState(false)
  const [loadingChallengerQuizzes, setLoadingChallengerQuizzes] = React.useState(true)
  const [loadingOpponentQuizzes, setLoadingOpponentQuizzes] = React.useState(true)
  const [loadingQuizArchive, setLoadingQuizArchive] = React.useState(true)
  const [challengerQuizArchive, setChallengerQuizArchive] = React.useState()
  const [opponentQuizArchive, setOpponentQuizArchive] = React.useState()

  useEffect(() => {
    const unsubscribe = db.collection('groups')
      .doc(context.userProfile.activeGroup)
      .collection('quizzes')
      .where('status', '==', 'archived')
      .where('challenger', '==', uid)
      .onSnapshot(function(querySnapshot) {
        let challengerQuizzes = []

        querySnapshot.forEach(function(doc) {
          const data = doc.data()
          const quizItem = {
            id: doc.id,
            challenger: data.challenger,
            challengerName: data.challengerName,
            opponent: data.opponent,
            opponentName: data.opponentName,
            turnOwner: data.turnOwner,
          }

          challengerQuizzes.push(quizItem)
        })
        setChallengerQuizArchive(challengerQuizzes)
        setLoadingChallengerQuizzes(false)
      })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    const unsubscribe = db.collection('groups')
      .doc(context.userProfile.activeGroup)
      .collection('quizzes')
      .where('status', '==', 'archived')
      .where('opponent', '==', uid)
      .onSnapshot(function(querySnapshot) {
        let opponentQuizzes = []

        querySnapshot.forEach(function(doc) {
          const data = doc.data()
          const quizItem = {
            id: doc.id,
            challenger: data.challenger,
            challengerName: data.challengerName,
            opponent: data.opponent,
            opponentName: data.opponentName,
            turnOwner: data.turnOwner,
          }

          opponentQuizzes.push(quizItem)
        })
        setOpponentQuizArchive(opponentQuizzes)
        setLoadingOpponentQuizzes(false)
      })

      return () => unsubscribe()
  }, [])

  useEffect(() => {
    setLoadingQuizArchive(false)
  }, [loadingChallengerQuizzes, loadingOpponentQuizzes])

  return {
    error,
    loadingQuizArchive,
    challengerQuizArchive,
    opponentQuizArchive,
  }
}
