export const viewVariants = {
  initial: {
    opacity: 0,
    transition: {
      type: 'tween',
      duration: 0.3
    }
  },
  animate: {
    opacity: 1,
    transition: {
      type: 'tween',
      duration: 0.3
    }
  },
  exit: {
    opacity: 0,
    transition: {
      type: 'tween',
      duration: 0.2
    }
  },
}