import React from 'react'
import styled from 'styled-components'
import { useQuizResults, useResultInterpreter } from '../../../database/services'

const Score = styled.div`  
  position: relative;
  padding-left: ${props => props.theme.space.s};
  margin-left: ${props => props.theme.space.s};
  font-size: ${props => props.theme.fontSize.m};
  font-weight: ${props => props.theme.fontWeight.bold};
  
  &:after {
    position: absolute;
    content: '';
    width: 1px;
    top: -40%;
    left: 0px;
    height: 180%;
    background-color: ${props => (props.status === 'active' ? props.theme.secondary.base : props.theme.primary.base)};
    opacity: 0.25;
  }
`

function TeaserScore(props) {
  const { quizResults } = useQuizResults(props.userProfile.activeGroup, props.id)
  const { challengerScore, opponentScore } = useResultInterpreter(props.id, quizResults)

  return (
    <Score status={props.status}>
      {props.challenger === props.user ? <>{challengerScore} – {opponentScore}</> : <>{opponentScore} – {challengerScore}</>}
      
    </Score>
  )
}

export default TeaserScore
