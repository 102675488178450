import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const SearchContainer = styled.div`
  position: relative;
`

const TextInput = styled.input`
  display: block;
  border: 0px solid #fff;
  border: 2px solid ${props => props.theme.primary.dark};
  background-color: ${props => props.theme.neutral.bright};
  outline: none;
  padding: 16px;
  margin: 0px 0px 8px 0px;
  font-size: 1rem;
  color: ${props => props.theme.primary.base};
  width: 100%;
  border-radius: 8px;
  transition: border 0.6s;
  font-weight: ${props => props.theme.fontWeight.regular};

  ::placeholder {
    color: ${props => props.theme.neutral.light};
  }

  &:focus {
    border: 2px solid ${props => props.theme.secondary.base};
  }

  &:disabled {
    border: 2px solid transparent;
    color: ${props => props.theme.primary.dark};

    &::placeholder {
      color: ${props => props.theme.primary.dark};
    }
  }
`

const Validator = styled.div`
  position: absolute;
  top: ${props => props.theme.space.s};
  right: ${props => props.theme.space.m};
  color: ${props => props.theme.neutral.light};
  font-weight: ${props => props.theme.fontWeight.medium};
`

const ResultList = styled(motion.ul)`
  position: absolute;
  left: 0px;
  top: 58px;
  width: 100%;
  height: auto;
  list-style-type: none;
  background-color: ${props => props.theme.neutral.bright};
  border-radius: ${props => props.theme.radius.m};
  box-shadow: ${props => props.theme.shadow.base};
  margin: 0px;
  padding: 0px;

  li {
    color: ${props => props.theme.secondary.base};
    padding: ${props => props.theme.space.s};
    margin: 0px;
    border-bottom: 1px solid ${props => props.theme.secondary.light};
    cursor: pointer;

    &:last-child {
      border-bottom: 0px;
    }
  }
`

function Search(props) {
  const [searchValue, setSearchValue] = useState('')
  const [searchResults, setSearchResults] = useState([])

  function changeHandler(event) {
    props.emitValue(event.target.value)
    setSearchValue(event.target.value)

    setSearchResults(filterSuggestions(props.suggestions, searchValue))
  }

  function filterSuggestions(suggestions, filterValue) {
    const filterResults = suggestions.filter(suggestion => suggestion.name > filterValue)
    return filterResults
  }

  function selectResult(id, name) {
    setSearchValue(name)
    setSearchResults([])
  }

  useEffect(() => {
    const result = props.suggestions.filter(suggestion => suggestion.name === searchValue)

    props.emitValue(result[0])
  }, [searchValue])

  return (
    <SearchContainer>
      <TextInput type="text" placeholder={props.placeholder} value={searchValue} onChange={event => changeHandler(event)} />
      <AnimatePresence>
        {props.suggestions.length !== 0 && (
          <ResultList initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            {searchResults.map(result => (
              <li key={result.id} onClick={() => selectResult(result.id, result.name)}>{result.name}</li>
            ))}
          </ResultList>
        )}
      </AnimatePresence>
    </SearchContainer>
  )
}

export default Search
