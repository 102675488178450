import React from 'react'
import styled from 'styled-components'

const Gallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${props => props.theme.space.s};
`

const Thumbnail = styled.div`
  width: 52px;
  height: 52px;
  border-radius: 100%;
  overflow: hidden;
  border: 4px solid ${props => props.theme.primary.light};
  margin: 0px -${props => props.theme.space.xs} 0px 0px;

  img {
    width: 100%;
    height: 100%;
  }
`

function PlayerGallery(props) {
  return (
    <Gallery>
      {props.groupMemberProfiles.map((player, index) => (
        <Thumbnail key={index}>
          <img src={player.photoURL} />
        </Thumbnail>
      ))}
    </Gallery>
  )
}

export default PlayerGallery
